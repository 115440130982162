import * as React from 'react';
import { useLocation, useHistory, Prompt } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ColourPaper from '../Utilities/ColourPaper';
import ProfileViewModel, { ClientProfileViewModel, OrganisationProfileViewModel, SystemAdministratorProfileViewModel } from '../ViewModels/Profile/ProfileViewModel';
import YellowButton from '../Utilities/YellowButton';
import authService from '../api-authorization/AuthorizeService';
import Collapse from '@mui/material/Collapse';
import { AlertContext } from '../Contexts/AlertContext';
import LinearProgress from '@mui/material/LinearProgress';
import PasswordChangeDialog from '../Utilities/PasswordChangeDialog';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import TitleDropdown from '../Utilities/TitleDropdown';
import LocationDropdown from '../Utilities/LocationDropdown';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SquareButton from '../Utilities/SquareButton';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            textAlign: 'right'
        }
    }));

interface IProps {
    id: string | undefined;
}

export default function ProfileDetails() {
    const classes = useStyles();
    const location = useLocation<IProps>();
    const { show } = React.useContext(AlertContext);
    const { id } = location.state || {
        id: undefined
    };
    const history = useHistory();
    const [clientProfile, setClientProfile] = React.useState<ClientProfileViewModel | null>(null);
    const [orgProfile, setOrgProfile] = React.useState<OrganisationProfileViewModel | null>(null);
    const [sysAdminProfile, setSysAdminProfile] = React.useState<SystemAdministratorProfileViewModel | null>(null);
    const [adminProfile, setAdminProfile] = React.useState<ProfileViewModel | null>(null);
    const [loaded, setLoaded] = React.useState(false);
    const [passwordAnchorEl, setPasswordAnchorEl] = React.useState<HTMLElement | null>(null);
    const [edited, setEdited] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        setEdited(false);
        getDetails();
    }, [id]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, profile: string, element: string) => {
        setEdited(true);
        switch (profile) {
            case "Client":
                clientProfile && setClientProfile({
                    ...clientProfile,
                    [element]: event.target.value
                });
                break;
            case "Organisation":
                orgProfile && setOrgProfile({
                    ...orgProfile,
                    [element]: event.target.value
                });
                break;
            case "SysAdmin":
                sysAdminProfile && setSysAdminProfile({
                    ...sysAdminProfile,
                    [element]: event.target.value
                });
                break;
            case "Admin":
                adminProfile && setAdminProfile({
                    ...adminProfile,
                    [element]: event.target.value
                });
                break;
        }
    }

    const getDetails = async () => {
        setSysAdminProfile(null);
        setClientProfile(null);
        setOrgProfile(null);
        setAdminProfile(null);

        const token = await authService.getAccessToken();
        setLoaded(false);

        let url = 'User/GetProfileDetails';
        if (id !== undefined) url = url + `?id=${id}`;

        fetch(url, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            switch (data.userType) {
                case "SystemAdministrator": setSysAdminProfile(data); break;
                case "Client": setClientProfile(data); break;
                case "Organisation": setOrgProfile(data); break;
                case "ApplicationUser": setAdminProfile(data); break;
            }
            setLoaded(true);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoaded(true);
        });
    }

    const save = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        var url = 'User/Update';
        var profile;

        if (clientProfile) { url = url + 'ClientProfile'; profile = clientProfile; }
        if (orgProfile) { url = url + 'OrganisationProfile'; profile = orgProfile; }
        if (sysAdminProfile) { url = url + 'SysAdminProfile'; profile = sysAdminProfile; }
        if (adminProfile) { url = url + 'BaseProfile'; profile = adminProfile; }

        fetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(profile)
        })
            .then(response => response.text())
            .then(data => {
                setEdited(false);
                setSaving(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    getDetails();
                    show('success', 'Successfully updated profile');
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const openDialog = (event: React.MouseEvent<HTMLElement>) => {
        setPasswordAnchorEl(event.currentTarget);
    }

    const closeDialog = () => {
        setPasswordAnchorEl(null);
    }

    const profilePage = (clientProfile !== null || orgProfile !== null || sysAdminProfile !== null || adminProfile !== null) ?
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4">Profile</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                                <Grid item>
                                    <Button onClick={history.goBack}><ArrowBackIcon /> Back</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Collapse in={loaded}>
                        <ColourPaper>
                            {adminProfile &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">User Details</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Email</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="adminProfileEmail"
                                                    size="small"
                                                    value={adminProfile.email}
                                                    onChange={(event) => { onChange(event, 'Admin', 'email') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {sysAdminProfile &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">User Details</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>First Name</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="sysAdminFirstName"
                                                    size="small"
                                                    value={sysAdminProfile.firstName}
                                                    onChange={(event) => { onChange(event, 'SysAdmin', 'firstName') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Last Name</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="sysAdminLastName"
                                                    size="small"
                                                    value={sysAdminProfile.lastName}
                                                    onChange={(event) => { onChange(event, 'SysAdmin', 'lastName') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Email</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="sysAdminEmail"
                                                    size="small"
                                                    value={sysAdminProfile.email}
                                                    onChange={(event) => { onChange(event, 'SysAdmin', 'email') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {orgProfile &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Practice Details</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Name</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="orgProfileOrganisationName"
                                                    size="small"
                                                    value={orgProfile.organisationName}
                                                    onChange={(event) => { onChange(event, 'Organisation', 'organisationName') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Email</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="orgProfileEmail"
                                                    size="small"
                                                    value={orgProfile.email}
                                                    onChange={(event) => { onChange(event, 'Organisation', 'email') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Location</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <LocationDropdown alignRight location={orgProfile.locationId} onChange={(event) => onChange(event, 'Organisation', 'locationId')} editable={true} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>URL/RIAI Profile</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="orgURL"
                                                    size="small"
                                                    value={orgProfile.url}
                                                    onChange={(event) => { onChange(event, 'Organisation', 'url') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Contact Number</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="orgContactNumber"
                                                    size="small"
                                                    value={orgProfile.contactNumber}
                                                    onChange={(event) => { onChange(event, 'Organisation', 'contactNumber') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {clientProfile &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Client Details</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Title</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TitleDropdown title={clientProfile.titleID} onChange={(event) => { onChange(event, 'Client', 'titleID') }} editable={true} alignRight />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>First Name</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="clientFirstName"
                                                    size="small"
                                                    value={clientProfile.firstName}
                                                    onChange={(event) => { onChange(event, 'Client', 'firstName') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Last Name</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="clientLastName"
                                                    size="small"
                                                    value={clientProfile.lastName}
                                                    onChange={(event) => { onChange(event, 'Client', 'lastName') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Email</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="clientEmail"
                                                    size="small"
                                                    value={clientProfile.email}
                                                    onChange={(event) => { onChange(event, 'Client', 'email') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {id === undefined &&
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                        <YellowButton fullWidth variant="contained" onClick={save} disabled={!edited || !loaded || saving}>Save Changes</YellowButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SquareButton fullWidth variant="outlined" disabled={!loaded} onClick={openDialog}>Change Password</SquareButton>
                                    </Grid>
                                </Grid>
                            }
                        </ColourPaper>
                    </Collapse>
                </Grid>
                {orgProfile &&
                    <Grid item xs={12} md={6}>
                        <Collapse in={loaded}>
                            <ColourPaper>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{clientProfile !== null ? "Billing Address Details" : "Address Details"}</Typography>
                                    </Grid>
                                    {orgProfile !== null &&
                                        <Grid item xs={12}>
                                            <Typography variant="body1">This is the address that will be used for in person appointments.</Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Address Line 1</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="addressLine1"
                                                    size="small"
                                                    value={clientProfile ? clientProfile.streetAddress1 : orgProfile ? orgProfile.streetAddress1 : ""}
                                                    onChange={(event) => { onChange(event, clientProfile ? 'Client' : 'Organisation', 'streetAddress1') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Address Line 2</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="addressLine2"
                                                    size="small"
                                                    value={clientProfile ? clientProfile.streetAddress2 : orgProfile ? orgProfile.streetAddress2 : ""}
                                                    onChange={(event) => { onChange(event, clientProfile ? 'Client' : 'Organisation', 'streetAddress2') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Address Line 3</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="addressLine3"
                                                    size="small"
                                                    value={clientProfile ? clientProfile.streetAddress3 : orgProfile ? orgProfile.streetAddress3 : ""}
                                                    onChange={(event) => { onChange(event, clientProfile ? 'Client' : 'Organisation', 'streetAddress3') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Town</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="city"
                                                    size="small"
                                                    value={clientProfile ? clientProfile.city : orgProfile ? orgProfile.city : ""}
                                                    onChange={(event) => { onChange(event, clientProfile ? 'Client' : 'Organisation', 'city') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>County</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="county"
                                                    size="small"
                                                    value={clientProfile ? clientProfile.province : orgProfile ? orgProfile.province : ""}
                                                    onChange={(event) => { onChange(event, clientProfile ? 'Client' : 'Organisation', 'province') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography><b>Eircode</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="postalCode"
                                                    size="small"
                                                    value={clientProfile ? clientProfile.postalCode : orgProfile ? orgProfile.postalCode : ""}
                                                    onChange={(event) => { onChange(event, clientProfile ? 'Client' : 'Organisation', 'postalCode') }}
                                                    fullWidth
                                                    inputProps={{
                                                        className: classes.textField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </ColourPaper>
                        </Collapse>
                    </Grid>
                }
            </Grid>
            <PasswordChangeDialog passwordAnchorEl={passwordAnchorEl} closeDialog={closeDialog} loaded={loaded} />
        </React.Fragment>
        :
        <Grid item xs={12}>
            <LinearProgress color="primary" />
        </Grid>

    return (
        <React.Fragment>
            {profilePage}
            <Prompt
                when={edited}
                message="Unsaved profile changes. Are you sure you want to leave?"
            />
        </React.Fragment>
    );
}