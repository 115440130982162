import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import SortableTableHead, { Order, HeadCell } from '../Utilities/SortableTableHead';
import RefundsListViewModel from '../ViewModels/Refunds/RefundsListViewModel';
import authService from '../api-authorization/AuthorizeService';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TextField from '@mui/material/TextField';
import SearchButton from '../Utilities/SearchButton';
import InputAdornment from '@mui/material/InputAdornment';
import ColourPaper from "../Utilities/ColourPaper";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import RefundsTableRow from './RefundsTableRow';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TableRow from '@mui/material/TableRow';
import { AlertContext } from '../Contexts/AlertContext';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@mui/material/Grid';
import SquareButton from '../Utilities/SquareButton';
import YellowButton from '../Utilities/YellowButton';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
        table: {
            minWidth: 500,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        dialogPaper: {
            padding: theme.spacing(2)
        },
    }),
);

export default function RefundsTable() {
    const classes = useStyles();
    const { show } = React.useContext(AlertContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof RefundsListViewModel>('firstName');
    const [results, setResults] = React.useState<RefundsListViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [refunds, setRefunds] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const [refundId, setRefundId] = React.useState(0);
    const [openRefundDialog, setOpenRefundDialog] = React.useState(false);
    const [refundAmount, setRefundAmount] = React.useState(0);
    const [refundBaseAmount, setRefundBaseAmount] = React.useState(0);
    const [refundDonationAmount, setRefundDonationAmount] = React.useState(0);

    const headCells: HeadCell<RefundsListViewModel>[] = refunds ?
        [
            { id: 'firstName', property: 'FirstName', align: "left", disablePadding: false, label: 'Forename' },
            { id: 'lastName', property: 'LastName', align: "left", disablePadding: false, label: 'Surname' },
            { id: 'slotDate', property: 'SlotDate', align: "left", disablePadding: false, label: 'Date' },
            { id: 'orderId', property: 'OrderId', align: "left", disablePadding: false, label: 'Order ID' },
            { id: 'cancellationDate', property: 'CancellationDate', align: "left", disablePadding: false, label: 'Cancellation Date' },
            { id: 'outcome', property: 'Outcome', align: "left", disablePadding: false, label: 'Outcome' },
            { id: 'baseAmount', property: 'BaseAmount', align: "right", disablePadding: false, label: 'Base Amount' },
            { id: 'donationAmount', property: 'DonationAmount', align: "right", disablePadding: false, label: 'Donation Amount' }
        ]
        :
        [
            { id: 'firstName', property: 'FirstName', align: "left", disablePadding: false, label: 'Forename' },
            { id: 'lastName', property: 'LastName', align: "left", disablePadding: false, label: 'Surname' },
            { id: 'slotDate', property: 'SlotDate', align: "left", disablePadding: false, label: 'Date' },
            { id: 'orderId', property: 'OrderId', align: "left", disablePadding: false, label: 'Order ID' },
            { id: 'cancellationDate', property: 'CancellationDate', align: "left", disablePadding: false, label: 'Cancellation Date' },
            { id: 'outcome', property: 'Outcome', align: "left", disablePadding: false, label: 'Outcome' },
            { id: 'baseAmount', property: 'BaseAmount', align: "right", disablePadding: false, label: 'Base Amount' },
            { id: 'donationAmount', property: 'DonationAmount', align: "right", disablePadding: false, label: 'Donation Amount' },
            { id: 'refundAmount', property: 'RefundAmount', align: "right", disablePadding: false, label: 'Refunded Amount' }
        ];

    React.useEffect(() => {
        getData();
    }, [order, orderBy, refunds]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        fetch(`Appointment/GetRefundsTable?orderBy=${orderByProp}&order=${order}&search=${search}&refunds=${refunds}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                setResults(data.rows);
                setCount(data.rowCount);
                setLoading(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof RefundsListViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            getData();
        }
    }

    const refundsButton = () => {
        setRefunds(true);
    }

    const retainsButton = () => {
        setRefunds(false);
    }

    const refund = (id: number, base: number, donation: number) => {
        setRefundId(id);
        setRefundBaseAmount(base);
        setRefundDonationAmount(donation);
        toggleRefundDialog();
    }

    const toggleRefundDialog = () => {
        setOpenRefundDialog((prev) => !prev);
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = isNaN(event.target.valueAsNumber) ?
            event.target.name === 'mainUser' ?
                event.target.checked :
                event.target.value :
            event.target.valueAsNumber;

        if ((value as number) >= refundBaseAmount) setRefundAmount(refundBaseAmount);
        else if ((value as number) <= 0) setRefundAmount(0);
        else setRefundAmount(value as number);
    }

    const processRefund = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`Appointment/Refund?id=${refundId}&amount=${refundAmount}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    show('success', 'Confirmed refund.');
                }

                toggleRefundDialog();
                getData();
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const retain = async (id: number) => {
        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`Appointment/Retain?id=${id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    show('success', 'Confirmed retain.');
                }

                getData();
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const closeRefundDialog = () => {
        toggleRefundDialog();
        setRefundAmount(0);
    }

    return (
        <ColourPaper>
            <Stack direction="column" spacing={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={5}>
                        <Typography variant="h5">Refunds Management</Typography>
                        <ButtonGroup variant="contained">
                            <Button variant={refunds ? "contained" : "outlined"} onClick={refundsButton}>Pending Refunds</Button>
                            <Button variant={refunds ? "outlined" : "contained"} onClick={retainsButton}>Completed Refunds</Button>
                        </ButtonGroup>
                    </Stack>
                    <TextField
                        color="primary"
                        size="small"
                        variant="outlined"
                        onChange={handleSearch}
                        value={search}
                        placeholder="Search..."
                        onKeyDown={keyPress}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchButton aria-label={"Search Architects"} onClick={getData} />
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
                <Stack direction="row" justifyContent="center">
                    <TableContainer>
                        <ResponsiveTable
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="sys admin table"
                        >
                            <SortableTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={headCells}
                                lastCells={
                                    <React.Fragment>
                                        <TableCell align="right" colSpan={1}>Booked by Admin</TableCell>
                                        <TableCell colSpan={1} />
                                    </React.Fragment>
                                }
                            />
                            <TableBody>
                                {!loading && results && results.map((row: RefundsListViewModel) => {
                                    return (<RefundsTableRow key={row.id} row={row} refunds={refunds} saving={saving} refund={refund} retain={retain} />);
                                })}
                                {loading &&
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="secondary" /><Typography>Pulling latest data</Typography></TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </ResponsiveTable>
                        {count <= 0 && !loading &&
                            <Stack direction="row" justifyContent="center" alignItems="flex-end">
                                <Typography variant="h5">No {refunds ? "pending refunds" : "completed refunds"} found.</Typography>
                            </Stack>
                        }
                    </TableContainer>
                </Stack>
            </Stack>
            <Dialog
                fullWidth
                open={openRefundDialog}
                onClose={toggleRefundDialog}
                PaperProps={{ className: classes.dialogPaper, square: true }}
                maxWidth="sm"
            >
                <ValidatorForm onSubmit={processRefund}>
                    <DialogTitle>Refund Details</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextValidator
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                    variant="outlined"
                                    label="Refund Amount"
                                    name="refundAmount"
                                    type="number"
                                    value={refundAmount}
                                    onChange={onChange}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                                    }}
                                    inputProps={{
                                        min: 0,
                                        max: `${(refundBaseAmount + refundDonationAmount).toFixed(2)}`,
                                        step: ".01"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SquareButton onClick={closeRefundDialog} variant="outlined" color="error" disabled={saving}>Cancel</SquareButton>
                        <YellowButton variant="contained" type="submit" disabled={saving}>Mark as Refunded</YellowButton>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </ColourPaper>
    );
}