import * as React from 'react';
import Layout from './components/Layout';
import Home from './components/Home';
import Success from './components/Success';
import { ThemeProvider, StyledEngineProvider, Theme, responsiveFontSizes } from '@mui/material/styles';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import AlertProvider from './components/Contexts/AlertContext';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import CustomTheme from './components/Utilities/CustomTheme';
import ProfileDetails from './components/Profile/ProfileDetails';
import ArchitectsContainer from './components/Architects/ArchitectsContainer';
import AdminContainer from './components/Admin/AdminContainer';
import BookingContainer from './components/Booking/BookingContainer';
import RegisterConfirmation from './components/Profile/RegisterConfirmation';
import ReportsContainer from './components/Reports/ReportsContainer';
import PaymentInformation from './components/Payments/PaymentInformation';
import GuestPayment from './components/Payments/GuestPayment';
import PaymentSuccess from './components/Payments/PaymentSuccess';
import authService from './components/api-authorization/AuthorizeService';
import BigCampaignTotal from './components/Reports/BigCampaignTotal';
import CookiePolicy from './components/CookiePolicy';
import ClientDashboard from './components/Client/ClientDashboard';
import BookingDialog from './components/Booking/BookingDialog';
import RefundsContainer from './components/Refunds/RefundsContainer';
import PaymentResponseURL from './components/Payments/PaymentResponseURL';
import { Route, Switch } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

const atlasFont = {
    fontFamily: 'Atlas Grotesk',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `local('AtlasGrotesk'),
        local('AtlasGrotesk-Regular'),
        url('./fonts/AtlasGrotesk-Regular.otf')`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

let theme = CustomTheme({
    typography: {
        fontFamily: 'Atlas Grotesk'
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    '@font-face': [atlasFont]
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 0
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        }
    },
    palette: {
        background: { default: '#F6F6F6' },
        primary: {
            main: '#1E196A',
            contrastText: '#fff'
        },
        secondary: {
            main: '#FAE205',
            contrastText: '#1E196A'
        },
        tertiary: { main: '#00AEEF' },
        warning: { main: '#f37360' },
        error: { main: '#F05B72' },
        success: { main: '#5EC4B6' }
    }
});

theme.shadows[8] = "0px 5px 5px -3px rgba(0,0,0,0.06),0px 8px 10px 1px rgba(0,0,0,0.04),0px 3px 14px 2px rgba(0,0,0,0.04)";

theme.palette.tertiary = theme.palette.augmentColor({
    color: {
        main: '#00AEEF'
    }
});

theme = responsiveFontSizes(theme);

export default function App() {
    React.useEffect(() => {
        logLogin();
    }, [])

    const logLogin = async () => {
        const token = await authService.getAccessToken();
        const id = await authService.getUser();
        if (id) {
            fetch(`User/AddLoginLog?id=${id.sub}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.text())
                .then(data => {
                }).catch((error) => {
                    Bugsnag.notify(error);
                });
        }
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider>
                    <AlertProvider>
                        <Layout>
                            <Switch>
                                <AuthorizeRoute exact path='/' component={Home} />
                                <AuthorizeRoute exact path='/Admin' component={AdminContainer} />
                                <AuthorizeRoute exact path='/AppointmentPayment' component={PaymentInformation} />
                                <AuthorizeRoute exact path='/Architects' component={ArchitectsContainer} />
                                <AuthorizeRoute exact path='/BigCampaignTarget' component={BigCampaignTotal} />
                                <AuthorizeRoute exact path='/Book/Create/:id/:routeTimeSlotId' component={BookingDialog} />
                                <AuthorizeRoute exact path='/Book/Create' component={BookingDialog} />
                                <AuthorizeRoute exact path='/Book' component={BookingContainer} />
                                <AuthorizeRoute exact path='/Book/:showWarning' component={BookingContainer} />
                                <AuthorizeRoute exact path='/ClientDashboard' component={ClientDashboard} />
                                <AuthorizeRoute exact path='/Profile' component={ProfileDetails} />
                                <AuthorizeRoute exact path='/Refunds' component={RefundsContainer} />
                                <AuthorizeRoute exact path='/Reports' component={ReportsContainer} />
                                <Route path='/CookiesPolicy' component={CookiePolicy} />
                                <Route exact path='/api/Payments/Success' component={Success} />
                                <Route path='/GuestPaymentInformation/:purchaseRecordId' component={PaymentInformation} />
                                <Route path='/GuestPaymentInformation' component={PaymentInformation} />
                                <Route path='/GuestPayment' component={GuestPayment} />
                                <Route path='/PaymentSuccess/:orderId/:type' component={PaymentSuccess} />
                                <Route path='/RegisterConfirmation/:userId/:code' component={RegisterConfirmation} />
                                <Route path='/PaymentResponseURL' component={PaymentResponseURL} />
                                <Route path={ApplicationPaths.ApiAuthorizationPrefix} ><ApiAuthorizationRoutes /></Route>
                            </Switch>
                        </Layout>
                    </AlertProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
