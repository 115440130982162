export default class ReportAppointmentListViewModel {
    public id: number = 0;
    public architect: string = '';
    public organisation: string = '';
    public memberId: string = '';
    public date: Date | null = null;
    public time: Date | null = null;
    public isOnlineMeeting: boolean = false;
    public firstName: string = '';
    public lastName: string = '';
    public phoneNumber: string = '';
    public email: string = '';
    public projectDescription: string = '';
    public eirCode: string = '';
    public address: string = '';
    public meetingLink: string = '';
    public outcome: number | null = null;
    public architectEmail: string = '';
    public architectPhone: string = '';
}