import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { UserDropdownViewModel } from '../ViewModels/Utilities/DropdownViewModel';
import Bugsnag from '@bugsnag/js';

interface IProps {
    practice: string | number | null;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    editable: boolean;
    alignRight?: boolean;
}

export default function PracticeDropdown(props: IProps) {
    const { practice, onChange, editable, alignRight } = props;
    const [items, setItems] = React.useState<UserDropdownViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        const token = await authService.getAccessToken();
        setLoaded(false);

        fetch(`Organisation/GetOrganisationDropdown`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setItems(data);
                setLoaded(true);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoaded(true);
            });
    }

    return (
        <TextField
            disabled={!editable || !loaded}
            select
            name='practice'
            size="small"
            label='Practice'
            fullWidth
            variant="outlined"
            value={practice}
            onChange={onChange}
            InputProps={{
                style: {
                    textAlign: alignRight ? 'right' : 'start'
                },
                startAdornment: (
                    <React.Fragment>
                        {loaded ? null : <CircularProgress size={20} />}
                    </React.Fragment>
                ),
            }}
        >
            <MenuItem key={'All Practices'} value={'All Practices'}><i>All Practices</i></MenuItem>
            {items.map(item => {
                return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>;
            })}
        </TextField>
    );
}