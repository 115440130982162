import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function CookiePolicy() {
    const ref = React.createRef<HTMLDivElement>();

    React.useEffect(() => {
        if (ref.current) {
            const script = document.createElement('script');
            script.id = 'CookieDeclaration';
            script.src = 'https://consent.cookiebot.com/a746588c-1d5b-4445-b3cd-02da0ff2d58c/cd.js';
            script.async = true;

            ref.current.appendChild(script);
        }
    }, [ref]);

    return (
        <Stack spacing={2}>
            <Typography variant="h4">Cookie Policy</Typography>
            <Typography>Disclaimer: You must have your ad blocker disabled to manage your cookies.</Typography>
            <div ref={ref}></div>
        </Stack>
    );
}