import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import authService from '../api-authorization/AuthorizeService';
import Collapse from '@mui/material/Collapse';
import AppointmentListViewModel from '../ViewModels/Booking/AppointmentListViewModel';
import ArchitectListRow from './ArchitectListRow';
import WarningDialog from '../Utilities/WarningDialog';
import { AlertContext } from '../Contexts/AlertContext';
import { useHistory } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import YellowButton from '../Utilities/YellowButton';
import Bugsnag from '@bugsnag/js';
import ListItem from '@mui/material/ListItem';
import Alert from '@mui/material/Alert';

interface IProps {
    selectedId: string;
    online: boolean;
    inperson: boolean;
    conservation: boolean;
    date: Date | null;
    oldAppointmentId?: number;
    id?: string;
    canBook: boolean;
}

class GroupedList {
    name: string = '';
    showList: boolean = false;
    list: AppointmentListViewModel[] = [];
    count: number = 0;
    conservation: boolean = false;
}

export default function ArchitectList(props: IProps) {
    const history = useHistory();
    const { selectedId, online, inperson, conservation, date, oldAppointmentId, id, canBook } = props;
    const { show } = React.useContext(AlertContext);
    const [list, setList] = React.useState<GroupedList[]>([]);
    const [selected, setSelected] = React.useState<AppointmentListViewModel | null>(null);
    const [rescheduling, setRescheduling] = React.useState(false);
    const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
    const [openDetailsWarningDialog, setOpenDetailsWarningDialog] = React.useState(false);

    React.useEffect(() => {
        getList();
    }, [selectedId, online, inperson, conservation]);

    const goToBooking = () => {
        if (selected && !oldAppointmentId) {
            history.push({
                pathname: '/Book/Create',
                state: {
                    timeslotId: selected.id,
                    clientId: id
                }
            });
        }
    }

    const getList = async () => {
        if (selectedId && selectedId !== "") {
            const token = await authService.getAccessToken();
            const dateParam = date ? '&date=' + encodeURIComponent(date?.toISOString()) : '';

            fetch(`Appointment/GetAvailable?organisation=${selectedId}&online=${online}&inperson=${inperson}&conservation=${conservation}${dateParam}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.json())
                .then((data) => {
                    setList(data);
                }).catch((error) => {
                    Bugsnag.notify(error);
                });
        }
    }

    const openListView = (name: string) => {
        const itemIndex = list.findIndex(f => f.name === name);

        if (itemIndex > -1) {
            const newList = [...list];
            const item = { ...newList[itemIndex] };
            item.showList = !item.showList;
            newList[itemIndex] = item;

            setList(newList);
        }
    }

    const selectAppointment = (item: AppointmentListViewModel) => {
        if (oldAppointmentId && oldAppointmentId !== 0) {
            lockSlot(item.id);
            toggleWarningDialog();
        }
        else {
            toggleDetailsWarningDialog();
        }
        setSelected(item);
    }

    const toggleDetailsWarningDialog = () => {
        setOpenDetailsWarningDialog((prev) => !prev);
    }

    const toggleWarningDialog = () => {
        setOpenWarningDialog((prev) => !prev);
    }

    const lockSlot = async (id: number) => {
        const token = await authService.getAccessToken();

        fetch(`Appointment/LockTimeSlot?id=${id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    show('error', data);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
            });
    }

    const unlockSlot = async () => {
        if (selected) {
            const token = await authService.getAccessToken();
            toggleWarningDialog();

            fetch(`Appointment/OpenTimeSlot?id=${selected.id}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.text())
                .then(data => {
                    if (data.length > 0) {
                        show('error', data);
                    }
                }).catch((error) => {
                    Bugsnag.notify(error);
                });
        }
    }

    const reschedule = async () => {
        if (selected) {
            setRescheduling(true);
            const token = await authService.getAccessToken();

            let url = `Appointment/RescheduleAppointment?oldAppointmentId=${oldAppointmentId}&timeslotId=${selected.id}`;
            if (id) url = url + `&clientId=${id}`;
            fetch(url, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.text())
                .then(data => {
                    if (data.length > 0) {
                        show('error', data);
                    } else {
                        show('success', 'Successfully rescheduled appointment.');
                    }
                    toggleWarningDialog();
                    setRescheduling(false);
                    history.push('/');
                }).catch((error) => {
                    Bugsnag.notify(error);
                });
        }
    }

    return (
        <React.Fragment>
            <List>
                {list.map(item =>
                    <Paper key={item.name} sx={{ marginBottom: 1 }} square>
                        <ListItemButton onClick={() => openListView(item.name)} disabled={item.count === 0} sx={{ '&.Mui-disabled': { opacity: 1 } }}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <ListItemText primary={item.name} secondary={item.conservation ? 'Conservation' : ''} />
                                    <InputLabel shrink>{item.count > 0 ? "Appointments Available: " + item.count : "Appointments Unavailable"}</InputLabel>
                                </Grid>
                                <Grid item>
                                    {item.count === 0 ? <YellowButton variant="contained">No Availability</YellowButton> :
                                        item.showList ? <YellowButton variant="contained">Hide Availability</YellowButton> :
                                            <YellowButton variant="contained">Show Availability</YellowButton>}
                                </Grid>
                            </Grid>
                        </ListItemButton>
                        <Collapse in={item.showList} timeout="auto" unmountOnExit>
                            <List>
                                <ListItem>
                                    <Alert sx={{ width: '100%', alignItems: 'center' }} severity="info">Not finding the right appointment date or time? Contact <a href="mailto:simon@riai.ie">simon@riai.ie</a></Alert>
                                </ListItem>
                                <ArchitectListRow list={item.list} selectAppointment={selectAppointment} canBook={canBook} />
                            </List>
                        </Collapse>
                    </Paper>
                )}
            </List>
            {selected && (oldAppointmentId && oldAppointmentId !== 0) && <WarningDialog text="Continuing will result in you taking this slot for your cancelled appointment. You will be able to cancel this for a fee, unless cancelled by the architect. Do you wish to continue?" buttonText="Continue" open={selected !== null && openWarningDialog} okClick={reschedule} close={unlockSlot} disabled={rescheduling} />}
            <WarningDialog title="Do you have the Project Details to hand?" text="Please make sure to have all of your project details handy. The next screen is timed, but you may change the project details later on from the dashboard." buttonText="Continue Booking" closeLabel="Go Back" open={openDetailsWarningDialog} okClick={goToBooking} close={toggleDetailsWarningDialog} />
        </React.Fragment>
    );
}