import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SquareButton from '../../Utilities/SquareButton';
import YellowButton from '../../Utilities/YellowButton';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { AlertContext } from '../../Contexts/AlertContext';
import { CreateSystemAdministratorUserViewModel } from '../../ViewModels/User/CreateUserViewModel';
import InputLabel from '@mui/material/InputLabel';
import authService from '../../api-authorization/AuthorizeService';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(0)
        },
    })
);

interface IProps {
    open: boolean;
    editId: string;
    onClose: (refresh: boolean) => void;
}

export default function EditSysAdminDialog(props: IProps) {
    const classes = useStyles();
    const { open, editId, onClose } = props;
    const { show } = React.useContext(AlertContext);
    const [admin, setAdmin] = React.useState<CreateSystemAdministratorUserViewModel>(new CreateSystemAdministratorUserViewModel());
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        if (editId && editId != "" && open) {
            getAdmin();
        }
    }, [editId, open])

    const getAdmin = async () => {
        const token = await authService.getAccessToken();

        fetch(`User/GetSysAdmin?id=${editId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setAdmin(data[0]);
            }).catch((error) => {
                Bugsnag.notify(error);
            });
    }

    const save = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`User/UpdateSystemAdmin?id=${editId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(admin)
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    show('error', data);
                    onClose(false);
                } else {
                    onClose(true);
                    setAdmin(new CreateSystemAdministratorUserViewModel());
                    show('success', 'Successfully created user');
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
                onClose(false);
            });
    }

    const cancel = () => {
        onClose(false);
        setAdmin(new CreateSystemAdministratorUserViewModel());
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdmin({
            ...admin,
            [event.target.name]: event.target.value
        });
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={cancel}
            PaperProps={{ className: classes.paper, square: true }}
        >
            <ValidatorForm onSubmit={save}>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="firstName" shrink>First Name</InputLabel>
                            <TextValidator
                                fullWidth
                                size="small"
                                variant="outlined"
                                name="firstName"
                                value={admin.firstName}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="lastName" shrink>Last Name</InputLabel>
                            <TextValidator
                                fullWidth
                                size="small"
                                variant="outlined"
                                name="lastName"
                                value={admin.lastName}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="email" shrink>Email</InputLabel>
                            <TextValidator
                                fullWidth
                                size="small"
                                variant="outlined"
                                name="email"
                                value={admin.email}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={cancel} variant="outlined" color="error" disabled={saving}>Cancel</SquareButton>
                    <YellowButton variant="contained" type="submit" disabled={saving}>Edit</YellowButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}