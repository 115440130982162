import * as React from "react";
import authService from '../api-authorization/AuthorizeService';
import { Calendar, Event, SlotInfo, momentLocalizer, stringOrDate } from 'react-big-calendar';
import 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import TimeSlotDialog from "./TimeSlotDialog";
import ArchitectViewModel from "../ViewModels/Architects/ArchitectViewModel";
import CreateCampaignSettingsViewModel from "../ViewModels/Campaign/CreateCampaignSettingsViewModel";
import CalendarToolbar from "../Utilities/CalendarToolbar";
import ColourPaper from "../Utilities/ColourPaper";
import Bugsnag from "@bugsnag/js";

const moment = require('moment');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        disabledDate: {
            backgroundColor: theme.palette.grey[400],
            color: '#999999'
        },
        calendar: {
            minHeight: '600px',
            height: '800px',
            '& .rbc-event-content': {
                fontSize: '14px'
            },
            '& .rbc-allday-cell': {
                display: 'none'
            },
            '& .rbc-time-view .rbc-header': {
                borderBottom: 'none'
            },
            '& .rbc-label': {
                fontSize: '0.875rem'
            },
            '& .rbc-day-slot .rbc-events-container': {
                marginRight: 0
            }
        },
        openEvent: {
            backgroundColor: '#00AEEF',
            '&.rbc-selected': {
                backgroundColor: '#00AEEF',
            },
            '& .rbc-event-label': {
                whiteSpace: 'normal'
            }
        },
        reservedEvent: {
            backgroundColor: '#F37360',
            '&.rbc-selected': {
                backgroundColor: '#F37360',
            },
            '& .rbc-event-label': {
                whiteSpace: 'normal'
            }
        },
        bookedEvent: {
            backgroundColor: '#5EC4B6',
            '&.rbc-selected': {
                backgroundColor: '#5EC4B6',
            },
            '& .rbc-event-label': {
                whiteSpace: 'normal'
            }
        }
    }),
);

interface IProps {
    architect: ArchitectViewModel | null;
    organisationId: string;
}

export interface TimeSlotEvent extends Event {
    id: number;
    status: string;
    link: string;
    inPerson: boolean;
    online: boolean;
}

function CustomEvent({ event }: any) {
    console.log(event);

    return (
        <span>
            {moment(event.start).format('HH:mm') + ' - ' + moment(event.end).format('HH:mm')}
        </span>
    );
}

function CustomWeekEvent({ event }: any) {
    return (
        <span/>
    );
}

export default function TimeslotCalendar(props: IProps) {
    const classes = useStyles();
    const { architect, organisationId } = props;
    moment.tz.setDefault('UTC');
    const localizer = momentLocalizer(moment);
    const [events, setEvents] = React.useState<TimeSlotEvent[]>([]);
    const [openCreate, setCreateOpen] = React.useState(false);
    const [selected, setSelected] = React.useState<TimeSlotEvent | null>(null);
    const [campaign, setCampaign] = React.useState(new CreateCampaignSettingsViewModel());
    const [inPerson, setInPerson] = React.useState(false);
    const [online, setOnline] = React.useState(false);

    React.useEffect(() => {
        getCampaignSettings();
        getLocationMeetingSettings();
    }, []);

    React.useEffect(() => {
        if (architect && architect.id > 0) {
            getTimeslots();
        }
    }, [architect]);

    React.useEffect(() => {
        if (selected !== null && architect !== null && campaign.canCreateSlots) {
            setCreateOpen(true);
        }
    }, [selected]);

    const getLocationMeetingSettings = async () => {
        const token = await authService.getAccessToken();

        fetch(`Organisation/GetMeetingPreferences?id=${organisationId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                setInPerson(data.inPerson);
                setOnline(data.online);
            }).catch((error) => {
                Bugsnag.notify(error);
            });
    }

    const getCampaignSettings = async () => {
        const token = await authService.getAccessToken();

        fetch(`Campaign/GetCurrentCampaignSettings`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                data.campaignStartDate = new Date(data.campaignStartDate);
                data.campaignEndDate = new Date(data.campaignEndDate);
                setCampaign(data);
            }).catch((error) => {
                Bugsnag.notify(error);
            });
    }

    const getTimeslots = async () => {
        const token = await authService.getAccessToken();

        fetch(`Architect/GetTimeslots?id=${architect?.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                data = data.map((item: any) => ({
                    id: item.id,
                    title: item.title,
                    start: moment.utc(item.start).toDate(),
                    end: moment.utc(item.end).toDate(),
                    status: item.status,
                    link: item.link,
                    online: item.online,
                    inPerson: item.inPerson
                }));
                setEvents(data);
            }).catch((error) => {
                Bugsnag.notify(error);
            });
    }

    const styleDisabledDates = (date: Date) => {
        const mDate = moment(date);

        if (campaign.campaignStartDate && campaign.campaignEndDate) {
            if (mDate.isBefore(campaign.campaignStartDate, 'day') || mDate.isAfter(campaign.campaignEndDate, 'day')) {
                return {
                    className: classes.disabledDate
                };
            }
        }
        return {};
    }

    const onSelectDate = (slotInfo: SlotInfo) => {
        const date = typeof slotInfo.start === "string" ? new Date(slotInfo.start) : slotInfo.start;
        const momentDate = moment(date);

        if (campaign.campaignStartDate && campaign.campaignEndDate) {
            if (!momentDate.isBefore(campaign.campaignStartDate, 'day') && momentDate.isBefore(moment(campaign.campaignEndDate).add(1, 'd').toDate(), 'day')) {
                setSelected({
                    id: 0,
                    title: '',
                    start: new Date(date),
                    end: new Date(date),
                    status: '',
                    link: '',
                    online: online,
                    inPerson: inPerson
                });
            }
        }
    }

    const addButtonClick = () => {
        if (campaign.campaignStartDate && campaign.campaignEndDate) {
            setSelected({
                id: 0,
                title: '',
                start: new Date(campaign.campaignStartDate.getFullYear(), campaign.campaignStartDate.getMonth(), campaign.campaignStartDate.getDate(), 9, 0, 0, 0),
                end: new Date(campaign.campaignStartDate.getFullYear(), campaign.campaignStartDate.getMonth(), campaign.campaignStartDate.getDate(), 9, 0, 0, 0),
                status: '',
                link: '',
                online: online,
                inPerson: inPerson
            });
        }
    }

    const closeCreate = (refresh: boolean) => {
        if (refresh) {
            getTimeslots();
        }

        setSelected(null);
        setCreateOpen(false);
    }

    const styleEvent = (event: TimeSlotEvent, start: stringOrDate, end: stringOrDate, isSelected: boolean) => {
        switch (event.status) {
            case 'Open':
                return {
                    className: classes.openEvent
                };
            case 'Locked':
                return {
                    className: classes.reservedEvent
                };
            case 'Booked':
                return {
                    className: classes.bookedEvent
                };
            default:
                return {
                    className: classes.openEvent
                };
        }
    }

    return (
        <ColourPaper sx={{border: 2, borderColor: 'primary.main' }}>
            {campaign && campaign.campaignStartDate &&
                <div className={classes.calendar}>
                    <Calendar
                        selectable
                        localizer={localizer}
                        views={{
                            month: true, week: true
                        }}
                        defaultView='week'
                        defaultDate={campaign.campaignStartDate}
                        dayPropGetter={styleDisabledDates}
                        onSelectSlot={onSelectDate}
                        onSelectEvent={(event) => setSelected(event)}
                        onSelecting={() => false}
                        events={events}
                        popup
                        components={{
                            toolbar: (props) => <CalendarToolbar {...props} addButtonClick={addButtonClick} disabled={!architect || architect.id === 0} name={architect?.name ?? ''} canCreate={campaign.canCreateSlots} />,
                            event: CustomEvent,
                            week: { event: CustomWeekEvent }
                        }}
                        eventPropGetter={styleEvent}
                        min={moment().startOf('day').hour(8).toDate()}
                        max={moment().startOf('day').hour(22).toDate()}
                        allDayAccessor={() => false}
                        showMultiDayTimes={true}
                    />
                    {architect && selected && campaign.campaignStartDate && campaign.campaignEndDate &&
                        <TimeSlotDialog
                            architect={architect}
                            slot={selected}
                            open={openCreate}
                            close={closeCreate}
                            startDate={campaign.campaignStartDate}
                            endDate={campaign.campaignEndDate}
                        />}
                </div>
            }
        </ColourPaper>
    );
}