import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import Table, { TableProps } from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scrollTable: {
            display: "block",
            overflowX: "auto"
        }
    })
);

export default function ResponsiveTable(props: TableProps) {
    const classes = useStyles();

    return (
        <div className={classes.scrollTable}>
            <Table {...props} />
        </div>
    );
}

export const SmallTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 8
        }
    }),
)(TableCell);