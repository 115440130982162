import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ValidatorForm } from 'react-material-ui-form-validator';
import authService from '../api-authorization/AuthorizeService';
import ArchitectViewModel from '../ViewModels/Architects/ArchitectViewModel';
import { AlertContext } from '../Contexts/AlertContext';
import SquareButton from '../Utilities/SquareButton';
import YellowButton from '../Utilities/YellowButton';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import TimeSlotValidator from './TimeSlotValidator';
import { TimeSlotEvent } from './TimeslotCalendar';
import DateValidator from '../Utilities/DateValidator';
import MeetingPreferenceValidator from './MeetingPreferenceValidator';
import WarningDialog from '../Utilities/WarningDialog';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(0)
        },
    })
);

interface IProps {
    architect: ArchitectViewModel;
    slot: TimeSlotEvent;
    open: boolean;
    close: (refresh: boolean) => void;
    startDate: Date;
    endDate: Date;
}

export default function TimeSlotDialog(props: IProps) {
    const classes = useStyles();
    const { open, close, architect, slot, startDate, endDate } = props;
    const { show } = React.useContext(AlertContext);
    const [time, setTime] = React.useState<Date | null>(null);
    const [date, setDate] = React.useState<moment.Moment | null>(null);
    const [newSlot, setSlot] = React.useState(slot);
    const [existingTimes, setExistingTimes] = React.useState<Date[]>([]);
    const [removing, setRemoving] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
    const [clientName, setClientName] = React.useState('');
    const [role, setRole] = React.useState('');

    ValidatorForm.addValidationRule('isAvailableTime', (value) => {
        if (!value && !value.isValid())
            return true;

        if (time !== null) {
            const dateValue = new Date(value);
            const newTime = moment({ y: date?.dayOfYear(), M: date?.month(), d: date?.date(), h: dateValue.getUTCHours(), m: dateValue.getMinutes() });

            if (existingTimes.findIndex(i => {
                const d = moment.utc(i).toDate();

                if (slot.id > 0 && slot.start && moment(d).isSame(moment(slot.start))) {
                    return false;
                } else {
                    const start = moment({ y: d.getFullYear(), M: d.getMonth(), d: d.getDate(), h: d.getUTCHours(), m: d.getMinutes() }).subtract(59, 'minutes');
                    const end = moment({ y: d.getFullYear(), M: d.getMonth(), d: d.getDate(), h: d.getUTCHours(), m: d.getMinutes() }).add(59, 'minutes');
                    const result = moment(newTime).isBetween(start, end, 'minutes', '[]');
                    return result;
                }
            }) > -1) {
                return false;
            }
        }

        return true;
    });

    ValidatorForm.addValidationRule('isValidTime', (value) => {
        if (!value && !value.isValid())
            return false;

        return true;
    });

    ValidatorForm.addValidationRule('isValidDate', (value: any) => {
        if (!value || !moment(value, 'DD/MM/YYYY', true).isValid()) {
            return false;
        }

        if (moment(value).isBefore(startDate, 'day')) {
            return false;
        }
        if (moment(value).isAfter(endDate, 'day')) {
            return false;
        }
        return true;
    });

    React.useEffect(() => {
        getExisting();
    }, []);

    React.useEffect(() => {
        if (slot.start) {
            const date = new Date(slot.start!);
            setTime(date);
            //setTime(moment({ h: date.getHours(), m: date.getMinutes() }).toDate());
        }
        setSlot(slot);
        setDate(slot.start ? moment.utc(slot.start) : null);
    }, [slot]);

    React.useEffect(() => {
        if (slot.id > 0) {
            getClientName();
        }
    }, [slot.id]);

    React.useEffect(() => {
        getExisting();
    }, [date]);

    const getExisting = async () => {
        const user = await authService.getUser();
        setRole(user.role);
        if (date && moment(date).isValid()) {
            const token = await authService.getAccessToken();
            setLoading(true);
            const start = moment.utc(date!);

            fetch(`Architect/GetSlotsForDate?date=${start.toISOString()}&id=${architect.id}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
            })
                .then(async response => {
                    if (response.ok) {
                        const data = await response.json();
                        setExistingTimes(data);
                    }
                    setLoading(false);
                }).catch((error) => {
                    Bugsnag.notify(error);
                    setLoading(false);
                });
        }
    }

    const getClientName = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Client/GetClientNameForTimeslot?timeslotId=${slot.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then(async response => {
                if (response.ok) {
                    const data = await response.text();
                    setClientName(data);
                }
                setLoading(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const create = async () => {
        if (!newSlot.online && !newSlot.inPerson)
            return;

        const token = await authService.getAccessToken();
        setSaving(true);
        const start = date!;

        fetch(`Architect/CreateSlot`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({
                architectId: architect.id,
                date: start.toISOString(),
                time: time?.toISOString(),
                meetingLink: newSlot.link,
                online: newSlot.online,
                inPerson: newSlot.inPerson
            })
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    show('success', 'Successfully created slot');
                    setTime(null);
                    close(true);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const save = async () => {
        if (!newSlot.online && !newSlot.inPerson)
            return;

        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`Architect/EditSlot`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({
                id: slot.id,
                architectId: architect.id,
                date: date?.toISOString(),
                time: time?.toISOString(),
                meetingLink: newSlot.link,
                online: newSlot.online,
                inPerson: newSlot.inPerson
            })
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    show('success', `Successfully edited ${slot.status === "Booked" ? "appointment" : "slot"}`);
                    setTime(null);
                    close(true);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const remove = async () => {
        const token = await authService.getAccessToken();
        setRemoving(true);

        fetch(`Architect/RemoveSlot?id=${slot.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then(response => response.text())
            .then(data => {
                setRemoving(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    show('success', 'Slot successfully removed');
                    setTime(null);
                    close(true);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setRemoving(false);
            });
    }

    const closeDialog = () => {
        setTime(null);
        close(true);
    }

    const cancel = async () => {
        const token = await authService.getAccessToken();
        setRemoving(true);
        toggleWarningDialog();

        fetch(`Appointment/ArchitectCancel?id=${slot.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then(response => response.text())
            .then(data => {
                setRemoving(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    show('success', 'Appointment cancelled. The client has been sent reschedule/refund options');
                    setTime(null);
                    close(true);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setRemoving(false);
            });
    }

    const disableTimes = (timeValue: number, clockType: 'hours' | 'minutes' | 'seconds') => {
        if (time !== null) {
            const newTime = moment({ h: time.getUTCHours(), m: time.getMinutes() });

            if (clockType === 'minutes') {
                newTime.minutes(timeValue);
            }

            if (existingTimes.findIndex(i => {
                const d = new Date(i);
                return moment(newTime).isBetween(moment({ h: d.getUTCHours(), m: d.getMinutes() }).subtract(59, 'minutes'), moment({ h: d.getUTCHours(), m: d.getMinutes() }).add(59, 'minutes'), 'minutes', '[]');
            }) > -1) {
                return true;
            }
        }

        return false;
    }

    const toggleWarningDialog = () => {
        setOpenWarningDialog((prev) => !prev);
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth
                open={open}
                onClose={close}
                PaperProps={{ className: classes.paper, square: true }}
            >
                <DialogTitle>{slot.id > 0 ? 'Edit' : 'Create'} Appointment</DialogTitle>
                <ValidatorForm onSubmit={slot.id > 0 ? save : create}>
                    <DialogContent sx={{ paddingTop: 0 }}>
                        <Stack direction="column" spacing={2} alignItems="stretch">
                            <FormControl>
                                <FormLabel>Architect</FormLabel>
                                <Typography>{architect.firstName} {architect.lastName}</Typography>
                            </FormControl>
                            {(slot.id > 0 && clientName.length > 1) &&
                                <FormControl>
                                    <FormLabel>Client</FormLabel>
                                    <Typography>{clientName}</Typography>
                                </FormControl>
                            }
                            <DateValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Date"
                                name="start"
                                onChange={(value: any) => { setDate(value) }}
                                value={moment.utc(date)}
                                minDate={moment(startDate)}
                                maxDate={moment(endDate)}
                                disabled={(slot.id > 0 && slot.status !== "Open")}
                                validators={['required', 'isValidDate']}
                                errorMessages={['This field is required', 'Invalid Date']}
                            />
                            <TimeSlotValidator
                                disableOpenPicker
                                renderInput={(params: any) => <TextField {...params} fullWidth />}
                                label="Time*"
                                name="time"
                                value={time}
                                onChange={(newValue: Date | null) => {
                                    setTime(newValue);
                                }}
                                shouldDisableTime={disableTimes}
                                disabled={loading || (slot.id > 0 && slot.status !== "Open")}
                                ampm={false}
                                validators={['required', 'isValidTime', 'isAvailableTime']}
                                errorMessages={['This field is required', 'Invalid', 'Unavailable']}
                            />
                            <Typography variant="caption">You can select any time. For example 10:00, 10:15, 15:30</Typography>
                            <TextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Meeting Link"
                                value={newSlot.link}
                                onChange={(event) => setSlot({ ...newSlot, link: event.target.value })}
                                disabled={slot.status === "Locked"}
                            />
                            <Typography variant="caption">Please add a meeting join link</Typography>
                            <MeetingPreferenceValidator
                                online={newSlot.online}
                                inperson={newSlot.inPerson}
                                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => setSlot({ ...newSlot, [event.target.name]: event.target.checked })}
                                disabled={slot.status === "Locked" || (slot.status === "Booked" && role !== "Admin")}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <SquareButton onClick={closeDialog} variant="outlined" color="error" disabled={saving}>Close</SquareButton>
                        {slot.id > 0 && slot.status === "Open" && <SquareButton onClick={remove} variant="contained" color="error" disabled={removing || time === null} endIcon={removing ? <CircularProgress size={25} /> : <React.Fragment />}>Remove Slot</SquareButton>}
                        {slot.id > 0 && slot.status === "Booked" && <SquareButton onClick={toggleWarningDialog} variant="contained" color="error" disabled={removing || time === null} endIcon={removing ? <CircularProgress size={25} /> : <React.Fragment />}>Cancel Appointment</SquareButton>}
                        {slot.status !== "Locked" && <YellowButton variant="contained" type="submit" disabled={saving || time === null} endIcon={saving ? <CircularProgress size={25} /> : <React.Fragment />}>{slot.id > 0 ? 'Edit Slot' : 'Create Slot'}</YellowButton>}
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
            <WarningDialog text="Continuing with this action will cancel the appointment and provide the client with the options to either reschedule or refund the price." buttonText="Continue" open={openWarningDialog} close={toggleWarningDialog} okClick={cancel} />
        </React.Fragment>
    );
}