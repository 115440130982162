import * as React from 'react'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import OrganisationListViewModel from '../ViewModels/Booking/OrganisationListViewModel';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        success: {
            color: theme.palette.success.main
        },
        error: {
            color: theme.palette.error.main
        },
        list: {
            paddingTop: 0
        }
    }),
);

interface IProps {
    list: OrganisationListViewModel[];
    selectOrganisation: (id: string) => void;
    selectedId: string;
}

export default function OrganisationList(props: IProps) {
    const classes = useStyles();
    const { list, selectOrganisation, selectedId } = props;

    return (
        <List className={classes.list}>
            <Paper square>
                {list && list.map(item =>
                    <ListItemButton key={item.id} selected={selectedId === item.id} onClick={(event) => selectOrganisation(item.id)}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <ListItemText primary={item.name} secondary={item.location} />
                                <InputLabel shrink>{item.slots > 0 ? "Appointments Available: " + item.slots : "No Appointments Available"}</InputLabel>
                            </Grid>
                            <Grid item>
                                {selectedId === item.id ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
                            </Grid>
                        </Grid>
                    </ListItemButton>
                )}
            </Paper>
        </List>
    );
}