import * as React from 'react';
import ReportAppointmentListViewModel from "../ViewModels/Reports/ReportAppointmentListViewModel";
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import ColourPaper from '../Utilities/ColourPaper';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import ReportAppointmentTableRow from './ReportAppointmentTableRow';
import ArchitectDropdown from '../Utilities/ArchitectDropdown';
import PracticeDropdown from '../Utilities/PracticeDropdown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from '../Utilities/DatePicker';
import YellowButton from '../Utilities/YellowButton';
import AppointmentRowDialog from './AppointmentRowDialog';
import TablePagination from '@mui/material/TablePagination';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        exportButton: {
            alignItems: 'flex-end',
            justifyContentContent: 'center'
        },
    }),
);

const headCells: HeadCell<ReportAppointmentListViewModel>[] = [
    { id: 'architect', property: 'Architect', disablePadding: false, label: 'Architect' },
    { id: 'memberId', property: 'MemberID', disablePadding: false, label: 'Member ID' },
    { id: 'date', property: 'Date', disablePadding: false, label: 'Date' },
    { id: 'time', property: 'Date', disablePadding: false, label: 'Time' },
    { id: 'firstName', property: 'FirstName', disablePadding: false, label: 'Forename' },
    { id: 'lastName', property: 'LastName', disablePadding: false, label: 'Surname' },
    { id: 'email', property: 'Email', disablePadding: false, label: 'Email' }
];

interface IProps {
    role: string;
    id: string;
}

export default function ReportAppointmentTable(props: IProps) {
    const classes = useStyles();
    const { role, id } = props;
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof ReportAppointmentListViewModel>('architect');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [search, setSearch] = React.useState('');
    const [results, setResults] = React.useState<ReportAppointmentListViewModel[]>([]);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);
    const [filterOrg, setFilterOrg] = React.useState<string>('All Practices');
    const [filterArchitect, setFilterArchitect] = React.useState<string | number>(0);
    const [filterStartDate, setFilterStartDate] = React.useState<Date | null>(null);
    const [filterEndDate, setFilterEndDate] = React.useState<Date | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selectedRow, setSelectedRow] = React.useState<ReportAppointmentListViewModel>(new ReportAppointmentListViewModel());
    const [openViewAppointment, setOpenViewAppointment] = React.useState(false);
    const [doneInitialSearch, setDoneInitialSearch] = React.useState(false);

    React.useEffect(() => {
        getCampaignDates();
    }, []);

    React.useEffect(() => {
        if (filterStartDate && filterEndDate && !doneInitialSearch) {
            getData();
            setDoneInitialSearch(true);
        }
    }, [filterStartDate, filterEndDate, doneInitialSearch]);

    React.useEffect(() => {
        if (doneInitialSearch)
            getData();
    }, [order, orderBy, page, rowsPerPage]);

    const toggleViewAppointment = () => {
        setOpenViewAppointment((prev) => !prev);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ReportAppointmentListViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const value = isNaN(event.target.valueAsNumber) ?
            event.target.value :
            event.target.valueAsNumber;

        switch (name) {
            case "practice":
                setFilterOrg(event.target.value);
                break;
            case "architect":
                setFilterArchitect(value);
                break;
        }
    }

    const onDateChange = (newValue: Date | null, name: string) => {
        switch (name) {
            case "filterStartDate":
                setFilterStartDate(newValue);
                break;
            case "filterEndDate":
                setFilterEndDate(newValue);
                break;
        }
    }

    const getCampaignDates = async () => {
        const token = await authService.getAccessToken();

        fetch(`Campaign/GetCampaignDates`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setFilterStartDate(new Date(data.campaignStartDate));
                    setFilterEndDate(new Date(data.campaignEndDate));
                }
            })
            .catch(error => {
                Bugsnag.notify(error);
            });
    }

    const getData = async () => {
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        let url = `Reports/GetReportAppointmentTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&filterArchitect=${filterArchitect}${filterStartDate !== null ? `&filterStartDate=${filterStartDate.toISOString()}` : ""}${filterEndDate !== null ? `&filterEndDate=${filterEndDate.toISOString()}` : ""}`;
        if (role === "Organisation") url = url + `&filterOrg=${id}`;
        if (role === "Admin" || role === "SystemAdministrator") url = url + `&filterOrg=${filterOrg}`;

        fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setResults(data.rows);
                    setCount(data.count);
                }
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const downloadCSV = async () => {
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        setDownloading(true);

        let url = `Reports/DownloadTableCSV?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&filterArchitect=${filterArchitect}${filterStartDate !== null ? `&filterStartDate=${filterStartDate.toISOString()}` : ""}${filterEndDate !== null ? `&filterEndDate=${filterEndDate.toISOString()}` : ""}`;
        if (role === "Organisation") url = url + `&filterOrg=${id}`;
        if (role === "Admin" || role === "SystemAdministrator") url = url + `&filterOrg=${filterOrg}`;

        fetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.blob())
            .then(blob => {
                let dateTime = new Date();
                const formattedTime = dateTime.toLocaleDateString() + "_" + dateTime.toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit'
                });

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "Appointment_CSV_Export_" + formattedTime + ".csv";
                document.body.appendChild(a);
                a.click();
                a.remove();
                setDownloading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setDownloading(false);
            });
    }

    const downloadPDF = async () => {
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        setDownloading(true);

        let url = `Reports/DownloadTablePDF?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&filterArchitect=${filterArchitect}${filterStartDate !== null ? `&filterStartDate=${filterStartDate.toISOString()}` : ""}${filterEndDate !== null ? `&filterEndDate=${filterEndDate.toISOString()}` : ""}`;
        if (role === "Organisation") url = url + `&filterOrg=${id}`;
        if (role === "Admin" || role === "SystemAdministrator") url = url + `&filterOrg=${filterOrg}`;

        fetch(url, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.blob())
            .then(blob => {
                const myBlob = new Blob([blob], { type: 'application/pdf' })
                const url = URL.createObjectURL(myBlob);
                window.open(url, "_blank");
                setDownloading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setDownloading(false);
            });
    }

    const select = (id: number) => {
        let row = results.find(f => f.id === id);
        if (row) setSelectedRow(row);
        setOpenViewAppointment(true);
    }

    return (
        <div className={classes.root}>
            <ColourPaper>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center" justifyContent="space-evenly">
                            {(role === "Admin" || role === "SystemAdministrator") &&
                                <Grid item xs={12} sm={6} md={2}>
                                    <PracticeDropdown practice={filterOrg} onChange={(event) => { onChange(event, 'practice') }} editable />
                                </Grid>
                            }
                            <Grid item xs={12} sm={6} md={2}>
                                <ArchitectDropdown architect={filterArchitect} onChange={(event) => { onChange(event, 'architect') }} editable orgId={role === "Organisation" ? id : (filterOrg !== null && filterOrg !== "All Practices") ? filterOrg : undefined} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <DatePicker
                                    date={filterStartDate}
                                    name="filterStartDate"
                                    disablePast={false}
                                    disableFuture={false}
                                    onChange={onDateChange}
                                    disabled={false}
                                    size="small"
                                    clearable={true}
                                    label="Start Date"

                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <DatePicker
                                    date={filterEndDate}
                                    name="filterEndDate"
                                    disablePast={false}
                                    disableFuture={false}
                                    onChange={onDateChange}
                                    disabled={false}
                                    size="small"
                                    clearable={true}
                                    label="End Date"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <TextField
                                    color="secondary"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleSearch}
                                    value={search}
                                    placeholder="Search..."
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={1}>
                                <YellowButton fullWidth variant="contained" disabled={loading} onClick={getData}>Search</YellowButton>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1}>
                                <YellowButton fullWidth variant="contained" disabled={loading} onClick={handleClick} endIcon={downloading ? <CircularProgress color="secondary" size={20} /> : <KeyboardArrowDownIcon />}>Download</YellowButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'menuButton',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem onClick={downloadPDF}>Download Report (PDF)</MenuItem>
                                    <MenuItem onClick={downloadCSV}>Download Detailed Report (CSV)</MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <ResponsiveTable
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="architect table"
                                    sx={{ width: 'calc(100% - 1px)' }}
                                >
                                    <SortableTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        headCells={headCells}
                                        lastCells={
                                            <TableCell />
                                        }
                                    />
                                    <TableBody>
                                        {loading &&
                                            <TableRow style={{ height: 53 }}>
                                                <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="secondary" /><Typography>Pulling latest data</Typography></TableCell>
                                            </TableRow>
                                        }
                                        {!loading && results && results.map((row: ReportAppointmentListViewModel) => {
                                            return (<ReportAppointmentTableRow key={row.id} row={row} onSelect={select} />);
                                        })}
                                    </TableBody>
                                </ResponsiveTable>
                                {count <= 0 && !loading &&
                                    <Grid container justifyContent="center" alignItems="flex-end">
                                        <Grid item>
                                            <Typography variant="h5">No appointments found</Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </TableContainer>
                        </Grid>
                        <Grid item>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </ColourPaper>
            <AppointmentRowDialog row={selectedRow} open={openViewAppointment} onClose={toggleViewAppointment} />
        </div>
    );
}