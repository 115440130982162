import * as React from 'react';
import Grid from '@mui/material/Grid';
import ColourPaper from '../Utilities/ColourPaper';
import authService from '../api-authorization/AuthorizeService';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import DataViewModel from '../ViewModels/Reports/DataViewModel';
import { Doughnut } from 'react-chartjs-2';
import { UtilityMethods } from '../Utilities/UtilityMethods';
import { interpolateWarm } from 'd3-scale-chromatic';
import 'chart.js/auto';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        test: {
            width: "80vw",
            height: "80vh",
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",

            margin: "auto"
        },
        text: {
            textAlign: "center"
        },
        header1: {
            fontSize: "6em"
        },
        header2: {
            fontSize: "3em"
        }
    }),
);

export default function BigCampaignTarget() {
    const classes = useStyles();
    const [amount, setAmount] = React.useState(0);
    const [organisations, setOrganisations] = React.useState(0);
    const [clients, setClients] = React.useState(0);
    const [slotData, setSlotData] = React.useState<DataViewModel[]>([]);

    let intervalId: NodeJS.Timer;
    React.useEffect(() => {
        getData();
        if (!intervalId) {
            intervalId = setInterval(() => {
                getData();
            }, 15000);
        }
        return () => clearInterval(intervalId);
    }, [])

    const getData = async () => {
        const token = await authService.getAccessToken();

        fetch(`Reports/GetBigCampaignTotal`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                if (data) {
                    setAmount(data.totalRaised);
                    setClients(data.clients);
                    setOrganisations(data.orgs);
                    setSlotData(data.slotData);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
            });
    }

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Timeslot Statuses'
            },
        }
    };

    const chartData = {
        labels: slotData.map(item => (item.x)),
        datasets: [{
            data: slotData.map(item => (item.y)),
            backgroundColor: UtilityMethods.interpolateColours(slotData.length, interpolateWarm, 0)
        }]
    };

    return (
        <Grid container spacing={1} justifyContent="center" alignItems="center" className={classes.test}>
            <Grid item xs={12} md={12}>
                <ColourPaper>
                    <Grid container className={classes.text}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <Doughnut
                                        data={chartData}
                                        options={options}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.header1}><b>€{amount.toFixed(2)}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.header2}>Total Raised</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">Organisations Made: {organisations}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">Clients Made: {clients}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ColourPaper>
            </Grid>
        </Grid>
    );
}