import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SearchButton from '../../Utilities/SearchButton';
import InputAdornment from '@mui/material/InputAdornment';
import YellowButton from '../../Utilities/YellowButton';
import Collapse from '@mui/material/Collapse';
import ColourPaper from '../../Utilities/ColourPaper';
import { SystemAdministratorListViewModel } from '../../ViewModels/User/UserListViewModel';
import SortableTableHead, { Order, HeadCell } from '../../Utilities/SortableTableHead';
import authService from '../../api-authorization/AuthorizeService';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import SystemAdminsTableRow from './SystemAdminsTableRow';
import CircularProgress from '@mui/material/CircularProgress';
import CreateSysAdminDialog from './CreateSysAdminDialog';
import EditSysAdminDialog from './EditSysAdminDialog';
import ArrowUpwardIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownwardIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Bugsnag from '@bugsnag/js';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
        table: {
            minWidth: 500,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        exportButton: {
            alignItems: 'flex-end',
            justifyContentContent: 'center'
        },
    }),
);

const headCells: HeadCell<SystemAdministratorListViewModel>[] = [
    { id: 'name', property: 'Name', align: "center", disablePadding: false, label: 'Name' },
    { id: 'email', property: 'Email', align: "center", disablePadding: false, label: 'Email' },
];

export default function SystemAdminsTable() {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof SystemAdministratorListViewModel>('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [results, setResults] = React.useState<SystemAdministratorListViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [editId, setEditId] = React.useState('');
    const [collapsed, setCollapsed] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {
        setPage(0);
    }, [search])

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        fetch(`User/GetSysAdminUsersList?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                if (data) {
                    setResults(data.rows);
                    setCount(data.rowCount);
                }
                setLoading(false);
                setCollapsed(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
                setCollapsed(false);
            });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof SystemAdministratorListViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            getData();
        }
    }

    const edit = (id: string) => {
        setEditId(id);
        toggleEditOpen();
    }

    const toggleAddOpen = () => {
        setCollapsed(false);
        setAddOpen((prev) => !prev);
    }

    const toggleEditOpen = () => {
        setCollapsed(false);
        setEditOpen((prev) => !prev);
    }

    const closeOpen = (refresh: boolean) => {
        if (refresh) getData();
        toggleAddOpen();
    }

    const closeEdit = (refresh: boolean) => {
        if (refresh) getData();
        toggleEditOpen();
    }

    const toggleCollapsed = () => {
        setCollapsed((prev) => !prev);
    }

    return (
        <React.Fragment>
            <ColourPaper>
                {loading &&
                    <React.Fragment>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <CircularProgress color="primary" />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Typography>Pulling latest data</Typography>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
                <Collapse in={!loading}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h5">Admin Management{collapsed ? "..." : ""}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                onChange={handleSearch}
                                                value={search}
                                                placeholder="Search..."
                                                onKeyDown={keyPress}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SearchButton aria-label={"Search Architects"} onClick={getData} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <YellowButton variant="contained" onClick={toggleAddOpen}>Add Admin</YellowButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton color="primary" aria-label="collapse" component="span" onClick={toggleCollapsed}>
                                                {collapsed ?
                                                    <Tooltip title="Expand Table">
                                                        <ArrowDownwardIcon />
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="Collapse Table">
                                                        <ArrowUpwardIcon />
                                                    </Tooltip>
                                                }
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={!collapsed}>
                                <TableContainer>
                                    <ResponsiveTable
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        aria-label="sys admin table"
                                    >
                                        <SortableTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            headCells={headCells}
                                            lastCells={
                                                <TableCell colSpan={2} />
                                            }
                                        />
                                        <TableBody>
                                            {!loading && results && results.map((row: SystemAdministratorListViewModel) => {
                                                return (<SystemAdminsTableRow key={row.id} row={row} edit={edit} refresh={getData} />);
                                            })}
                                        </TableBody>
                                    </ResponsiveTable>
                                    {count <= 0 && !loading &&
                                        <Grid container justifyContent="center" alignItems="flex-end">
                                            <Grid item>
                                                <Typography variant="h5">No admins found</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </TableContainer>
                            </Collapse>
                            <Collapse in={!collapsed}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Collapse>
            </ColourPaper>
            <CreateSysAdminDialog open={addOpen} onClose={closeOpen} />
            <EditSysAdminDialog open={editOpen} editId={editId} onClose={closeEdit} />
        </React.Fragment>
    );
}