import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import AppointmentListViewModel from '../ViewModels/Booking/AppointmentListViewModel';
import AppointmentDetails from './AppointmentDetails';
import BillingDetails from './BillingDetails';
import PaymentDetails from './PaymentDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import ColourPaper from '../Utilities/ColourPaper';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import LinearProgress from '@mui/material/LinearProgress';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { AlertContext } from '../Contexts/AlertContext';
import Bugsnag from '@bugsnag/js';
const moment = require('moment');

interface LocationState {
    timeslotId: number;
    clientId?: string;
    aId?: number;
}

interface IRouteProps {
    id?: string;
    routeTimeSlotId?: string;
}

const labels = [
    'Appointment Details',
    'Billing Details',
    'Payment Details'
];

export default function BookingDialog() {
    const theme = useTheme();
    const history = useHistory();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { show } = React.useContext(AlertContext);
    const location = useLocation<LocationState>();
    const { timeslotId, clientId, aId } = location.state || {
        timeslotId: 0,
        clientId: undefined,
        aId: undefined
    };
    const { id, routeTimeSlotId } = useParams<IRouteProps>();
    const [activeStep, setActiveStep] = React.useState(0);
    const [appointmentId, setAppointId] = React.useState(aId ? aId : 0);
    const [baseAmount, setBaseAmount] = React.useState(0);
    const [donationAmount, setDonationAmount] = React.useState(0);
    const [timeslot, setTimeslot] = React.useState(new AppointmentListViewModel());
    const [loading, setLoading] = React.useState(true);
    const [timer, setTimer] = React.useState(0);
    const [formattedTime, setFormattedTime] = React.useState('Calculating..');
    const [intervalId, setIntervalId] = React.useState<number>(0);

    React.useEffect(() => {
        getBaseAmount();
        lockSlot(timeslotId);
        if (id) {
            setActiveStep(1);
            setAppointId(parseInt(id));
        }
    }, []);

    React.useEffect(() => {
        if (id) {
            setActiveStep(1);
            setAppointId(parseInt(id));
        }
    }, [id]);

    React.useEffect(() => {
        if (!id) {
            setActiveStep(0);
        }

        if (timeslotId && timeslotId > 0) {
            getTimeslot();
        }
        else if (routeTimeSlotId && parseInt(routeTimeSlotId) > 0) {
            getTimeslot();
        }
        else {
            setLoading(false);
        }
    }, [timeslotId, routeTimeSlotId]);

    const getBaseAmount = async () => {
        const token = await authService.getAccessToken();

        fetch(`Appointment/GetCurrentBaseDonation`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                setBaseAmount(data);
            })
            .catch((error) => {
                Bugsnag.notify(error);
            });
    }

    const getTimeslot = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Appointment/GetTimeslot?id=${timeslotId && timeslotId > 0 ? timeslotId : routeTimeSlotId ? parseInt(routeTimeSlotId) : 0}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                setTimeslot(data);
                setLoading(false);
            })
            .catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const goToBilling = (appointId: number) => {
        setAppointId(appointId);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const next = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const updateDonation = (amount: number) => {
        setDonationAmount(amount);
    }

    const updateBaseAmount = (amount: number) => {
        setBaseAmount(amount);
    }

    const unlockSlot = async () => {
        const token = await authService.getAccessToken();

        fetch(`Appointment/OpenTimeSlot?id=${timeslotId && timeslotId > 0 ? timeslotId : routeTimeSlotId ? parseInt(routeTimeSlotId) : 0}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    show('error', data);
                }

                clearInterval(intervalId);
                if (clientId) {
                    history.goBack();
                } else {
                    if (timer <= 0) history.push('/Book/true');
                    else history.push('/');
                }
            }).catch((error) => {
                Bugsnag.notify(error);
            });
    }

    const lockSlot = async (id: number) => {
        const token = await authService.getAccessToken();

        fetch(`Appointment/CheckTimeslotLocked?id=${timeslotId && timeslotId > 0 ? timeslotId : routeTimeSlotId ? parseInt(routeTimeSlotId) : 0}&${clientId ? `clientId=${clientId}` : "" }`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data1 => {
                if (data1 === false) {
                    fetch(`Appointment/LockTimeSlot?id=${timeslotId && timeslotId > 0 ? timeslotId : routeTimeSlotId ? parseInt(routeTimeSlotId) : 0}&${clientId ? `clientId=${clientId}` : "" }`, {
                        method: 'POST',
                        headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                    })
                        .then(response => response.text())
                        .then(data2 => {
                            if (data2.length > 0) {
                                show('error', data2);
                                unlockSlot();
                            }
                            else {
                                var unlockAt = moment().add(15, 'm').toDate();
                                if (intervalId === 0) {
                                    let interval = window.setInterval(() => {
                                        let d = unlockAt.valueOf() - new Date().valueOf();
                                        if (d <= 0) {
                                            clearInterval(interval);
                                            unlockSlot();
                                        }
                                        else {
                                            setTimer(d);
                                            setFormattedTime(moment(d).format('mm') + "mins " + moment(d).format('ss') + "secs");
                                        }
                                    }, 1000);
                                    setIntervalId(interval);
                                }
                            }
                        }).catch((error) => {
                            Bugsnag.notify(error);
                        });
                }
                else if (data1 === true) {
                    show('error', "In the time since this appointment was presented to you, another client has commenced a booking for it. It has been reserved for them for 15 minutes. Please choose another appointment.");
                    history.goBack();
                }
                else {
                    let mData = moment(data1);
                    if (intervalId === 0) {
                        let interval = window.setInterval(() => {
                            let d = mData.valueOf() - new Date().valueOf();
                            if (d <= 0) {
                                clearInterval(interval);
                                unlockSlot();
                            }
                            else {
                                setTimer(d);
                                setFormattedTime(moment(d).format('mm') + "mins " + moment(d).format('ss') + "secs");
                            }
                        }, 1000);
                        setIntervalId(interval);
                    }
                }
            })
            .catch((error) => {
                Bugsnag.notify(error);
                unlockSlot();
            });
    }

    return (
        <Stack spacing={1} direction="column">
            {loading && <LinearProgress color="primary" />}
            <Alert severity="info">The slot has been reserved for you for: {formattedTime}. After this timer runs out you will be returned to the booking screen.</Alert>
            {clientId ?
                <Collapse in={!loading}>
                    <Stack spacing={1} direction="column">
                        <Alert severity="warning">You are currently booking an appointment for a client! They will not be charged for this</Alert>
                        <ColourPaper>
                            <AppointmentDetails clientId={clientId} timeslot={timeslot} next={goToBilling} close={unlockSlot} donation={donationAmount} updateDonation={updateDonation} updateBaseAmount={updateBaseAmount} baseAmount={baseAmount} appointmentId={appointmentId} />
                        </ColourPaper>
                    </Stack>
                </Collapse>
                :
                <ColourPaper>
                    <Collapse in={!loading}>
                        {smallScreen ?
                            <Typography variant="h6" gutterBottom>{labels[activeStep]}</Typography>
                            :
                            <Stepper activeStep={activeStep} sx={{ marginBottom: 2 }}>
                                <Step>
                                    <StepLabel>{labels[0]}</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>{labels[1]}</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>{labels[2]}</StepLabel>
                                </Step>
                            </Stepper>
                        }
                        {activeStep === 0 &&
                            <AppointmentDetails timeslot={timeslot} next={goToBilling} close={unlockSlot} donation={donationAmount} updateDonation={updateDonation} updateBaseAmount={updateBaseAmount} baseAmount={baseAmount} appointmentId={appointmentId} />
                        }
                        {activeStep === 1 &&
                            <BillingDetails appointmentId={appointmentId} next={next} close={unlockSlot} donation={donationAmount} paymentErrorId={id} back={() => setActiveStep(0)} />
                        }
                        {activeStep === 2 &&
                            <PaymentDetails appointmentId={appointmentId} close={unlockSlot} back={()=> setActiveStep(0)} />
                        }
                    </Collapse>
                </ColourPaper>
            }
        </Stack>
    );
}