import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ReportDonationListViewModel from '../ViewModels/Reports/ReportDonationListViewModel';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

interface IProps {
    row: ReportDonationListViewModel;
}

export default function ReportDonationTableRow(props: IProps) {
    const { row } = props;

    return (
        <TableRow>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.orderId}</TableCell>
            <TableCell>{row.transactionId}</TableCell>
            <TableCell>{row.date && moment(row.date).format("D/M/YYYY")}</TableCell>
            <TableCell>{row.refunded ? <DoneIcon color="success" /> : <CloseIcon color="error" />}</TableCell>
            <TableCell>€{row.amount.toFixed(2)}</TableCell>
        </TableRow>
    );
}