import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import ColourPaper from '../Utilities/ColourPaper';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import DatePicker from '../Utilities/DatePicker';
import YellowButton from '../Utilities/YellowButton';
import TablePagination from '@mui/material/TablePagination';
import Bugsnag from '@bugsnag/js';
import ExceptionsReportViewModel from '../ViewModels/Reports/ExceptionsReportViewModel';
import ReportExceptionsTableRow from './ReportExceptionsTableRow';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        exportButton: {
            alignItems: 'flex-end',
            justifyContentContent: 'center'
        },
    }),
);

const headCells: HeadCell<ExceptionsReportViewModel>[] = [
    { id: 'orderId', property: 'OrderId', disablePadding: false, label: 'Order ID' },
    { id: 'slotDate', property: 'SlotDate', disablePadding: false, label: 'Slot Date' },
    { id: 'isTimeslotArchived', property: 'IsTimeslotArchived', disablePadding: false, label: 'Timeslot Archived' },
    { id: 'timeSlotStatus', property: 'TimeSlotStatus', disablePadding: false, label: 'Timeslot Status' },
    { id: 'isAppointmentArchived', property: 'IsAppointmentArchived', disablePadding: false, label: 'Appointment Archived' },
    { id: 'outcome', property: 'Outcome', disablePadding: false, label: 'Appointment Outcome' },
    { id: 'confirmRefunded', property: 'ConfirmRefunded', disablePadding: false, label: 'Appointment Refunded' },
    { id: 'purchaseStarted', property: 'PurchaseStarted', disablePadding: false, label: 'Purchase Start' },
    { id: 'purchaseCompleted', property: 'PurchaseCompleted', disablePadding: false, label: 'Purchase Complete' },
    { id: 'responseMessage', property: 'ResponseMessage', disablePadding: false, label: 'Response Message' },
    { id: 'amount', property: 'Amount', disablePadding: false, label: 'Response Amount' },
    { id: 'realexTransationId', property: 'RealexTransationId', disablePadding: false, label: 'Realex Transation Id' },
    { id: 'purchaseStatus', property: 'PurchaseStatus', disablePadding: false, label: 'Purchase Status' },
    { id: 'name', property: 'Name', disablePadding: false, label: 'Purchaser Name' },
    { id: 'phoneNumber', property: 'PhoneNumber', disablePadding: false, label: 'Purchaser Phone Number' },
    { id: 'email', property: 'Email', disablePadding: false, label: 'Purchaser Email' },
    { id: 'lockedBy', property: 'LockedBy', disablePadding: false, label: 'Timeslot Locked By' },
    { id: 'architectsName', property: 'ArchitectsName', disablePadding: false, label: 'Architects Name' },
];


export default function ReportExceptionsTable() {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof ExceptionsReportViewModel>('orderId');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [search, setSearch] = React.useState('');
    const [results, setResults] = React.useState<ExceptionsReportViewModel[]>([]);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);
    const [filterStartDate, setFilterStartDate] = React.useState<Date | null>(null);
    const [filterEndDate, setFilterEndDate] = React.useState<Date | null>(null);
    const [doneInitialSearch, setDoneInitialSearch] = React.useState(false);

    React.useEffect(() => {
        getCampaignDates();
    }, []);

    React.useEffect(() => {
        if (filterStartDate && filterEndDate && !doneInitialSearch) {
            getData();
            setDoneInitialSearch(true);
        }
    }, [filterStartDate, filterEndDate, doneInitialSearch]);

    React.useEffect(() => {
        if (doneInitialSearch)
            getData();
    }, [order, orderBy, page, rowsPerPage]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ExceptionsReportViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const onDateChange = (newValue: Date | null, name: string) => {
        switch (name) {
            case "filterStartDate":
                setFilterStartDate(newValue);
                break;
            case "filterEndDate":
                setFilterEndDate(newValue);
                break;
        }
    }

    const getCampaignDates = async () => {
        const token = await authService.getAccessToken();

        fetch(`Campaign/GetCampaignDates`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setFilterStartDate(new Date(data.campaignStartDate));
                    setFilterEndDate(new Date(data.campaignEndDate));
                }
            })
            .catch(error => {
                Bugsnag.notify(error);
            });
    }

    const getData = async () => {
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        let url = `Reports/GetReportExceptionsTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}${filterStartDate !== null ? `&filterStartDate=${filterStartDate.toISOString()}` : ""}${filterEndDate !== null ? `&filterEndDate=${filterEndDate.toISOString()}` : ""}`;

        fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setResults(data.rows);
                    setCount(data.count);
                }
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const downloadCSV = async () => {
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        setDownloading(true);

        let url = `Reports/DownloadExceptionsTableCSV?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}${filterStartDate !== null ? `&filterStartDate=${filterStartDate.toISOString()}` : ""}${filterEndDate !== null ? `&filterEndDate=${filterEndDate.toISOString()}` : ""}`;

        fetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.blob())
            .then(blob => {
                let dateTime = new Date();
                const formattedTime = dateTime.toLocaleDateString() + "_" + dateTime.toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit'
                });

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "PurchasesReport_CSV_Export_" + formattedTime + ".csv";
                document.body.appendChild(a);
                a.click();
                a.remove();
                setDownloading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setDownloading(false);
            });
    }

    return (
        <div className={classes.root}>
            <ColourPaper>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                            <Grid item xs={12} sm={6} md={2}>
                                <DatePicker
                                    date={filterStartDate}
                                    name="filterStartDate"
                                    disablePast={false}
                                    disableFuture={false}
                                    onChange={onDateChange}
                                    disabled={false}
                                    size="small"
                                    clearable={true}
                                    label="Start Date"

                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <DatePicker
                                    date={filterEndDate}
                                    name="filterEndDate"
                                    disablePast={false}
                                    disableFuture={false}
                                    onChange={onDateChange}
                                    disabled={false}
                                    size="small"
                                    clearable={true}
                                    label="End Date"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <TextField
                                    color="secondary"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleSearch}
                                    value={search}
                                    placeholder="Search..."
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={1}>
                                <YellowButton fullWidth variant="contained" disabled={loading} onClick={getData}>Search</YellowButton>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1}>
                                <YellowButton fullWidth variant="contained" disabled={loading} onClick={downloadCSV} endIcon={downloading ? <CircularProgress color="secondary" size={20} /> : undefined}>Download</YellowButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <ResponsiveTable
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="exceptions table"
                                >
                                    <SortableTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        headCells={headCells}
                                        lastCells={
                                            <TableCell />
                                        }
                                    />
                                    <TableBody>
                                        {loading &&
                                            <TableRow style={{ height: 53 }}>
                                                <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="secondary" /><Typography>Pulling latest data</Typography></TableCell>
                                            </TableRow>
                                        }
                                        {!loading && results && results.map((row: ExceptionsReportViewModel, index: number) => {
                                            return (<ReportExceptionsTableRow key={index} row={row} />);
                                        })}
                                    </TableBody>
                                </ResponsiveTable>
                                {count <= 0 && !loading &&
                                    <Grid container justifyContent="center" alignItems="flex-end" mt={1}>
                                        <Grid item>
                                            <Typography variant="h5">No exceptions found</Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </TableContainer>
                        </Grid>
                        <Grid item>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </ColourPaper>
        </div>
    );
}