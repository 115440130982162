export default class PaymentViewModel {
    public name: string = '';
    public email: string = '';
    public streetAddress1: string = '';
    public streetAddress2: string = '';
    public streetAddress3: string = '';
    public city: string = '';
    public province: string = '';
    public postalCode: string = '';
    public countryCode: string = '353';
    public phoneNumber: string = '';
    public donationAmount: number;
    public note: string = '';
    public error: string = '';

    constructor(donationAmount: number) {
        this.donationAmount = donationAmount;
    } 
}

export class AppointmentPaymentViewModel {
    public id?: number;
    public appointId: number = 0;
    public appointmentPurchaseId: number = 0;
    public email: string = '';
    public name: string = '';
    public streetAddress1: string = '';
    public streetAddress2: string = '';
    public streetAddress3: string = '';
    public city: string = '';
    public province: string = '';
    public postalCode: string = '';
    public countryCode: string = '353';
    public phoneNumber: string = '';
    public donationAmount: number = 0;
    public baseAmount: number = 0;
    public note: string = '';
    public error: string = '';

    constructor(appointId: number, donationAmount: number) {
        this.appointId = appointId;
        this.donationAmount = donationAmount;
    } 
}