export default class ArchitectViewModel {
    public id: number = 0;
    public name: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public memberId: string = '';
    public email: string = '';
    public conservation: boolean = false;
    public mailChimp: boolean = false;
}

export class CreateArchitectViewModel {
    public organisationId: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public memberId: string = '';
    public email: string = '';
    public conservation: boolean = false;
    public mailChimp: boolean = false;

    constructor(orgId: string) {
        this.organisationId = orgId;
    }
}