import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import YellowButton from '../Utilities/YellowButton';
import Button from '@mui/material/Button';
import authService from '../api-authorization/AuthorizeService';
import DatePicker from '../Utilities/DatePicker';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import CreateCampaignSettingsViewModel from '../ViewModels/Campaign/CreateCampaignSettingsViewModel';
import { AlertContext } from '../Contexts/AlertContext';
import ColourPaper from '../Utilities/ColourPaper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        yesButton: {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.main
            },
            borderRadius: 0
        },
        noButton: {
            color: '#fff',
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.dark
            },
            borderRadius: 0
        }
    }));

export default function CampaignSettings() {
    const classes = useStyles();
    const { show } = React.useContext(AlertContext);
    const [campaignSettings, setCampaignSettings] = React.useState<CreateCampaignSettingsViewModel>(new CreateCampaignSettingsViewModel());
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {

        getData();
    }, [])

    const getData = async () => {

        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Campaign/GetCurrentCampaignSettings`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if(data) {
                    setCampaignSettings(data);
                }
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const onDateChange = (newValue: Date | null, name: string) => {
        setCampaignSettings({
            ...campaignSettings,
            [name]: newValue
        });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
        setCampaignSettings({
            ...campaignSettings,
            [name]: event.target.value
        });
    }

    const toggleCampaignActive = () => {
        setCampaignSettings({
            ...campaignSettings,
            active: !campaignSettings.active
        });
    }

    const toggleBookSlots = () => {
        setCampaignSettings({
            ...campaignSettings,
            canBookSlots: !campaignSettings.canBookSlots
        });
    }

    const toggleCreateSlots = () => {
        setCampaignSettings({
            ...campaignSettings,
            canCreateSlots: !campaignSettings.canCreateSlots
        });
    }

    const save = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Campaign/AddCampaignSettings`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(campaignSettings)
        })
            .then(response => response.text())
            .then(data => {
                setLoading(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    show('success', 'Successfully set campaign settings');
                    getData();
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    return (
        <ColourPaper>
            {loading &&
                <React.Fragment>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <CircularProgress color="primary" />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Typography>Pulling latest data</Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            <Collapse in={!loading}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Campaign Management</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button style={{ marginTop: '15px' }} fullWidth className={campaignSettings.active ? classes.yesButton : classes.noButton} variant="contained" disabled={loading} onClick={toggleCampaignActive}>Campaign Active: {campaignSettings.active ? "Yes" : "No"}</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="campaignStartDate">Start Date</InputLabel>
                                <DatePicker
                                    date={campaignSettings.campaignStartDate}
                                    name="campaignStartDate"
                                    disablePast={true}
                                    disableFuture={false}
                                    onChange={onDateChange}
                                    disabled={loading || !campaignSettings.active}
                                    size="small"
                                    clearable={false}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="campaignEndDate">End Date</InputLabel>
                                <DatePicker
                                    date={campaignSettings.campaignEndDate}
                                    name="campaignEndDate"
                                    disablePast={true}
                                    disableFuture={false}
                                    onChange={onDateChange}
                                    disabled={loading || !campaignSettings.active}
                                    size="small"
                                    clearable={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button fullWidth className={campaignSettings.canBookSlots ? classes.yesButton : classes.noButton} variant="contained" disabled={loading || !campaignSettings.active} onClick={toggleBookSlots}>Book Slots: {campaignSettings.canBookSlots ? "Yes" : "No"}</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth className={campaignSettings.canCreateSlots ? classes.yesButton : classes.noButton} variant="contained" disabled={loading || !campaignSettings.active} onClick={toggleCreateSlots}>Create Slots: {campaignSettings.canCreateSlots ? "Yes" : "No"}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="baseAmount">Base Price</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    id="baseAmount"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                                    }}
                                    value={campaignSettings.baseAmount}
                                    disabled={loading || !campaignSettings.active}
                                    onChange={(event) => onChange(event, 'baseAmount')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="cancellationAmount">Cancellation Fee</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    id="cancellationAmount"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                                    }}
                                    value={campaignSettings.cancellationAmount}
                                    disabled={loading || !campaignSettings.active}
                                    onChange={(event) => onChange(event, 'cancellationAmount')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="target">Campaign Target</InputLabel>
                        <TextField
                            size="small"
                            fullWidth
                            type="number"
                            id="target"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>
                            }}
                            value={campaignSettings.target}
                            disabled={loading || !campaignSettings.active}
                            onChange={(event) => onChange(event, 'target')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <YellowButton fullWidth variant="contained" disabled={loading} onClick={save}>Save Changes</YellowButton>
                    </Grid>
                </Grid>
            </Collapse>
        </ColourPaper>
    );
}