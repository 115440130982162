import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

interface IProps {
    online: boolean;
    inperson: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
}

export default function MeetingPreferenceValidator(props: IProps) {
    const { online, inperson, handleChange, disabled } = props;

    return (
        <FormControl
            required
            error={!online && !inperson}
            component="fieldset"
            variant="standard"
            disabled={disabled}
        >
            <FormLabel component="legend">Meeting Preference</FormLabel>
            <Typography variant="caption">You can choose one or both.</Typography>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox checked={online} onChange={handleChange} name="online" />
                    }
                    label="Online"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={inperson} onChange={handleChange} name="inPerson" />
                    }
                    label="In Person"
                />
            </FormGroup>
            <FormHelperText>{!online && !inperson && 'You must choose at least one'}</FormHelperText>
        </FormControl>
    );
}