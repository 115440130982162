import Typography from '@mui/material/Typography';
import { Redirect } from 'react-router-dom';
import * as React from 'react';

interface IProps {
}

export default function Success(props: IProps) {
    return (
        <div>
            <Typography variant="h4">Payment Success</Typography>
            <Redirect to={{
                pathname: '/',
            }} />
        </div>
    );
}
