import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import SquareButton from '../Utilities/SquareButton';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import InputLabel from '@mui/material/InputLabel';
import ChangePasswordViewModel from '../ViewModels/Password/ChangePasswordViewModel';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import YellowButton from './YellowButton';
import ColourPaper from '../Utilities/ColourPaper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Bugsnag from '@bugsnag/js';

interface IProps {
    passwordAnchorEl: HTMLElement | null;
    closeDialog: () => void;
    loaded: boolean;
}

export default function PasswordChangeDialog(props : IProps) {
    const { passwordAnchorEl, closeDialog, loaded } = props;
    const [saving, setSaving] = React.useState(false);
    const [password, setPassword] = React.useState(new ChangePasswordViewModel());
    const [error, setError] = React.useState('');
    const isPasswordDialogOpen = Boolean(passwordAnchorEl);
    const [showCurrentPass, setShowCurrentPass] = React.useState(false);
    const [showPass, setShowPass] = React.useState(false);
    const [showConfirmPass, setShowConfirmPass] = React.useState(false);

    ValidatorForm.addValidationRule('validPassword', (value) => {
        if (/^(?=.{6,})(?=.*[\W])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/.test(value)) {
            return true;
        } return false;
    });

    ValidatorForm.addValidationRule('passwordMatch', (value) => {
        if (value === password.newPassword) {
            return true;
        } return false;
    });

    const onClose = () => {
        setPassword(new ChangePasswordViewModel());
        setError('');
        closeDialog();
    }

    const changePassword = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch('User/ChangePassword', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(password)
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    setError(data);
                } else {
                    closeDialog();
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
                setError("Unexpected error occurred, please try again.");
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword({
            ...password,
            [event.target.name]: event.target.value
        });
    }

    return (
        <Dialog
            fullWidth
            open={isPasswordDialogOpen}
            onClose={closeDialog}
            aria-labelledby="change-password-title"
            PaperComponent={ColourPaper}
        >
            <ValidatorForm onSubmit={changePassword}>
                <DialogTitle id="change-password-title">Change Password</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="currentPassword" shrink>Current Password</InputLabel>
                            <TextValidator
                                id="currentPassword"
                                name="currentPassword"
                                variant="outlined"
                                fullWidth
                                type={showCurrentPass ? "text" : "password"}
                                value={password.currentPassword}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: <Button size="small" sx={{padding: 0, margin: 0}} onClick={() => setShowCurrentPass((prev) => !prev)}>{showCurrentPass ? <VisibilityIcon /> : <VisibilityOffIcon />}</Button>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="newPassword" shrink>New Password</InputLabel>
                            <TextValidator
                                id="newPassword"
                                name="newPassword"
                                variant="outlined"
                                fullWidth
                                type={showPass ? "text" : "password"}
                                value={password.newPassword}
                                validators={['required', 'validPassword']}
                                errorMessages={['This field is required', 'Must be 6 characters long and contain 1 digit, 1 uppercase letter, 1 lowercase letter and 1 special character']}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: <Button size="small" sx={{ padding: 0, margin: 0 }} onClick={() => setShowPass((prev) => !prev)}>{showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}</Button>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="repeatPassword" shrink>Repeat Password</InputLabel>
                            <TextValidator
                                id="repeatPassword"
                                name="repeatPassword"
                                variant="outlined"
                                type={showConfirmPass ? "text" : "password"}
                                fullWidth
                                value={password.repeatPassword}
                                validators={['required', 'passwordMatch']}
                                errorMessages={['This field is required', 'Passwords do not match']}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: <Button size="small" sx={{ padding: 0, margin: 0 }} onClick={() => setShowConfirmPass((prev) => !prev)}>{showConfirmPass ? <VisibilityIcon /> : <VisibilityOffIcon />}</Button>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Typography color="error">{error}</Typography>
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={onClose} variant="outlined" color="error" disabled={saving}>Cancel</SquareButton>
                    <YellowButton type="submit" variant="contained" disabled={saving || !loaded}>Change</YellowButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}