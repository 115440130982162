import * as React from 'react';
import BookingContainer from '../Booking/BookingContainer';
import AppointmentViewModel from '../ViewModels/Appointment/AppointmentViewModel';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import ColourPaper from '../Utilities/ColourPaper';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import WarningDialog from '../Utilities/WarningDialog';
import YellowButton from '../Utilities/YellowButton';
import { AlertContext } from '../Contexts/AlertContext';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Bugsnag from '@bugsnag/js';
const moment = require('moment');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subtitles: {
            textAlign: "center",
            backgroundColor: `${theme.palette.success.main}`,
            color: "white",
            padding: "8px"
        },
        button: {
            borderRadius: 0
        }
    }),
);

interface IProps {
    id?: string;
}

export default function ClientDashboard() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation<IProps>();
    const { id } = location.state || {
        id: undefined
    }
    const { show } = React.useContext(AlertContext);
    const [appointment, setAppointment] = React.useState<AppointmentViewModel | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
    const [openSecondWarningDialog, setOpenSecondWarningDialog] = React.useState(false);
    const [cancelling, setCancelling] = React.useState(false);
    const [refunding, setRefunding] = React.useState(false);
    const [name, setName] = React.useState('');
    const [meetingValue, setMeetingValue] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [updatingDescription, setUpdatingDescription] = React.useState(false);
    const [updatingLocation, setUpdatingLocation] = React.useState(false);
    const [updating, setUpdating] = React.useState(false);
    const [lockedTimeslot, setLockedTimeslot] = React.useState(0);
    const [lockedAppointment, setLockedAppointment] = React.useState(0);
    const [timer, setTimer] = React.useState(0);
    const [formattedTime, setFormattedTime] = React.useState('Calculating..');
    const [intervalId, setIntervalId] = React.useState<number>(0);

    React.useEffect(() => {
        getData();
        return () => clearInterval(intervalId);
    }, []);

    React.useEffect(() => {
        if (appointment === null || appointment.id === 0) checkLockedTimeslot();
        return () => clearInterval(intervalId);
    }, [appointment])

    const checkLockedTimeslot = async () => {
        const token = await authService.getAccessToken();

        fetch(`Appointment/CheckUserHasLockedTimeslot?${id !== undefined ? `clientId=${id}` : ""}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                if (data) {
                    setLockedTimeslot(data.timeslotId);
                    setLockedAppointment(data.appointmentId);
                    let mData = moment(data.unlockAt);
                    if (intervalId === 0) {
                        let interval = window.setInterval(() => {
                            let d = mData.valueOf() - new Date().valueOf();
                            if (d <= 0) {
                                clearInterval(interval);
                                unlockSlot();
                            }
                            else {
                                setTimer(d);
                                setFormattedTime(moment(d).format('mm') + "mins " + moment(d).format('ss') + "secs");
                            }
                        }, 1000);
                        setIntervalId(interval);
                    }
                }
            }).catch((error) => {
                Bugsnag.notify(error);
            });
    }

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        if (user && token) {
            fetch(`Appointment/GetClientAppointment?id=${id !== undefined ? id : user.sub}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.json())
                .then((data) => {
                    if (data && data !== false) {
                        setAppointment(data);
                        setMeetingValue(data.isOnlineMeeting ? 'online' : 'inPerson');
                        setDescription(data.projectDescription);
                    }
                    setLoading(false);
                }).catch((error) => {
                    Bugsnag.notify(error);
                    setLoading(false);
                });
        }

        if (id && token) {
            fetch(`Client/GetClientName?id=${id}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.json())
                .then((data) => {
                    if (data) {
                        setName(data);
                    }
                }).catch((error) => {
                    Bugsnag.notify(error);
                });
        }
    }

    const toggleWarningDialog = () => {
        setOpenWarningDialog((prev) => !prev);
    }

    const toggleSecondWarningDialog = () => {
        setOpenSecondWarningDialog((prev) => !prev);
    }

    const secondWarning = () => {
        toggleWarningDialog();
        toggleSecondWarningDialog();
    }

    const cancelAppointment = async () => {
        if (appointment !== null) {
            const token = await authService.getAccessToken();
            toggleSecondWarningDialog();
            setCancelling(true);

            fetch(`Appointment/ClientCancel?id=${appointment.id}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
            })
                .then(response => response.json())
                .then(data => {
                    setCancelling(false);
                    setAppointment(null);

                    if (data.length > 0) {
                        show('error', data);
                    } else {
                        show('success', 'Appointment cancelled.');
                        getData();
                    }

                    history.push('/');
                }).catch((error) => {
                    Bugsnag.notify(error);
                    setCancelling(false);
                });
        }
    }

    const clientFullRefund = async () => {
        if (appointment) {
            const token = await authService.getAccessToken();
            setRefunding(true);

            fetch(`Appointment/ClientFullRefund?id=${appointment.id}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
            })
                .then(response => response.json())
                .then(data => {
                    setRefunding(false);
                    setAppointment(null);

                    if (data.length > 0) {
                        show('error', data);
                    } else {
                        show('success', 'Refund request sent.');
                    }

                    getData();
                    if (id) history.push('/');
                }).catch((error) => {
                    Bugsnag.notify(error);
                    setRefunding(false);
                });
        }
    }

    const onMeetingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMeetingValue((event.target as HTMLInputElement).value);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    }

    const updateDescription = async () => {
        const token = await authService.getAccessToken();
        setUpdating(true);

        if (appointment) {
            fetch(`Appointment/UpdateDescription?id=${appointment.id}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify(description)
            })
                .then(response => {
                    return response.text()
                })
                .then(data => {
                    setUpdating(false);

                    if (data.length > 0) {
                        show('error', data);
                    } else {
                        show('success', 'Updated successfully.');
                        getData();
                    }

                    toggleUpdatingDescription();

                    //if (id) history.push('/');
                }).catch((error) => {
                    Bugsnag.notify(error);
                    setUpdating(false);
                });
        }
    }

    const updateLocation = async () => {
        const token = await authService.getAccessToken();
        setUpdating(true);

        if (appointment) {
            fetch(`Appointment/UpdateLocation?id=${appointment.id}&online=${meetingValue === 'online' ? true : false}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.json())
                .then(data => {
                    setUpdating(false);

                    if (data.length > 0) {
                        show('error', data);
                    } else {
                        show('success', 'Updated successfully.');
                    }

                    toggleUpdatingLocation();
                    getData();
                    if (id) history.push('/');
                }).catch((error) => {
                    Bugsnag.notify(error);
                    setUpdating(false);
                });
        }
    }

    const rescheduleLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={{
            pathname: `/Book`,
            state: {
                oldAppointmentId: appointment !== null ? appointment.id : 0,
                id: id
            }
        }} ref={ref} {...itemProps} />
    ));

    const toggleUpdatingDescription = () => {
        setUpdatingDescription(!updatingDescription);
    }

    const toggleUpdatingLocation = () => {
        setUpdatingLocation((prev) => !prev);
    }

    const cancelUpdateDescription = () => {
        if (appointment) setDescription(appointment.projectDescription);
        setUpdatingDescription(false);
    }

    const cancelUpdateLocation = () => {
        if (appointment) setMeetingValue(appointment.isOnlineMeeting ? 'online' : 'inPerson');
        setUpdatingLocation(false);
    }

    const unlockSlot = async () => {
        if (lockedTimeslot > 0) {
            const token = await authService.getAccessToken();

            fetch(`Appointment/OpenTimeSlot?id=${lockedTimeslot}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        show('error', data);
                    }
                    clearInterval(intervalId);
                    setTimer(-1);
                    setLockedTimeslot(0);
                }).catch((error) => {
                    Bugsnag.notify(error);
                });
        }
    }

    const goToBooking = async () => {
        if (lockedTimeslot > 0) {
            history.push({
                pathname: '/Book/Create',
                state: {
                    timeslotId: lockedTimeslot,
                    clientId: id,
                    aId: lockedAppointment
                }
            });
        }
    }

    return (
        <React.Fragment>
            {loading ?
                <LinearProgress color="primary" />
                :
                <Stack spacing={2}>
                    {id && <Stack spacing={1} direction="row"><Button onClick={history.goBack} startIcon={<ArrowBackIcon />}>Back</Button><Typography variant="h4">Currently viewing: {name}</Typography></Stack>}
                    {appointment === null || appointment.id === 0 ?
                        <React.Fragment>
                            {lockedTimeslot > 0 && timer >= 0 && <Alert severity="info"><Grid container spacing={1} alignItems="center"><Grid item>You still have a reserved timeslot from your previous booking for {formattedTime}. Select from the options for what you would like to do:</Grid><Grid item><Grid container spacing={1}><Grid item><Button size="small" onClick={unlockSlot} sx={{ padding: '0 8px' }}>Cancel</Button></Grid><Grid item><Button size="small" onClick={goToBooking} sx={{ padding: '0 8px' }}>Booking page</Button></Grid></Grid></Grid></Grid></Alert>}
                            <BookingContainer id={id} />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {(appointment.outcome === null || appointment.outcome === 0) &&
                                <React.Fragment>
                                    {appointment.status === 3 ?
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item md={6} xs={12}>
                                                <ColourPaper>
                                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography variant="h3" align="center">Attention!</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body2">Your appointment has been cancelled by the architect you booked with. Please select from the options below for the next steps.</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2} justifyContent="space-evenly" alignItems="center">
                                                                <Grid item>
                                                                    <Button onClick={clientFullRefund} disabled={(appointment.cancellationDate && new Date(appointment.cancellationDate) <= new Date()) || refunding} endIcon={refunding ? <CircularProgress size={25} /> : <React.Fragment />}>Full Refund</Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button component={rescheduleLink} variant="contained" disabled={refunding}> Reschedule Appointment</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {appointment.cancellationDate && new Date(appointment.cancellationDate) <= new Date() &&
                                                            <Grid item>
                                                                <Typography color="error"><i>You are no longer eligable for a refund</i></Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </ColourPaper>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid spacing={2} container justifyContent="center">
                                            <Grid item xs={12}>
                                                <Typography align="center" variant="h4">Appointment Details</Typography>
                                            </Grid>
                                            <Grid item md={5} xs={12}>
                                                <Stack spacing={2} justifyContent="center" direction="column">
                                                    <Stack justifyContent="center" direction="column">
                                                        <Typography className={classes.subtitles} variant="h6">Architect</Typography>
                                                        <ColourPaper>
                                                            <Stack direction="column">
                                                                <Typography variant="body1"><b>{appointment.architectName}</b></Typography>
                                                                <Typography variant="body1">{appointment.organisationName}</Typography>
                                                                {appointment.url && <Typography variant="body1"><a href={!appointment.url.includes("https://") ? "https://" + appointment.url : appointment.url} target="_blank">{appointment.url}</a></Typography>}
                                                            </Stack>
                                                        </ColourPaper>
                                                    </Stack>
                                                    <Stack justifyContent="center" direction="column">
                                                        <Typography className={classes.subtitles} variant="h6">Project</Typography>
                                                        <ColourPaper>
                                                            <Stack direction="column" spacing={2}>
                                                                <Stack direction="column">
                                                                    <Typography variant="body1"><b>Address</b></Typography>
                                                                    <Typography variant="body1">{appointment.address}</Typography>
                                                                    <Typography variant="body1">{appointment.eirCode}</Typography>
                                                                </Stack>
                                                                <Stack direction="column">
                                                                    <Typography variant="body1"><b>Details</b></Typography>
                                                                    {!updatingDescription ?
                                                                        <Stack direction="column" spacing={2}>
                                                                            <Typography variant="body1">{appointment.projectDescription}</Typography>
                                                                            <Grid container spacing={2} alignItems="center">
                                                                                <Grid item md={9} xs={12}>
                                                                                    <Typography variant="body2"><i>Update/add your project description. You can also include a link to your project images by using WeTransfer or Dropbox platform. To see what you could bring to your consultation please visit <a href='https://www.riaisimonopendoor.ie/howitworks'>‘How it Works’</a>.</i></Typography>
                                                                                </Grid>
                                                                                <Grid item md={3} xs={12}>
                                                                                    <Button size="small" className={classes.button} variant="contained" onClick={toggleUpdatingDescription} disabled={updating} endIcon={updating && <CircularProgress size={25} />}>Edit Description</Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Stack>
                                                                        :
                                                                        <Stack direction="column" spacing={2}>
                                                                            <TextField
                                                                                id="description"
                                                                                fullWidth
                                                                                multiline
                                                                                rows={6}
                                                                                value={description}
                                                                                onChange={onChange}
                                                                            />
                                                                            <Grid container spacing={1} alignItems="center">
                                                                                <Grid item md={8} xs={12}>
                                                                                    <Typography variant="body2"><i>Update/add your project description. You can also include a link to your project images by using WeTransfer or Dropbox platform. To see what you could bring to your consultation please visit <a href='https://www.riaisimonopendoor.ie/howitworks'>‘How it Works’</a>.</i></Typography>
                                                                                </Grid>
                                                                                <Grid item md={4} xs={12}>
                                                                                    <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                                                                                        <Grid item>
                                                                                            <Button size="small" className={classes.button} variant="contained" onClick={cancelUpdateDescription}>Cancel</Button>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Button size="small" className={classes.button} variant="contained" disabled={description === appointment.projectDescription || updating} onClick={updateDescription} endIcon={updating && <CircularProgress size={25} />}>Save Changes</Button>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Stack>
                                                                    }
                                                                </Stack>
                                                            </Stack>
                                                        </ColourPaper>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item md={5} xs={12}>
                                                <Stack spacing={1} justifyContent="center" direction="column">
                                                    <Stack justifyContent="center" direction="column">
                                                        <Typography className={classes.subtitles} variant="h6">Appointment</Typography>
                                                        <ColourPaper>
                                                            <Stack direction="column">
                                                                <Typography variant="body1"><b>{appointment.slotDate + ", " + appointment.slotTime}</b></Typography>
                                                                {id ?
                                                                    !updatingLocation ?
                                                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                                            <Typography variant="body1">{appointment.isOnlineMeeting ? "Meeting Online" : "Meeting In-Person"}</Typography>
                                                                            {appointment.online && appointment.inPerson &&
                                                                                <Button size="small" className={classes.button} variant="contained" onClick={toggleUpdatingLocation} disabled={updating} endIcon={updating && <CircularProgress size={25} />}>Edit Location</Button>
                                                                            }
                                                                        </Stack>
                                                                        :
                                                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                                            <Stack direction="row">
                                                                                <FormControl>
                                                                                    <RadioGroup
                                                                                        value={meetingValue}
                                                                                        onChange={onMeetingChange}
                                                                                        row
                                                                                    >
                                                                                        {appointment.online && <FormControlLabel value="online" control={<Radio />} label="Online" />}
                                                                                        {appointment.inPerson && <FormControlLabel value="inPerson" control={<Radio />} label="In Person" />}
                                                                                    </RadioGroup>
                                                                                </FormControl>
                                                                            </Stack>
                                                                            <Stack direction="row" spacing={1}>
                                                                                <Button size="small" className={classes.button} variant="contained" onClick={cancelUpdateLocation}>Cancel</Button>
                                                                                <Button size="small" className={classes.button} variant="contained" disabled={(meetingValue === 'online' && appointment.isOnlineMeeting) || (meetingValue === 'inPerson' && !appointment.isOnlineMeeting) || updating} onClick={updateLocation} endIcon={updating && <CircularProgress size={25} />}>Save Changes</Button>
                                                                            </Stack>
                                                                        </Stack>
                                                                    :
                                                                    <Typography variant="body1">{appointment.isOnlineMeeting ? "Meeting Online" : "Meeting In-Person"}</Typography>
                                                                }
                                                                {appointment.isOnlineMeeting ?
                                                                    <Typography variant="body1">{appointment.meetingLink !== "" ? appointment.meetingLink : <i>Meeting link to be provided</i>}</Typography>
                                                                    :
                                                                    <Stack direction="column" spacing={1}>
                                                                        <Stack direction="column">
                                                                            <Typography variant="body1">{appointment.addressLine1}</Typography>
                                                                            {appointment.addressLine2 !== '' &&
                                                                                <Typography variant="body1">{appointment.addressLine2}</Typography>
                                                                            }
                                                                            {appointment.addressLine3 !== '' &&
                                                                                <Typography variant="body1">{appointment.addressLine3}</Typography>
                                                                            }
                                                                            <Typography variant="body1">{appointment.city}</Typography>
                                                                            <Typography variant="body1">{appointment.province}</Typography>
                                                                            <Typography variant="body1">{appointment.postalCode}</Typography>
                                                                        </Stack>
                                                                        <Stack direction="row">
                                                                            <YellowButton variant="contained" target="_blank" href={`https://www.google.com/maps/search/${appointment.postalCode}+Ireland`}>View On Google Maps</YellowButton>
                                                                        </Stack>
                                                                    </Stack>
                                                                }
                                                            </Stack>
                                                        </ColourPaper>
                                                    </Stack>
                                                    <Stack justifyContent="flex-end" direction="row">
                                                        <Button className={classes.button} onClick={toggleWarningDialog} variant="contained" color="error" disabled={cancelling || updating} endIcon={cancelling && <CircularProgress size={25} />}>Cancel Appointment</Button>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography align="center" variant="body1"><i>For any queries with your appointment, please contact <a href='mailto:simon@riai.ie' target='_blank'>simon@riai.ie</a></i></Typography>
                                            </Grid>
                                            <WarningDialog text={<p>A refund will be processed in accordance with the <a href='https://riaisimonopendoor.ie/terms-of-use' target='_blank'>Terms of Use.</a> Do you wish to continue?</p>} buttonText="Continue with Cancellation" open={openWarningDialog} close={toggleWarningDialog} okClick={secondWarning} closeLabel="Go Back" />
                                            <WarningDialog text={"Are you absolutely sure you would like to cancel your appointment? This action is irreversible."} buttonText="Yes I'm sure" open={openSecondWarningDialog} close={toggleSecondWarningDialog} okClick={cancelAppointment} closeLabel="Go Back" />
                                        </Grid>
                                    }
                                </React.Fragment>
                            }
                            {(appointment.outcome === 1 || appointment.outcome === 2) &&
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        {appointment.outcome === 1 && <Alert severity="warning">You cancelled your last appointment. If you would like to re-book, please do so below.</Alert>}
                                        {appointment.outcome === 2 && <Alert severity="warning">Your last appointment was fully refunded after being cancelled by the architect. If you would like to re-book, please do so below.</Alert>}
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <BookingContainer />
                                    </Grid>
                                </Grid>
                            }
                        </React.Fragment>
                    }
                </Stack >
            }
        </React.Fragment >
    );
}