import { createTheme, ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
    interface Palette {
        tertiary: {
            main: React.CSSProperties['color']
        }
    }
    interface PaletteOptions {
        tertiary: {
            main: React.CSSProperties['color']
        }
    }
}

export default function CustomTheme(options: ThemeOptions) {
    return createTheme({
        palette: {
            tertiary: {
                main: '#00AEEF'
            }
        },
        ...options
    });
}