import Paper, { PaperProps } from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2)
        },
        successPaper: {
            padding: theme.spacing(2),
            borderTop: `${theme.palette.success.main} solid 5px`
        },
        failPaper: {
            padding: theme.spacing(2),
            borderTop: `${theme.palette.error.main} solid 5px`
        }
    })
);

interface IProps extends PaperProps {
    topborder?: string;
}

export default function ColourPaper(props: IProps) {
    const { topborder } = props;
    const classes = useStyles();

    return (
        <Paper
            square
            className={topborder && topborder === "success" ? classes.successPaper : topborder && topborder === "fail" ? classes.failPaper : classes.paper}
            {...props} />
    );
}