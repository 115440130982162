export default class PaymentRequestDataViewModel {

    public MERCHANT_ID:                                  string = '';
    public ACCOUNT:                                      string = '';
    public ORDER_ID:                                     string = '';
    public AMOUNT:                                       string = '';
    public CURRENCY:                                     string = '';
    public TIMESTAMP:                                    string = '';
    public AUTO_SETTLE_FLAG:                             string = '';
    public HPP_CUSTOMER_EMAIL:                           string = '';
    public HPP_CUSTOMER_PHONENUMBER_MOBILE:              string = '';
    public HPP_ADDRESS_MATCH_INDICATOR:                  string = '';
    public BILLING_CODE:                                 string = '';
    public BILLING_CO:                                   string = '';
    public HPP_BILLING_STREET1:                          string = '';
    public HPP_BILLING_STREET2:                          string = '';
    public HPP_BILLING_STREET3:                          string = '';
    public HPP_BILLING_CITY:                             string = '';
    public HPP_BILLING_POSTALCODE:                       string = '';
    public HPP_VERSION:                                  string = '';
    public SHA1HASH:                                     string = '';

}