import * as React from 'react';
import ColourPaper from '../Utilities/ColourPaper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SendEmailDialog from '../Utilities/SendEmailDialog';
import YellowButton from '../Utilities/YellowButton';
import { useHistory } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import Bugsnag from '@bugsnag/js';

export default function AdminFunctions() {
    const history = useHistory();
    const [openClientEmail, setOpenClientEmail] = React.useState(false);
    const [openArchitectEmail, setOpenArchitectEmail] = React.useState(false);
    const [exportsAnchorEl, setExportsAnchorEl] = React.useState<null | HTMLElement>(null);
    const [emailsAnchorEl, setEmailsAnchorEl] = React.useState<null | HTMLElement>(null);
    const [downloading, setDownloading] = React.useState(false);

    const handleExportsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportsAnchorEl(event.currentTarget);
    };
    const handleExportsClose = () => {
        setExportsAnchorEl(null);
    };

    const handleEmailsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEmailsAnchorEl(event.currentTarget);
    };
    const handleEmailsClose = () => {
        setEmailsAnchorEl(null);
    };

    const toggleClientEmail = () => {
        handleEmailsClose();
        setOpenClientEmail((prev) => !prev);
    }

    const toggleArchitectEmail = () => {
        handleEmailsClose();
        setOpenArchitectEmail((prev) => !prev);
    }

    const downloadClientNoAppointmentCSV = async () => {
        handleExportsClose();
        const token = await authService.getAccessToken();
        setDownloading(true);

        fetch(`Reports/DownloadClientNoAppointmentCSV`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.blob())
            .then(blob => {
                let dateTime = new Date();
                const formattedTime = dateTime.toLocaleDateString() + "_" + dateTime.toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit'
                });

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "ClientNoAppointment_CSV_Export_" + formattedTime + ".csv";
                document.body.appendChild(a);
                a.click();
                a.remove();
                setDownloading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setDownloading(false);
            });
    }

    const downloadArchitectsCSV = async () => {
        handleExportsClose();
        const token = await authService.getAccessToken();
        setDownloading(true);

        fetch(`Reports/DownloadArchitectsCSV`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.blob())
            .then(blob => {
                let dateTime = new Date();
                const formattedTime = dateTime.toLocaleDateString() + "_" + dateTime.toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit'
                });

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "Architects_CSV_Export_" + formattedTime + ".csv";
                document.body.appendChild(a);
                a.click();
                a.remove();
                setDownloading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setDownloading(false);
            });
    }

    const downloadArchitectsRegisteredCSV = async (year: number) => {
        handleExportsClose();
        const token = await authService.getAccessToken();
        setDownloading(true);

        fetch(`Reports/DownloadArchitectsRegisteredCSV?year=${year}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.blob())
            .then(blob => {
                let dateTime = new Date();
                const formattedTime = dateTime.toLocaleDateString() + "_" + dateTime.toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit'
                });

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = year + "_Architects_Registered_CSV_Export_" + formattedTime + ".csv";
                document.body.appendChild(a);
                a.click();
                a.remove();
                setDownloading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setDownloading(false);
            });
    }

    const downloadArchitectsSlotsCSV = async (year: number) => {
        handleExportsClose();
        const token = await authService.getAccessToken();
        setDownloading(true);

        fetch(`Reports/DownloadArchitectsWithSlotsCSV?year=${year}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.blob())
            .then(blob => {
                let dateTime = new Date();
                const formattedTime = dateTime.toLocaleDateString() + "_" + dateTime.toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit'
                });

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = year + "_Architects_TimeSlots_CSV_Export_" + formattedTime + ".csv";
                document.body.appendChild(a);
                a.click();
                a.remove();
                setDownloading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setDownloading(false);
            });
    }

    const currentYear = (new Date()).getFullYear();
    const yearArray: number[] = [];

    for (let i = 2022; i <= currentYear; i++) {
        yearArray.push(i);
    }

    return (
        <ColourPaper>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Admin Functions</Typography>
                </Grid>
                <Grid item xs={12}>
                    <YellowButton fullWidth variant="contained" onClick={() => { history.push('/Refunds'); }}>Manage Refunds</YellowButton>
                </Grid>
                <Grid item xs={12}>
                    <YellowButton fullWidth variant="contained" onClick={handleEmailsClick} endIcon={<KeyboardArrowDownIcon />}>Send Emails</YellowButton>
                    <Menu
                        id="menu"
                        anchorEl={emailsAnchorEl}
                        open={Boolean(emailsAnchorEl)}
                        onClose={handleEmailsClose}
                        MenuListProps={{ onMouseLeave: handleEmailsClose }}
                    >
                        <MenuItem onClick={toggleClientEmail}>Email to All Clients</MenuItem>
                        <MenuItem onClick={toggleArchitectEmail}>Email to All Architects</MenuItem>
                    </Menu>
                    <SendEmailDialog clients open={openClientEmail} onClose={toggleClientEmail} />
                    <SendEmailDialog architects open={openArchitectEmail} onClose={toggleArchitectEmail} />
                </Grid>
                <Grid item xs={12}>
                    <YellowButton fullWidth variant="contained" onClick={handleExportsClick} endIcon={!downloading ? <KeyboardArrowDownIcon /> : <CircularProgress size={25} />} disabled={downloading}>Exports Menu</YellowButton>
                    <Menu
                        id="menu"
                        anchorEl={exportsAnchorEl}
                        open={Boolean(exportsAnchorEl)}
                        onClose={handleExportsClose}
                        MenuListProps={{ onMouseLeave: handleExportsClose }}
                    >
                        <MenuItem onClick={downloadArchitectsCSV}>All Architects CSV</MenuItem>
                        {yearArray.map(year =>
                            <MenuItem key={year + "ArchSlotCsv"} onClick={() => downloadArchitectsSlotsCSV(year)}>Architects with Timeslots for {year} CSV</MenuItem>
                        )}
                        {yearArray.map(year =>
                            <MenuItem key={year + "ArchRegCsv"} onClick={() => downloadArchitectsRegisteredCSV(year)}>Architects Registered in {year} CSV</MenuItem>
                        )}
                        <MenuItem onClick={downloadClientNoAppointmentCSV}>Clients w/ No Appointment CSV</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </ColourPaper>
    );
}