import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/MobileDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import enGB from 'date-fns/esm/locale/en-GB';
import * as React from 'react';

interface IDatePickerProps {
    date: Date | null;
    name: string;
    label?: string;
    disablePast: boolean;
    disableFuture: boolean;
    monthOnly?: boolean;
    disabled: boolean;
    size: "medium" | "small" | undefined;
    onChange: (date: Date | null, name: string) => void;
    clearable: boolean;
    maxDate?: Date;
    minDate?: Date;
}

export default function Datepicker(props: IDatePickerProps) {
    const defaultMonth = props.minDate ? new Date().setMonth(props.minDate.getMonth()) : new Date();
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
            <DatePicker
                views={props.monthOnly ? ["year", "month"] : ["year", "month", "day"]}
                openTo={props.monthOnly ? "month" : undefined}
                inputFormat={props.monthOnly ? "MM" : "dd/MM/yyyy"}
                label={props.label}
                value={props.date}
                onChange={(newValue: any) => {
                    props.onChange(newValue as Date | null, props.name);
                }}
                disableFuture={props.disableFuture}
                disablePast={props.disablePast}
                disabled={props.disabled}
                renderInput={(params) => <TextField InputLabelProps={{ shrink: true }} fullWidth size={props.size} variant="outlined" {...params} />}
                clearable={props.clearable}
                clearText="Clear"
                showToolbar={false}
                disableCloseOnSelect={false}
                maxDate={props.maxDate}
                minDate={props.minDate}
                defaultCalendarMonth={defaultMonth}
            />
        </LocalizationProvider>
    );
}