import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.main
            },
            borderRadius: 0
        },
    }));

interface IProps extends ButtonProps {
    component?: any;
    target?: string;
    notextwrap?: boolean;
    download?: boolean;
    size?: "medium" | "large" | "small" | undefined;
}

const defaultProps: IProps = {
    component: undefined,
    notextwrap: false,
    target: '_self',
    download: false,
    size: undefined
}

function YellowButton(props: IProps) {
    const classes = useStyles();
    const { component, notextwrap, size, ...rest } = props;

    return (
        <React.Fragment>
            {component ?
                <Button {...rest} target="" className={classes.root} size={size} style={notextwrap ? { whiteSpace: 'nowrap' } : {}} component={component} />
                :
                <Button {...rest} className={classes.root} size={size} style={notextwrap ? { whiteSpace: 'nowrap' } : {}} />
            }
        </React.Fragment>
    );
}

YellowButton.defaultProps = defaultProps;
export default YellowButton;