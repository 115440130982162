import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OrganisationInfoViewModel from "../ViewModels/Architects/OrganisationViewModel";
import ArchitectsTable from "./ArchitectsTable";
import authService from '../api-authorization/AuthorizeService';
import TimeslotCalendar from "./TimeslotCalendar";
import ArchitectViewModel from "../ViewModels/Architects/ArchitectViewModel";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import LinearProgress from '@mui/material/LinearProgress';
import Collapse from '@mui/material/Collapse';
import { useLocation, useHistory, LinkProps, Link } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Bugsnag from "@bugsnag/js";
import YellowButton from "../Utilities/YellowButton";

interface IProps {
    orgId: string | undefined;
}

export default function ArchitectsContainer(props: IProps) {
    const location = useLocation<IProps>();
    const { orgId } = location.state || props || {
        orgId: undefined
    };
    const history = useHistory();
    const [organisation, setOrganisation] = React.useState(new OrganisationInfoViewModel());
    const [selectedArchitect, setSelectedArchitect] = React.useState<ArchitectViewModel | null>(null);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [role, setRole] = React.useState('');

    React.useEffect(() => {
        getRole();
        getOrganisation();
    }, []);

    const getRole = async () => {
        const user = await authService.getUser();
        setRole(user ? user.role : '');
    }

    const getOrganisation = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        let url = 'Organisation/GetOrganisation';
        if (orgId !== undefined) url = url + `?id=${orgId}`;

        fetch(url, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                setOrganisation(data);
                setLoading(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const reportLink = React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link to="/Reports" ref={ref} {...itemProps} />
    ));

    return (
        <React.Fragment>
            {loading && <LinearProgress />}
            <Collapse in={!loading && organisation !== undefined && role.length > 0}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Grid container spacing={1} alignItems="center">
                                    {(role === 'Admin' || role === 'SystemAdministrator') &&
                                        <Grid item>
                                            <Button fullWidth onClick={history.goBack} startIcon={<ArrowBackIcon />}>Back</Button>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <Typography variant="h4">Architect Management</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <YellowButton fullWidth variant="contained" component={reportLink}>Your Schedule</YellowButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2}>
                            <ArchitectsTable
                                organisationId={organisation.id}
                                setSelectedArchitect={(architect) => setSelectedArchitect(architect)}
                                count={count}
                                setCount={(value: number) => setCount(value)}
                            />
                            <Stack spacing={2} sx={{ marginLeft: '24px !important', marginRight: '24px !important' }}>
                                <Typography variant="body2" color="gray">Managing your appointments:</Typography>
                                <Typography variant="body2" color="gray"> • Add and remove Architects on your practice list above</Typography>
                                <Typography variant="body2" color="gray"> • Select an Architect from the list to see their calendar</Typography>
                                <Typography variant="body2" color="gray"> • Add appointments by clicking on the calendar or the Add Appointments button</Typography>
                                <Typography variant="body2" color="gray"> • Please have the Video Conferencing Link ready when adding an appointment</Typography>
                                <Typography variant="body2" color="gray"> • Colours represent the status of an appointment</Typography>
                                <Stack direction="row" spacing={4} justifyContent="center">
                                    <Chip sx={{ minWidth: '100px', backgroundColor: '#00AEEF', borderRadius: '8px', color: 'white' }} label="Available" />
                                    <Chip sx={{ minWidth: '100px', backgroundColor: '#F37360', borderRadius: '8px', color: 'white' }} label="Reserved" />
                                    <Chip sx={{ minWidth: '100px', backgroundColor: '#5EC4B6', borderRadius: '8px', color: 'white' }} label="Booked" />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {orgId &&
                            <Collapse in={count > 0}>
                                <TimeslotCalendar architect={selectedArchitect} organisationId={orgId} />
                            </Collapse>
                        }
                    </Grid>
                </Grid>
            </Collapse>
        </React.Fragment>
    );
}