export default class CreateCampaignSettingsViewModel {
    public createdById: string = '';
    public campaignStartDate: Date | null = null;
    public campaignEndDate: Date | null = null;
    public canBookSlots: boolean = false;
    public canCreateSlots: boolean = false;
    public baseAmount: number = 0;
    public cancellationAmount: number = 0;
    public active: boolean = false;
    public target: number = 0;
}