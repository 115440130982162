import * as React from 'react';
import NavMenu from './NavMenu';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import authService from '../components/api-authorization/AuthorizeService';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh'
        },
        shopRoot: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: '#f6f6f6'
        },
        content: {
            flexGrow: 1,
        },
        container: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(3)
        },
        footer: {
            marginTop: 'auto',
            padding: theme.spacing(3),
            width: '100%',
            backgroundColor: '#e8e8e8'
        },
        footerBar: {
            padding: theme.spacing(2),
            backgroundColor: '#fff'
        }
    }),
);

interface IProps {
    children: any;
}

const Layout = (props: IProps) => {
    const classes = useStyles();
    const [authenticated, setAuthenticated] = React.useState(false);

    React.useEffect(() => {
        checkAuth();
    }, [])

    const checkAuth = async () => {
        setAuthenticated(await authService.isAuthenticated());
    }

    return (
        <div className={classes.root}>
            <React.Fragment>
                {authenticated &&
                    <NavMenu />
                }
                <main className={classes.content}>
                    {authenticated &&
                        <Toolbar />
                    }
                    <Container maxWidth="xl" className={classes.container}>
                        <React.Fragment key={'mainView'}>{props.children}</React.Fragment>
                    </Container>
                </main>
            </React.Fragment>
            <div className={classes.footerBar}>
                <Container maxWidth="lg">
                    <Grid container alignContent="space-between" alignItems="center" spacing={4}>
                        <Grid item xs={12} md={9}>
                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="body2">&copy; RIAI (The Royal Institute of the Architects of Ireland) <a href="https://smashedcrabsoftware.com" target="_blank">Built by</a><a href="https://smashedcrabsoftware.com" target="_blank"><img src='crab.png' alt="Crab Logo" style={{ marginLeft: '5px', height: '14px', marginBottom: '-3px' }} /></a></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="body2">Company Limited by Guarantee. Registered in Ireland, No. 003498. RCN 20008109</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="body2">Simon Community | Registered Charity No. 20020125 | Reg. No. 74038, Charity No. 8273</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <VerifiedUser style={{ color: "#929292" }} />
                                </Grid>
                                <Grid item>
                                    <img src='paymentslogos.png' alt="Payment Logos" style={{ marginLeft: '10px', height: '24px', marginBottom: '-6px' }} />
                                </Grid>
                                <Grid item>
                                    <Typography><a href="/CookiesPolicy">Cookie Policy</a></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
}
export default Layout;