export default class ProfileViewModel {
    public id: string = '';
    public email: string = '';
    public userType: string = '';
    public streetAddress1: string = '';
    public streetAddress2: string = '';
    public streetAddress3: string = '';
    public city: string = '';
    public province: string = '';
    public postalCode: string = '';
}

export class ClientProfileViewModel extends ProfileViewModel {
    public titleID: number = 0;
    public firstName: string = '';
    public lastName: string = '';
}

export class OrganisationProfileViewModel extends ProfileViewModel {
    public organisationName: string = '';
    public locationId: number = 0;
    public url: string = '';
    public contactNumber: string = '';
}

export class SystemAdministratorProfileViewModel extends ProfileViewModel {
    public titleID: number = 0;
    public firstName: string = '';
    public lastName: string = '';
}