import * as React from 'react';
import { OrganisationListViewModel } from "../../ViewModels/User/UserListViewModel";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import YellowButton from '../../Utilities/YellowButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import CircularProgress from '@mui/material/CircularProgress';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        activateButton: {
            color: '#fff',
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.dark
            },
            borderRadius: 0
        },
        deactivateButton: {
            color: '#fff',
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.dark
            },
            borderRadius: 0
        }
    }));

interface IProps {
    row: OrganisationListViewModel;
    refresh: () => void;
}

export default function OrganisationsTableRow(props: IProps) {
    const classes = useStyles();
    const { show } = React.useContext(AlertContext);
    const { row, refresh } = props;
    const [disabled, setDisabled] = React.useState(false);

    const profileLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: `/Profile`,
                state: {
                    id: row.id
                }
            }}
            {...props} />
    ));

    const architectsLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: `/Architects`,
                state: {
                    orgId: row.id
                }
            }}
            {...props} />
    ));

    const deactivate = async (id: string) => {
        setDisabled(true);
        const token = await authService.getAccessToken();
        fetch(`Organisation/DeactivateOrganisation?id=${id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    show('error', data);
                } else {
                    refresh();
                    show('success', 'Successfully deactivated user');
                }

                setDisabled(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setDisabled(false);
            });
    }

    const activate = async (id: string) => {
        setDisabled(true);
        const token = await authService.getAccessToken();
        fetch(`Organisation/ActivateOrganisation?id=${id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    show('error', data);
                } else {
                    refresh();
                    show('success', 'Successfully activated user');
                }

                setDisabled(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setDisabled(false);
            });
    }

    return (
        <TableRow id={row.id}>
            <TableCell align="center">{row.organisationName}</TableCell>
            <TableCell align="center">{row.email}</TableCell>
            <TableCell align="center">{row.location}</TableCell>
            <TableCell>
                <Grid container alignItems="center" justifyContent="flex-end" spacing={1} style={{ flexWrap: 'nowrap' }}>
                    <Grid item>
                        <YellowButton notextwrap={true} variant="contained" fullWidth size="small" component={profileLink}>Profile</YellowButton>
                    </Grid>
                    <Grid item>
                        <YellowButton notextwrap={true} variant="contained" fullWidth size="small" component={architectsLink}>Architects</YellowButton>
                    </Grid>
                    <Grid item>
                        <Button size="small" variant="contained" disabled={disabled} className={row.archived ? classes.activateButton : classes.deactivateButton} onClick={row.archived ? () => { activate(row.id); } : () => { deactivate(row.id); }}>{disabled ? <CircularProgress size={25} /> : row.archived ? "Activate" : "Deactivate"}</Button>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
}