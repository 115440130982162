import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import SquareButton from '../Utilities/SquareButton';
import YellowButton from '../Utilities/YellowButton';
import AppointmentListViewModel from '../ViewModels/Booking/AppointmentListViewModel';
import CreateAppointmentViewModel from '../ViewModels/Booking/CreateAppointmentViewModel';
import InputAdornment from '@mui/material/InputAdornment';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Bugsnag from '@bugsnag/js';

const moment = require('moment');

interface IProps {
    timeslot: AppointmentListViewModel;
    next: (appointId: number) => void;
    close: (refresh: boolean) => void;
    donation: number;
    updateDonation: (amount: number) => void;
    updateBaseAmount: (amount: number) => void;
    baseAmount: number;
    clientId?: string;
    appointmentId?: number;
}

export default function AppointmentDetails(props: IProps) {
    const history = useHistory();
    const { timeslot, next, close, donation, updateDonation, updateBaseAmount, baseAmount, clientId, appointmentId } = props;
    const { show } = React.useContext(AlertContext);
    const [details, setDetails] = React.useState(new CreateAppointmentViewModel(timeslot.id, (timeslot.online && !timeslot.inPerson), (timeslot.inPerson && !timeslot.online)));
    const [saving, setSaving] = React.useState(false);
    const [meetingValue, setMeetingValue] = React.useState('online');
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timer | null>(null);

    React.useEffect(() => {
        if (timeslot && timeslot.id > 0) {
            let timeoutId = setTimeout(checkUserIsLockedBy, 5000);
            setTimeoutId(timeoutId);
        }
    }, [timeslot])

    const checkUserIsLockedBy = async () => {
        const token = await authService.getAccessToken();
        fetch(`Appointment/CheckUserIsTimeSlotLock?timeslotId=${timeslot.id}&${clientId ? `clientId=${clientId}` : ""}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then(response => response.json())
            .then(data => {
                if (data === false) {
                    show('error', "In the time since this appointment was presented to you, another client has commenced a booking for it. It has been reserved for them for 15 minutes. Please choose another appointment.");
                    history.goBack();
                }
            }).catch((error) => {
                Bugsnag.notify(error);
            });
    }

    ValidatorForm.addValidationRule("notNegative", (value: number) => {
        if (!value || value > -1) {
            return true;
        } return false;
    });

    ValidatorForm.addValidationRule("eirCode", (value: string) => {
        if (value.match(/(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -][0-9AC-FHKNPRTV-Y]{4}$/g)) return true;
        return false;
    })

    React.useEffect(() => {
        if (appointmentId && appointmentId > 0) {
            getExisting();
        } else {
            setDetails(new CreateAppointmentViewModel(timeslot.id, (timeslot.online && !timeslot.inPerson), (timeslot.inPerson && !timeslot.online)));
        }
    }, [timeslot, appointmentId]);

    const getExisting = async () => {
        const token = await authService.getAccessToken();

        setSaving(true);
        fetch(`Appointment/GetAppointment?id=${appointmentId}&timeslotId=${timeslot.id}&${clientId ? `clientId=${clientId}` : ""}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then(response => response.json())
            .then(data => {
                setSaving(false);
                if (!data) {
                    setDetails(new CreateAppointmentViewModel(timeslot.id, (timeslot.online && !timeslot.inPerson), (timeslot.inPerson && !timeslot.online)));
                } else {
                    setDetails(data);
                }

            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });

    }

    const save = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        let url = 'Appointment/';

        if (clientId)
            url = url + `AdminAddAppointment?clientId=${clientId}&amount=${baseAmount}&donation=${donation}`;
        else
            url = url + 'AddUpdateAppointment';

        fetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(details)
        })
            .then(response => {
                response.text().then(data => {
                    setSaving(false);

                    if (response.ok) {
                        setDetails(new CreateAppointmentViewModel(timeslot.id, (timeslot.online && !timeslot.inPerson), (timeslot.inPerson && !timeslot.online)));
                        if (!clientId) next(parseInt(data));
                        else history.push('/');
                    } else {
                        show('error', data);
                    }
                })
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const cancel = async () => {
        console.log(timeoutId);
        if (timeoutId) clearTimeout(timeoutId);
        if (details && details.id && details.id > 0) {
            const token = await authService.getAccessToken();

            fetch(`Appointment/CancelAddAppointment?appointmentId=${details.id}&${clientId ? `clientId=${clientId}` : ""}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.text())
                .then(data => {
                    if (data.length > 0) {
                        show('error', data);
                    }
                })
                .catch(error => {
                    Bugsnag.notify(error);
                });
        }
        setDetails(new CreateAppointmentViewModel(timeslot.id, (timeslot.online && !timeslot.inPerson), (timeslot.inPerson && !timeslot.online)));
        close(false);
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDetails({
            ...details,
            [event.target.name]: event.target.value
        });
    }

    const onMeetingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMeetingValue((event.target as HTMLInputElement).value);

        setDetails({
            ...details,
            online: event.target.value === 'online',
            inPerson: event.target.value === 'inPerson'
        });
    };

    return (
        <ValidatorForm onSubmit={save}>
            <Stack spacing={2} alignItems="stretch">
                <FormControl>
                    <FormLabel>Architect</FormLabel>
                    <Typography variant="h6"><strong>{timeslot.name}</strong></Typography>
                </FormControl>
                <FormControl>
                    <FormLabel>Date</FormLabel>
                    <Typography>{new Date(timeslot.slotTime).toLocaleDateString('en-GB')}</Typography>
                </FormControl>
                <FormControl>
                    <FormLabel>Time</FormLabel>
                    <Typography>{moment(timeslot.slotTime).format('HH:mm')} - {moment(timeslot.slotTime).add(1, 'h').format('HH:mm')}</Typography>
                </FormControl>
                <FormControl>
                    <FormLabel id="meeting-choice">Location</FormLabel>
                    {(timeslot.online && timeslot.inPerson) ?
                        <RadioGroup
                            aria-labelledby="meeting-choice-label"
                            value={meetingValue}
                            onChange={onMeetingChange}
                            row
                        >
                            <FormControlLabel value="online" control={<Radio />} label="Online" />
                            <FormControlLabel value="inPerson" control={<Radio />} label="In Person" />
                        </RadioGroup> :
                        timeslot.online ?
                            <Typography>Meeting Online</Typography> :
                            <Typography>Meeting In Person</Typography>
                    }
                </FormControl>
                <FormControl>
                    <FormLabel>Project Address</FormLabel>
                    <TextValidator
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        name="address"
                        value={details.address}
                        onChange={onChange}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Project Eircode</FormLabel>
                    <TextValidator
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        name="eirCode"
                        value={details.eirCode}
                        onChange={onChange}
                        validators={['required', 'eirCode']}
                        errorMessages={['This field is required', 'Not a valid Eircode']}
                        InputLabelProps={{ shrink: true }}
                    />
                    <FormHelperText>Please enter the Eircode in XXX XXXX format (with a space).</FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel>Project Details</FormLabel>
                    <TextValidator
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        name="description"
                        value={details.description}
                        onChange={onChange}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        multiline
                        rows={4}
                        InputLabelProps={{ shrink: true }}
                        helperText="These details can be changed from the client dashboard at a later time."
                    />
                </FormControl>
                {!clientId ?
                    <FormControl>
                        <FormLabel>Appointment Fee</FormLabel>
                        <Typography>€{isNaN(baseAmount) ? '0.00' : baseAmount.toFixed(2)}</Typography>
                    </FormControl>
                    :
                    <FormControl>
                        <FormLabel>Base Amount</FormLabel>
                        <TextValidator
                            fullWidth
                            size="small"
                            margin="dense"
                            variant="outlined"
                            name="baseAmount"
                            type="number"
                            value={baseAmount}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateBaseAmount(event.target.valueAsNumber)}
                            validators={['notNegative']}
                            errorMessages={['This value cannot be negative']}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>
                            }}
                            inputProps={{
                                min: 0,
                                step: ".01"
                            }} />
                    </FormControl>
                }
                <FormControl>
                    <FormLabel>Additional Voluntary Donation</FormLabel>
                    <TextValidator
                        fullWidth
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="donationAmount"
                        type="number"
                        value={donation}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateDonation(event.target.valueAsNumber)}
                        validators={['notNegative']}
                        errorMessages={['This value cannot be negative']}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>
                        }}
                        inputProps={{
                            min: 0,
                            step: ".01"
                        }} />
                    <FormHelperText>This is an additional voluntary donation. In the event of a client cancellation, a refund will be processed in accordance with the <a href='https://riaisimonopendoor.ie/terms-of-use' target='_blank'>Terms of Use</a>.</FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel>Total Amount Payable</FormLabel>
                    <Typography>€{isNaN((baseAmount + donation)) ? isNaN(baseAmount) ? donation.toFixed(2) : baseAmount.toFixed(2) : (baseAmount + donation).toFixed(2)}</Typography>
                </FormControl>
                <Stack spacing={1} direction="row">
                    <SquareButton fullWidth onClick={cancel} variant="outlined" color="error" disabled={saving}>Cancel</SquareButton>
                    <YellowButton fullWidth variant="contained" type="submit" disabled={saving} endIcon={clientId && saving && <CircularProgress size={25} />}>{clientId ? "Book" : "Next"}</YellowButton>
                </Stack>
            </Stack>
        </ValidatorForm>
    );
}