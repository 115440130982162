export default class CreateAppointmentViewModel {
    public id: number | null = null;
    public timeSlotId: number = 0;
    public description: string = '';
    public online: boolean = false;
    public inPerson: boolean = false;
    public address: string = '';
    public eirCode: string = '';

    constructor(timeslotId: number, online: boolean, inperson: boolean, id?: number) {
        this.timeSlotId = timeslotId;
        this.online = online;
        this.inPerson = inperson;

        if (id) {
            this.id = id;
        }
    }
}