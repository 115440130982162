import * as React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ColourPaper from '../Utilities/ColourPaper';
import { useHistory } from 'react-router-dom';
import YellowButton from '../Utilities/YellowButton';

interface IProps {
    orderId?: string;
    type?: string;
}

export default function PaymentSuccess() {
    const history = useHistory();
    const { orderId, type } = useParams<IProps>();

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <ColourPaper>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="h5">Simon Community and the RIAI would like to thank you for your generous support. Every cent raised will go directly to the Simon Communities of Ireland to help fund the work with people who are homeless. Find out more at <a href="www.simon.ie">www.simon.ie.</a></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Your donation reference is: {orderId}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {type && type === "Appointment" &&
                                <Typography>A confirmation of your booking, along with further information and advice, has been emailed to you at the address provided.</Typography>
                            }
                            {type && type === "Donation" &&
                                <Typography>A receipt for your donation has been emailed to you at the address provided</Typography>
                            }
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            {type && type === "Appointment" &&
                                <YellowButton fullWidth variant="contained" color="secondary" onClick={() => { history.push('/') }}>Back to Home</YellowButton>
                            }
                            {type && type === "Donation" &&
                                <YellowButton fullWidth variant="contained" color="secondary" href="https://www.riaisimonopendoor.ie">Back to Home</YellowButton>
                            }
                        </Grid>
                    </Grid>
                </ColourPaper>
            </Grid>
        </Grid>
    );
}