import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import YellowButton from './YellowButton';
import SquareButton from './SquareButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(0)
        },
    })
);

interface IProps {
    text: string | JSX.Element;
    buttonText: string;
    open: boolean;
    okLink?: any;
    okClick?: (description?: string) => void;
    close: () => void;
    disabled?: boolean;
    description?: boolean;
    title?: string;
    showCancel?: boolean;
    closeLabel?: string;
}

export default function WarningDialog(props: IProps) {
    const classes = useStyles();
    const { open, close, okLink, okClick, text, buttonText, disabled, description, title, showCancel, closeLabel } = props;
    const [descValue, setDescValue] = React.useState('');

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescValue(event.target.value);
    }

    const cancel = () => {
        close();
    }

    const clickedOk = () => {
        if (okClick) {
            okClick(descValue);
        }
    }

    const isDisabled = disabled === undefined ? false : disabled;

    const okButton = okLink ?
        <YellowButton variant="contained" color="primary" component={okLink} disabled={isDisabled}>{buttonText}</YellowButton> :
        <YellowButton variant="contained" color="primary" onClick={clickedOk} disabled={isDisabled}>{buttonText}</YellowButton>;

    const handleRoomKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && okClick) {
            okClick(descValue);
        }
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="warning-title"
            aria-describedby="warning-desc"
            PaperProps={{ className: classes.paper, square: true }}
            onKeyDown={handleRoomKeyPress}
        >
            <DialogTitle id="warning-title">{title !== undefined ? title : "Are you sure?" }</DialogTitle>
            <DialogContent>
                <DialogContentText id="warning-desc">{text}</DialogContentText>
                {description &&
                    <Grid item>
                        <DialogContentText>Please provide a description:</DialogContentText>
                        <TextField
                            fullWidth
                            value={descValue}
                            onChange={onChange}
                        />
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                {showCancel !== false && <SquareButton onClick={cancel} variant="outlined" color="error" disabled={disabled}>{closeLabel ? closeLabel : "Cancel"}</SquareButton>}
                {okButton}
            </DialogActions>
        </Dialog>
    );
}