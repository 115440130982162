import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import authService from '../api-authorization/AuthorizeService';
import SquareButton from '../Utilities/SquareButton';
import YellowButton from '../Utilities/YellowButton';
import { AlertContext } from '../Contexts/AlertContext';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2)
        },
    })
);

interface IProps {
    clients?: boolean;
    architects?: boolean;
    orgId?: string;
    open: boolean;
    onClose: () => void;
}

export default function SendEmailDialog(props: IProps) {
    const classes = useStyles();
    const { show } = React.useContext(AlertContext);
    const { clients, architects, orgId, open, onClose } = props;
    const [subject, setSubject] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [sending, setSending] = React.useState(false);

    const close = () => {
        setSubject('');
        setMessage('');
        onClose();
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, element: string) => {
        switch (element) {
            case "Subject":
                setSubject(event.target.value);
                break;
            case "Message":
                setMessage(event.target.value);
                break;
        }
    }

    const send = async () => {
        const token = await authService.getAccessToken();
        setSending(true);

        let url = 'User/';
        if (clients) url = url + 'EmailAllClients';
        if (architects && orgId) url = url + 'EmailAllArchitectsInOrg?id=' + orgId;
        else if (architects) url = url + 'EmailAddArchitects';

        fetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ subject: subject, message: message })
        })
            .then(response => response.text())
            .then(data => {
                setSending(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    close();
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSending(false);
            });
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={onClose}
            PaperProps={{ className: classes.paper, square: true }}
            maxWidth="lg"
        >
            <ValidatorForm onSubmit={send}>
                <DialogTitle>Send Email {clients ? 'to Clients' : 'to Architects'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Subject"
                                name="subject"
                                value={subject}
                                onChange={(event) => { onChange(event as React.ChangeEvent<HTMLInputElement>, 'Subject') }}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                multiline
                                rows={12}
                                margin="dense"
                                variant="outlined"
                                label="Message"
                                name="message"
                                value={message}
                                onChange={(event) => { onChange(event as React.ChangeEvent<HTMLInputElement>, 'Message') }}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={close} variant="outlined" color="error" disabled={sending}>Cancel</SquareButton>
                    <YellowButton variant="contained" type="submit" disabled={sending}>Send</YellowButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}