import * as React from 'react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import authService from '../api-authorization/AuthorizeService';
import { CreateArchitectViewModel } from '../ViewModels/Architects/ArchitectViewModel';
import { AlertContext } from '../Contexts/AlertContext';
import SquareButton from '../Utilities/SquareButton';
import YellowButton from '../Utilities/YellowButton';
import DialogTitle from '@mui/material/DialogTitle';
import Bugsnag from '@bugsnag/js';

interface IProps {
    organisationId: string;
    open: boolean;
    close: (refresh: boolean) => void;
}

export default function CreateArchitectDialog(props: IProps) {
    const { organisationId, open, close } = props;
    const { show } = React.useContext(AlertContext);
    const [newArchitect, setNewArchitect] = React.useState(new CreateArchitectViewModel(organisationId));
    const [confirmEmail, setConfirmEmail] = React.useState('');
    const [saving, setSaving] = React.useState(false);
    const [memberIdError, setMemberIdError] = React.useState('');
    const [emailError, setEmailError] = React.useState('');

    React.useEffect(() => {
        setNewArchitect({
            ...newArchitect,
            organisationId: organisationId
        });
    }, [organisationId]);

    const save = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);
        setEmailError('');
        setMemberIdError('');

        fetch(`Architect/AddArchitect`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(newArchitect)
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    if (data.includes('Member')) {
                        setMemberIdError(data);
                    } else if (data.includes('Email')) {
                        setEmailError(data);
                    } else {
                        show('error', data);
                    }
                } else {
                    setNewArchitect(new CreateArchitectViewModel(organisationId));
                    setConfirmEmail('');
                    close(true);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'email') {
            setEmailError('');
        }
        if (event.target.name === 'memberId') {
            setMemberIdError('');
        }

        setNewArchitect({
            ...newArchitect,
            [event.target.name]: event.target.name === 'conservation' || event.target.name === 'mailChimp' ? event.target.checked : event.target.value
        });
    }

    const cancel = () => {
        setNewArchitect(new CreateArchitectViewModel(organisationId));
        setConfirmEmail('');
        setEmailError('');
        setMemberIdError('');
        close(false);
    }

    ValidatorForm.addValidationRule('isMatch', (value) => {
        if (newArchitect.email !== value) {
            return false;
        }

        return true;
    });

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={cancel}
            PaperProps={{ square: true }}
        >
            <DialogTitle>Add Architect</DialogTitle>
            <ValidatorForm onSubmit={save}>
                <DialogContent sx={{ paddingTop: 0 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="First Name"
                                name="firstName"
                                value={newArchitect.firstName}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Last Name"
                                name="lastName"
                                value={newArchitect.lastName}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Member ID"
                                name="memberId"
                                value={newArchitect.memberId}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                error={memberIdError.length > 0}
                                helperText={memberIdError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Email"
                                name="email"
                                value={newArchitect.email}
                                onChange={onChange}
                                validators={['required', 'isEmail']}
                                errorMessages={['This field is required', 'Invalid Email']}
                                error={emailError.length > 0}
                                helperText={emailError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Confirm Email"
                                name="email"
                                value={confirmEmail}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmEmail(event.target.value)}
                                validators={['required', 'isEmail', 'isMatch']}
                                errorMessages={['This field is required', 'Invalid Email', 'Emails do not match']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="conservation" checked={newArchitect.conservation} onChange={onChange} />} label="Conservation" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="mailChimp" checked={newArchitect.mailChimp} onChange={onChange} />} label="Receive Notifications" />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={cancel} variant="outlined" color="error" disabled={saving}>Cancel</SquareButton>
                    <YellowButton variant="contained" type="submit" disabled={saving}>Create</YellowButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}