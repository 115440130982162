import * as React from 'react';
import Typography from '@mui/material/Typography';
import AppointmentListViewModel from '../ViewModels/Booking/AppointmentListViewModel';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
const moment = require('moment');

interface IProps {
    list: AppointmentListViewModel[];
    selectAppointment: (item: AppointmentListViewModel) => void;
    canBook: boolean;
}

function groupBy(data: AppointmentListViewModel[], keyFn: any) {
    let m = new Map();

    for (let x of data) {
        let k = keyFn(x);
        if (!m.has(k))
            m.set(k, []);
        m.get(k).push(x);
    }

    return m;
}

export default function ArchitectListRow(props: IProps) {
    const { list, selectAppointment, canBook } = props;

    const dateGrouping = [];

    for (let [date, items] of groupBy(list.filter(f => !f.booked), (x: AppointmentListViewModel) => new Date(x.slotTime).toLocaleDateString('en-GB'))) {
        dateGrouping.push({ date, slots: items });
    }

    return (
        <React.Fragment>
            {dateGrouping.map((item, index) =>
                <ListItem key={item.date + index}>
                    <Stack spacing={1}>
                        <Typography>{item.date}</Typography>
                        <Grid container spacing={1}>
                            {item.slots.map((slot: AppointmentListViewModel) => {
                                if (slot.online && slot.inPerson) {
                                    return (
                                        <Grid item key={slot.id}>
                                            <Chip
                                                sx={{
                                                    bgcolor: 'warning.main',
                                                    color: 'white',
                                                    '&:hover': {
                                                        bgcolor: 'warning.dark',
                                                        color: 'white'
                                                    }
                                                }}
                                                onClick={() => selectAppointment(slot)}
                                                label={moment(slot.slotTime).format('HH:mm') + ' - ' + moment(slot.slotTime).add(1, 'h').format('HH:mm')}
                                                disabled={slot.booked || !canBook}
                                            />
                                        </Grid>
                                    )
                                }
                                else if (slot.online && !slot.inPerson) {
                                    return (
                                        <Grid item key={slot.id}>
                                            <Chip
                                                sx={{
                                                    bgcolor: 'tertiary.main',
                                                    color: 'white',
                                                    '&:hover': {
                                                        bgcolor: 'tertiary.dark',
                                                        color: 'white'
                                                    }
                                                }}
                                                onClick={() => selectAppointment(slot)}
                                                label={moment(slot.slotTime).format('HH:mm') + ' - ' + moment(slot.slotTime).add(1, 'h').format('HH:mm')}
                                                disabled={slot.booked || !canBook}
                                            />
                                        </Grid>
                                    )
                                }
                                else if (!slot.online && slot.inPerson) {
                                    return (
                                        <Grid item key={slot.id}>
                                            <Chip
                                                sx={{
                                                    bgcolor: 'success.main',
                                                    color: 'white',
                                                    '&:hover': {
                                                        bgcolor: 'success.dark',
                                                        color: 'white'
                                                    }
                                                }}
                                                onClick={() => selectAppointment(slot)}
                                                label={moment(slot.slotTime).format('HH:mm') + ' - ' + moment(slot.slotTime).add(1, 'h').format('HH:mm')}
                                                disabled={slot.booked || !canBook}
                                            />
                                        </Grid>
                                    )
                                }
                            })}
                        </Grid>
                    </Stack>
                </ListItem>
            )}
        </React.Fragment >
    );
}