import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { createStyles, makeStyles } from '@mui/styles';
import PaymentRequestDataViewModel from '../ViewModels/Payments/PaymentRequestDataViewModel';
import PaymentViewModel from '../ViewModels/Payments/PaymentViewModel';
import Bugsnag from '@bugsnag/js';

const height = 600;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'relative',
            height: `calc(${height} + ${theme.spacing(2)})`,
            padding: theme.spacing(2)
        },
        loading: {
            position: 'absolute',
            top: 'calc(50% - 10px)',
            left: 'calc(50% - 10px)',
            zIndex: 0
        },
        image: {
            width: "100%"
        },
        iFrameNotLoaded: {
            opacity: 0,
            border: 'none',
            position: 'relative',
            zIndex: 100
        },
        iFrameLoaded: {
            opacity: 1,
            border: 'none',
            position: 'relative',
            zIndex: 100
        }
    }));

interface RouteParams {
    orderId: string;
}

export default function GuestPayment() {
    const classes = useStyles();
    const location = useLocation<RouteParams>();
    const { orderId } = location.state || { orderId: '' };
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [order, setOrder] = React.useState<PaymentViewModel>(new PaymentViewModel(1));
    const [loading, setLoading] = React.useState(true);
    const [paymentRequestData, setPaymentRequestData] = React.useState<PaymentRequestDataViewModel>(new PaymentRequestDataViewModel);

    const iframeRef = React.createRef<HTMLIFrameElement>();
    React.useEffect(() => {
        if (iframeRef.current) {
            var iframe: any = document.getElementById('realexIframe');

            if (paymentRequestData.MERCHANT_ID === "riai" && iframe.src.includes("Dummy")) {

                loadIFrame(paymentRequestData);
                setLoading(false);
            }
        }
    }, [iframeRef, paymentRequestData]);

    React.useEffect(() => {
        getPurchase();
    }, []);

    React.useEffect(() => {
        if (order !== null) {
            if (order.streetAddress1 !== null && order.streetAddress1.length > 0 && order.phoneNumber !== null && order.phoneNumber.length > 0)
                realexHpp();
        }
    }, [order]);

    const getPurchase = async () => {
        fetch(`api/Payments/getPurchase?purchaseId=${orderId}`, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setOrder(data);
            })
            .catch(error => {
                Bugsnag.notify(error);
            });
    }

    const realexHpp = async () => {
        const data = JSON.stringify({
            purchaseId: orderId,
            billingAddress:
            {
                streetAddress1: order.streetAddress1,
                streetAddress2: order.streetAddress2,
                streetAddress3: order.streetAddress3,
                city: order.city,
                province: order.province,
                postalCode: order.postalCode,
            },
            countryCode: order.countryCode,
            phoneNumber: order.phoneNumber,
            donationAmount: order.donationAmount,
            email: order.email
        });

        fetch("api/Payments/RequestGuestPayment",
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                body: data
            }
        ).then(response => response.json()).then((data) => {
            setPaymentRequestData(data);
        }).catch(error => {
            Bugsnag.notify(error);
        });
    }

    const loadIFrame = (data: any) => {
        if (document.readyState === "complete") {
            window.RealexHpp.setHppUrl("https://pay.realexpayments.com/pay");
            window.RealexHpp.embedded.init("payButton", "realexIframe", window.location.origin + "/api/Payments/PaymentResponse", data);

            if (buttonRef && buttonRef.current) {
                buttonRef.current.click();
            }
        }
    }

    const bannerURL = window.location.origin + '/payment_banner.png';

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            {loading && <Grid item xs={12}><LinearProgress color="primary" /></Grid>}

            <Grid item xs={12}>
                <Fade in={!loading}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <img src={bannerURL} className={classes.image} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Button id="payButton" hidden ref={buttonRef} />
                            <Paper className={classes.paper}>
                                <div className={classes.loading}>
                                    <CircularProgress color="secondary" />
                                </div>
                                <iframe ref={iframeRef} id="realexIframe" height={height + 4} width={'100%'} className={loading ? classes.iFrameNotLoaded : classes.iFrameLoaded} src="iframeDummyLoader.html" />
                            </Paper>
                        </Grid>
                    </Grid>
                </Fade>
            </Grid>
        </Grid>
    );
}