import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import SearchButton from '../Utilities/SearchButton';
import CircularProgress from '@mui/material/CircularProgress';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import ColourPaper from '../Utilities/ColourPaper';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import ArchitectViewModel from '../ViewModels/Architects/ArchitectViewModel';
import ArchitectsTableRow from './ArchitectsTableRow';
import YellowButton from '../Utilities/YellowButton';
import CreateArchitectDialog from './CreateArchitectDialog';
import { useLocation } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        exportButton: {
            alignItems: 'flex-end',
            justifyContentContent: 'center'
        },
    }),
);

const headCells: HeadCell<ArchitectViewModel>[] = [
    { id: 'name', property: 'Name', disablePadding: false, label: 'Name' },
    { id: 'memberId', property: 'MemberId', disablePadding: false, label: 'Member ID' },
    { id: 'email', property: 'Email', disablePadding: false, label: 'Email' },
    { id: 'conservation', property: 'Conservation', align: "center", disablePadding: false, label: 'Conservation' },
];

interface IProps {
    organisationId?: string;
    setSelectedArchitect?: (architect: ArchitectViewModel | null) => void;
    setCount: (count: number) => void;
    count: number;
}

interface RouteParams {
    organisation?: string;
}

export default function ArchitectsTable(props: IProps) {
    const classes = useStyles();
    const { organisationId, setSelectedArchitect, count, setCount } = props;
    const location = useLocation<RouteParams>();
    const { organisation } = location.state || { organisation: undefined };
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof ArchitectViewModel>('name');
    const [results, setResults] = React.useState<ArchitectViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [createOpen, setCreateOpen] = React.useState(false);
    const [selectedArchitect, setSelected] = React.useState<ArchitectViewModel | null>(null);

    React.useEffect(() => {
        if (organisationId || organisation)
            getData();
    }, [organisationId, organisation, order, orderBy]);

    React.useEffect(() => {
        if (setSelectedArchitect) {
            setSelectedArchitect(selectedArchitect);
        }
    }, [selectedArchitect]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        const orgId = organisationId ? organisationId : organisation ? organisation : '';

        fetch(`Architect/GetArchitects?organisation=${encodeURIComponent(orgId)}&orderBy=${orderByProp}&order=${order}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                setResults(data.rows);
                setCount(data.rowCount);

                if (data.rows.length > 0) {
                    setSelected(data.rows[0]);
                } else {
                    setSelected(null);
                }

                setLoading(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ArchitectViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            getData();
        }
    }

    const closeCreate = (refresh: boolean) => {
        if (refresh) {
            getData();
        }

        setCreateOpen(false);
    }

    return (
        <div className={classes.root}>
            <ColourPaper>
                <Grid container justifyContent="space-between" spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="h5">Architects</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    onChange={handleSearch}
                                    value={search}
                                    placeholder="Search..."
                                    onKeyDown={keyPress}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchButton aria-label={"Search Architects"} onClick={getData} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <YellowButton variant="contained" onClick={() => setCreateOpen(true)}>Add Architect</YellowButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <ResponsiveTable
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="architect table"
                                sx={{width: 'calc(100% - 1px)'}}
                            >
                                <SortableTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                    lastCells={
                                        <TableCell />
                                    }
                                />
                                <TableBody>
                                    {loading &&
                                        <TableRow style={{ height: 53 }}>
                                            <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /><Typography>Pulling latest data</Typography></TableCell>
                                        </TableRow>
                                    }
                                    {!loading && results && results.map((row: ArchitectViewModel) => {
                                        return (<ArchitectsTableRow
                                            key={row.id}
                                            row={row}
                                            refresh={getData}
                                            selectRow={(value) => setSelected(value)}
                                            onClick={(event) => setSelected(row)}
                                            selected={selectedArchitect === row} />);
                                    })}
                                </TableBody>
                            </ResponsiveTable>
                            {count <= 0 && !loading &&
                                <Grid container justifyContent="center" alignItems="flex-end">
                                    <Grid item>
                                        <Typography variant="h5">No Architects found</Typography>
                                    </Grid>
                                </Grid>
                            }
                        </TableContainer>
                    </Grid>
                </Grid>
            </ColourPaper>
            <CreateArchitectDialog open={createOpen} close={closeCreate} organisationId={organisationId && organisationId ? organisationId : organisation ? organisation : ''} />
        </div >
    );
}