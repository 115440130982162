import ColourPaper from '../Utilities/ColourPaper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import * as React from 'react';

interface IProps {
    value: string | number | null;
    title: string;
    inputAdornment?: string | JSX.Element;
    endAdornment?: string | JSX.Element;
    topborder?: string;
}

export default function ReportMetric(props: IProps) {
    const { value, title, inputAdornment, endAdornment, topborder } = props;

    return (
        <ColourPaper style={{ height: '100%' }} topborder={topborder}>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    {value !== null ?
                        <h2 style={{ textAlign: "center" }}>{inputAdornment}{value}{endAdornment}</h2>
                        :
                        <h2>N/A</h2>
                    }
                </Grid>
                <Grid item style={{textAlign: "center"}}>
                    <Typography variant="overline">{title}</Typography>
                </Grid>
            </Grid>
        </ColourPaper>
    );
}