import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import DropdownViewModel from '../ViewModels/Utilities/DropdownViewModel';
import Bugsnag from '@bugsnag/js';

interface IProps {
    year: number;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: boolean;
}

export default function YearDropdown(props: IProps) {
    const { year, onChange, label } = props;
    const [items, setItems] = React.useState<DropdownViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        const token = await authService.getAccessToken();
        setLoaded(false);

        fetch(`Campaign/GetCampaignYears`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setItems(data);
                }
                setLoaded(true);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoaded(true);
            });
    }

    return (
        <TextField
            disabled={!loaded}
            select
            name='year'
            fullWidth
            size="small"
            variant="outlined"
            label={label ? "Campaign Year" : ""}
            value={year}
            onChange={onChange}
            InputProps={{
                startAdornment: (
                    <React.Fragment>
                        {loaded ? null : <CircularProgress size={20} />}
                    </React.Fragment>
                ),
            }}
        >
            <MenuItem key={0} value={0}><i>All Campaigns</i></MenuItem>
            {items.map(item => {
                return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>;
            })}
        </TextField>
    );
}