import * as React from 'react';
import { LinearProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import ReportMetric from './ReportMetric';
import ReportMetricsViewModel from '../ViewModels/Reports/ReportMetricsViewModel';
import authService from '../api-authorization/AuthorizeService';
import Typography from '@mui/material/Typography';
import ReportAppointmentTable from './ReportAppointmentTable';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import LocationDropdown from '../Utilities/LocationDropdown';
import ReportDonationTable from './ReportDonationTable';
import Bugsnag from '@bugsnag/js';
import YearDropdown from '../Utilities/YearDropdown';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import YellowButton from "../Utilities/YellowButton";
import ReportExceptionsTable from './ReportExceptionsTable';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '12.5%',
        },
    }),
);

export default function ReportsContainer() {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [metrics, setMetrics] = React.useState<ReportMetricsViewModel | null>(null);
    const [id, setId] = React.useState('');
    const [role, setRole] = React.useState('');
    const [locationFilter, setLocationFilter] = React.useState<number | string>(0);
    const [yearFilter, setYearFilter] = React.useState<number>(new Date().getFullYear());

    let intervalId: NodeJS.Timer;
    React.useEffect(() => {
        getUser();
        if (!intervalId) {
            intervalId = setInterval(() => {
                getMetrics();
            }, 15000);
        }
        return () => clearInterval(intervalId);
    }, [])

    React.useEffect(() => {
        getMetrics();
        return () => clearInterval(intervalId);
    }, [locationFilter, yearFilter])

    const getMetrics = async () => {
        const token = await authService.getAccessToken();

        const locationParam = locationFilter === '' ? 0 : locationFilter;

        if (token) {
            fetch(`Reports/GetReportMetrics?location=${locationParam}&year=${yearFilter > 0 ? yearFilter : ''}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setMetrics(data);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    Bugsnag.notify(error);
                    setLoading(false);
                });
        }
    }

    const getUser = async () => {
        const user = await authService.getUser();
        setId(user.sub);
        setRole(user.role);
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = isNaN(event.target.valueAsNumber) ?
            event.target.value :
            event.target.valueAsNumber;

        if (event.target.name === 'location')
            setLocationFilter(value as number);
        else
            setYearFilter(value as number);
    }

    const dashboardLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/" ref={ref} {...itemProps} />
    ));

    return (loading ?
        <LinearProgress color="primary" />
        :
        <Grid container spacing={2}>
            {(role === "Admin" || role === "SystemAdministrator") && metrics &&
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h4">Campaign Totals</Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <YearDropdown year={yearFilter} onChange={onChange} label />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LocationDropdown location={locationFilter} onChange={onChange} editable label includeAllOfDublin />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.root}>
                            <ReportMetric value={metrics.feesPaid.toFixed(2)} title="Total Fees Paid" inputAdornment="€" topborder={locationFilter !== '' ? "fail" : undefined} />
                        </Grid>
                        <Grid item className={classes.root}>
                            <ReportMetric value={metrics.donations.toFixed(2)} title="Total Donations" inputAdornment="€" topborder={locationFilter !== '' ? "fail" : undefined} />
                        </Grid>
                        <Grid item className={classes.root}>
                            <ReportMetric value={metrics.fundsRaised.toFixed(2)} title="Total Funds Raised" inputAdornment="€" topborder={locationFilter !== '' ? "fail" : undefined} />
                        </Grid>
                        <Grid item className={classes.root}>
                            <ReportMetric value={metrics.target} title="Campaign Target" inputAdornment="€" topborder={locationFilter !== '' ? "fail" : undefined} />
                        </Grid>
                        <Grid item className={classes.root}>
                            <ReportMetric value={metrics.percentTargetRaised} title="Of Target Amount Raised" endAdornment="%" topborder={locationFilter !== '' ? "fail" : undefined} />
                        </Grid>
                        <Grid item className={classes.root}>
                            <ReportMetric value={metrics.availableSlots} title="Available Slots Remaining" topborder={locationFilter !== '' ? "success" : undefined} />
                        </Grid>
                        <Grid item className={classes.root}>
                            <ReportMetric value={metrics.bookedSlots} title="Total Booked Slots" topborder={locationFilter !== '' ? "success" : undefined} />
                        </Grid>
                        <Grid item className={classes.root}>
                            <ReportMetric value={metrics.onlineSlotsCreated + "/" + metrics.inPersonSlotsCreated + "/" + metrics.bothSlotsCreated} title="Online/In-person/Both Slots Created" topborder={locationFilter !== '' ? "success" : undefined} />
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h4">Booked Appointment Report</Typography>
                    </Grid>
                    {role === "Organisation" &&
                        <Grid item>
                            <YellowButton fullWidth variant="contained" component={dashboardLink}>Dashboard</YellowButton>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <ReportAppointmentTable role={role} id={id} />
                    </Grid>
                    {(role === "Admin" || role === "SystemAdministrator") &&
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="h4">Payments Report</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ReportDonationTable />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">Purchases Report</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ReportExceptionsTable />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}