import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import authService from '../api-authorization/AuthorizeService';
import ArchitectViewModel from '../ViewModels/Architects/ArchitectViewModel';
import { AlertContext } from '../Contexts/AlertContext';
import SquareButton from '../Utilities/SquareButton';
import YellowButton from '../Utilities/YellowButton';
import DialogTitle from '@mui/material/DialogTitle';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(0)
        },
    })
);

interface IProps {
    architect: ArchitectViewModel;
    open: boolean;
    close: (refresh: boolean) => void;
}

export default function EditArchitectDialog(props: IProps) {
    const classes = useStyles();
    const { architect, open, close } = props;
    const { show } = React.useContext(AlertContext);
    const [newArchitect, setNewArchitect] = React.useState(architect);
    const [confirmEmail, setConfirmEmail] = React.useState(architect.email);
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        setNewArchitect(architect);
    }, [architect]);

    const save = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`Architect/EditArchitect`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(newArchitect)
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    setNewArchitect(architect);
                    setConfirmEmail('');
                    close(true);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewArchitect({
            ...newArchitect,
            [event.target.name]: event.target.name === 'conservation' || event.target.name === 'mailChimp' ? event.target.checked : event.target.value
        });
    }

    const cancel = () => {
        setNewArchitect(architect);
        setConfirmEmail('');
        close(false);
    }

    ValidatorForm.addValidationRule('isMatch', (value) => {
        if (newArchitect.email !== value) {
            return false;
        }

        return true;
    });

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={cancel}
            PaperProps={{ className: classes.paper, square: true }}
        >
            <DialogTitle>Edit Architect</DialogTitle>
            <ValidatorForm onSubmit={save}>
                <DialogContent sx={{ paddingTop: 0 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="First Name"
                                name="firstName"
                                value={newArchitect.firstName}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Last Name"
                                name="lastName"
                                value={newArchitect.lastName}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Email"
                                name="email"
                                value={newArchitect.email}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Confirm Email"
                                name="confirmEmail"
                                value={confirmEmail}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmEmail(event.target.value)}
                                validators={['required', 'isEmail', 'isMatch']}
                                errorMessages={['This field is required', 'Invalid Email', 'Emails do not match']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={newArchitect.conservation} name="conservation" onChange={onChange} />} label="Conservation" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="mailChimp" checked={newArchitect.mailChimp} onChange={onChange} />} label="Receive Notifications" />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={cancel} variant="outlined" color="error" disabled={saving}>Cancel</SquareButton>
                    <YellowButton variant="contained" type="submit" disabled={saving}>Update</YellowButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}