import * as React from 'react';
import Grid from '@mui/material/Grid';
import DatePicker from '../Utilities/DatePicker';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import OrganisationListViewModel from '../ViewModels/Booking/OrganisationListViewModel';
import authService from '../api-authorization/AuthorizeService';
import OrganisationList from './OrganisationList';
import OrganisationInfo from './OrganisationInfo';
import LinearProgress from '@mui/material/LinearProgress';
import Collapse from '@mui/material/Collapse';
import ArchitectList from './ArchitectList';
import LocationDropdown from '../Utilities/LocationDropdown';
import { Theme, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import YellowButton from '../Utilities/YellowButton';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import WarningDialog from '../Utilities/WarningDialog';
import Bugsnag from '@bugsnag/js';
const moment = require('moment');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            '& .MuiCollapse-wrapperInner': {
                width: '100%'
            }
        }
    })
);

interface IProps {
    oldAppointmentId?: number;
    id?: string;
}

interface RouteProps {
    showWarning?: string;
}

export default function BookingContainer(props: IProps) {
    const history = useHistory();
    const Location = useLocation<IProps>();
    const { oldAppointmentId, id } = Location.state || props || {
        oldAppointmentId: undefined,
        id: undefined
    };
    const { showWarning } = useParams<RouteProps>();
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [date, setDate] = React.useState<Date | null>(null);
    const [search, setSearch] = React.useState('');
    const [location, setLocation] = React.useState<string | number>(0);
    const [conservation, setConservation] = React.useState(false);
    const [results, setResults] = React.useState<OrganisationListViewModel[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState('');
    const [online, setOnline] = React.useState(true);
    const [inperson, setInperson] = React.useState(true);
    const [openWarningDialog, setOpenWarningDialog] = React.useState(showWarning && showWarning === "true" ? true : false);
    const [canBook, setCanBook] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [campaignStartDate, setCampaignStartDate] = React.useState<Date | null>(null);
    const [campaignEndDate, setCampaignEndDate] = React.useState<Date | null>(null);

    React.useEffect(() => {
        getCanBook();
    }, []);

    React.useEffect(() => {
        if (moment(date, 'DD/MM/YYYY', true).isValid() || date === null) {
            getResults();
        }
    }, [date, conservation, online, inperson, location]);

    React.useEffect(() => {
        setSelectedIndex('');
    }, [results]);

    const getCanBook = async () => {
        const token = await authService.getAccessToken();

        if (token) {
            fetch(`Campaign/GetCanBookSlots`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.json())
                .then((data) => {
                    if (data) {
                        setCanBook(data);
                    }
                }).catch((error) => {
                    Bugsnag.notify(error);
                });

            fetch(`Campaign/GetCampaignDates`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.json())
                .then((data) => {
                    if (data) {
                        setCampaignStartDate(new Date(data.campaignStartDate));
                        setCampaignEndDate(new Date(data.campaignEndDate));
                    }
                }).catch((error) => {
                    Bugsnag.notify(error);
                });
        }
    }

    const toggleWarningDialog = () => {
        setOpenWarningDialog((prev) => !prev);
    }

    const getResults = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        const dateParam = date ? '&date=' + encodeURIComponent(date?.toISOString()) : '';
        const locationParam = location === '' ? 0 : location;

        fetch(`Organisation/GetOrganisationAvailability?conservation=${conservation}&online=${online}&inperson=${inperson}&location=${locationParam}&search=${search}${dateParam}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                if (data) {
                    setResults(data.groupedList);
                    setCount(data.count);
                }
                setLoading(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const onDateChange = (newValue: Date | null, name: string) => {
        setDate(newValue);
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (event.target.name) {
            case "search":
                setSearch(event.target.value);
                break;
            case "location":
                setLocation(event.target.value);
                break;
            default:
                break;
        };
    }

    const onCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'online':
                setOnline(event.target.checked);
                break;
            case 'inperson':
                setInperson(event.target.checked);
                break;
            default:
                setConservation(event.target.checked);
                break;
        }
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            getResults();
        }
    }

    const selectOrganisation = (id: string) => {
        if (id == selectedIndex) setSelectedIndex('');
        else setSelectedIndex(id);
    }

    const clearSearch = async () => {
        setDate(null);
        setSearch('');
        setLocation('');
        setConservation(false);
        setOnline(true);
        setInperson(true);

        setLoading(true);
        const token = await authService.getAccessToken();

        fetch(`Organisation/GetOrganisationAvailability?conservation${false}=&online=${true}&inperson=${true}&location=0&search=`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data) => {
                setResults(data.groupedList);
                setCount(data.count);
                setLoading(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    return (
        <Stack spacing={1}>
            {!canBook &&
                <Alert severity="info">Booking of appointments is currently unavailable. Please check back later.</Alert>
            }
            {oldAppointmentId !== undefined && oldAppointmentId > 0 &&
                <Stack direction="row" spacing={1}>
                    <Alert severity="warning">You are currently rescheduling your cancelled appointment</Alert>
                    <Button onClick={history.goBack} startIcon={<ArrowBackIcon />}>Back</Button>
                </Stack>
            }
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} lg={2}>
                    {campaignStartDate && campaignEndDate &&
                        <DatePicker
                            date={date}
                            name="date"
                            disablePast={true}
                            disableFuture={false}
                            onChange={onDateChange}
                            disabled={false}
                            size="small"
                            clearable
                            label="Date"
                            maxDate={campaignEndDate}
                            minDate={campaignStartDate}
                        />
                    }
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                    <LocationDropdown location={location} onChange={onChange} editable label includeAllOfDublin />
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={onChange}
                        name="search"
                        value={search}
                        placeholder="Search..."
                        onKeyDown={keyPress}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={conservation}
                                onChange={onCheckedChange}
                                name="conservation"
                            />
                        }
                        label="Conservation"
                        componentsProps={{
                            typography: {
                                alignItems: "center"
                            }
                        }}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={online}
                                onChange={onCheckedChange}
                                name="online"
                            />
                        }
                        label="Online Meetings"
                        componentsProps={{
                            typography: {
                                alignItems: "center"
                            }
                        }}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={inperson}
                                onChange={onCheckedChange}
                                name="inperson"
                            />
                        }
                        label="In Person Meetings"
                        componentsProps={{
                            typography: {
                                alignItems: "center"
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <YellowButton fullWidth variant="contained" onClick={clearSearch}>Clear Search Criteria</YellowButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <YellowButton fullWidth variant="contained" onClick={getResults}>Search</YellowButton>
                </Grid>
            </Grid>
            {loading && <LinearProgress />}
            {count <= 0 ?
                <Grid container justifyContent="center">
                    <Grid item>
                        <Typography variant="h4">No organisations found.</Typography>
                    </Grid>
                </Grid>
                :
                <Grid container alignItems="flex-start">
                    <Grid item md={6} xs={12} sx={!smallScreen ? { paddingRight: 1 } : selectedIndex.length === 0 ? {} : { height: 0 }}>
                        <Slide direction="right" in={!smallScreen || (smallScreen && selectedIndex.length === 0)}>
                            <Collapse in={!loading && results && results.length > 0} className={classes.list} sx={smallScreen ? { height: 0 } : {}}>
                                <Typography variant="h6" gutterBottom>Select a Practice</Typography>
                                <OrganisationList list={results} selectOrganisation={selectOrganisation} selectedId={selectedIndex} />
                            </Collapse>
                        </Slide>
                    </Grid>
                    <Grid item md={6} xs={12} sx={!smallScreen ? { paddingLeft: 1 } : selectedIndex.length > 0 ? {} : { width: 0, height: 0 }}>
                        {smallScreen ?
                            <Slide direction="left" in={selectedIndex.length > 0}>
                                <Stack spacing={2} alignItems="stretch" sx={selectedIndex.length > 0 ? {} : { display: 'none' }}>
                                    {smallScreen && selectedIndex.length > 0 && <YellowButton variant="contained" onClick={() => setSelectedIndex('')}>Back to Results</YellowButton>}
                                    <OrganisationInfo selectedId={selectedIndex} />
                                    <Grid container spacing={1} justifyContent="center" sx={{ paddingTop: "8px" }}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">Key:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Chip sx={{ minWidth: '100%', backgroundColor: '#00AEEF', color: 'white' }} label="Online" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Chip sx={{ minWidth: '100%', backgroundColor: '#5EC4B6', color: 'white' }} label="In Person" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Chip sx={{ minWidth: '100%', backgroundColor: '#f37360', color: 'white' }} label="Both" />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h6">Select an Architect</Typography>
                                    <ArchitectList canBook={canBook} selectedId={selectedIndex} online={online} inperson={inperson} conservation={conservation} date={date} oldAppointmentId={oldAppointmentId} id={id} />
                                </Stack>
                            </Slide> :
                            <Collapse in={selectedIndex.length > 0} orientation="horizontal" className={classes.list}>
                                <Stack spacing={2} alignItems="stretch">
                                    <OrganisationInfo selectedId={selectedIndex} />
                                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={4} sx={{ paddingTop: "8px" }}>
                                        <Typography variant="body2">Key:</Typography>
                                        <Stack direction="column" justifyContent="center" alignItems="center">
                                            <Chip sx={{ width: '20px', height: '20px', backgroundColor: '#00AEEF', color: 'white' }} />
                                            <Typography variant="body2">Online</Typography>
                                        </Stack>
                                        <Stack direction="column" justifyContent="center" alignItems="center">
                                            <Chip sx={{ width: '20px', height: '20px', backgroundColor: '#5EC4B6', color: 'white' }} />
                                            <Typography variant="body2">In Person</Typography>
                                        </Stack>
                                        <Stack direction="column" justifyContent="center" alignItems="center">
                                            <Chip sx={{ width: '20px', height: '20px', backgroundColor: '#f37360', color: 'white' }} />
                                            <Typography variant="body2">Both</Typography>
                                        </Stack>
                                    </Stack>
                                    <Typography variant="h6">Select an Architect</Typography>
                                    <ArchitectList canBook={canBook} selectedId={selectedIndex} online={online} inperson={inperson} conservation={conservation} date={date} oldAppointmentId={oldAppointmentId} id={id} />
                                </Stack>
                            </Collapse>
                        }
                    </Grid>
                </Grid>
            }
            <WarningDialog title={"Attention!"} text={"The time limit for completing your booking has expired. Please start a new booking."} buttonText={"Close"} open={openWarningDialog} close={toggleWarningDialog} okClick={toggleWarningDialog} showCancel={false} />
        </Stack >
    );
}