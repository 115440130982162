export default class CreateUserViewModel {
    public email: string = '';
    public streetAddress1: string = '';
    public streetAddress2: string = '';
    public streetAddress3: string = '';
    public city: string = '';
    public province: string = '';
    public postalCode: string = '';
    public mailChimpId: number | null = null;
}

export class CreateClientUserViewModel extends CreateUserViewModel {
    public firstName: string = '';
    public lastName: string = '';
    public title: number | null = null;
}

export class CreateOrganisationUserViewModel extends CreateUserViewModel {
    public organisationName: string = '';
    public locationId: number = 0;
}

export class CreateSystemAdministratorUserViewModel extends CreateUserViewModel {
    public firstName: string = '';
    public lastName: string = '';
}