import * as React from 'react';
import ColourPaper from '../Utilities/ColourPaper';
import { OrganisationProfileViewModel } from '../ViewModels/Profile/ProfileViewModel';
import authService from '../api-authorization/AuthorizeService';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import YellowButton from '../Utilities/YellowButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WebIcon from '@mui/icons-material/Language';
import BusinessIcon from '@mui/icons-material/Business';
import Stack from '@mui/material/Stack';
import Bugsnag from '@bugsnag/js';

interface IProps {
    selectedId: string;
}

export default function OrganisationInfo(props: IProps) {
    const { selectedId } = props;
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [org, setOrg] = React.useState<OrganisationProfileViewModel>(new OrganisationProfileViewModel);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (selectedId) {
            getDetails();
        }
    }, [selectedId]);

    const getDetails = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        let url = `User/GetProfileDetails?id=${selectedId}`;

        fetch(url, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setOrg(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const siteLink = org.url ? org.url.startsWith('http') ? org.url : '//' + org.url : '';

    return (
        <ColourPaper>
            {loading && <CircularProgress />}
            <Collapse in={!loading}>
                <Typography variant="h6" gutterBottom noWrap={!smallScreen}>{org.organisationName}</Typography>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12} sm={6}>
                        {org.url &&
                            <Stack direction="row" spacing={1}>
                                <WebIcon />
                                <Typography variant="body2" gutterBottom><a target="_blank" href={siteLink}>{org.url}</a></Typography>
                            </Stack>
                        }
                        <YellowButton notextwrap={!smallScreen} sx={{ marginTop: 1 }} variant="contained" target="_blank" href={`https://www.google.com/maps/search/${org.postalCode}+Ireland`}>View On Google Maps</YellowButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack direction="row" spacing={1}>
                            <BusinessIcon />
                            <Stack>
                                <Typography variant="body2">{org.streetAddress1}</Typography>
                                {org.streetAddress2 && <Typography variant="body2">{org.streetAddress2}</Typography>}
                                {org.streetAddress3 && <Typography variant="body2">{org.streetAddress3}</Typography>}
                                <Typography variant="body2">{org.city}</Typography>
                                <Typography variant="body2">{org.province}</Typography>
                                <Typography variant="body2">{org.postalCode}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Collapse>
        </ColourPaper>
    );
}