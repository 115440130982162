import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import DropdownViewModel from '../ViewModels/Utilities/DropdownViewModel';
import Bugsnag from '@bugsnag/js';

interface IProps {
    location: number | string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    editable: boolean;
    alignRight?: boolean;
    label?: boolean;
    includeAllOfDublin?: boolean;
}

export default function LocationDropdown(props: IProps) {
    const { location, onChange, editable, alignRight, label, includeAllOfDublin } = props;
    const [items, setItems] = React.useState<DropdownViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        const token = await authService.getAccessToken();
        setLoaded(false);

        fetch(`User/GetLocations`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setItems(data);
                }
                setLoaded(true);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoaded(true);
            });
    }

    return (
        <TextField
            disabled={!editable || !loaded}
            select
            name='location'
            fullWidth
            size="small"
            variant="outlined"
            label={label ? "Location" : ""}
            value={location}
            onChange={onChange}
            InputProps={{
                style: {
                    textAlign: alignRight ? 'right' : 'start'
                },
                startAdornment: (
                    <React.Fragment>
                        {loaded ? null : <CircularProgress size={20} />}
                    </React.Fragment>
                ),
            }}
        >
            <MenuItem key={0} value={0}><i>All Locations</i></MenuItem>
            {includeAllOfDublin && <MenuItem key={-1} value={-1}><i>All of Dublin</i></MenuItem>}
            {items.map(item => {
                return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>;
            })}
        </TextField>
    );
}