import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import ExceptionsReportViewModel from '../ViewModels/Reports/ExceptionsReportViewModel';

interface IProps {
    row: ExceptionsReportViewModel;
}

export default function ReportExceptionsTableRow(props: IProps) {
    const { row } = props;

    return (
        <TableRow>
            <TableCell>{row.orderId}</TableCell>
            <TableCell>{row.slotDate && moment(row.slotDate).format("D/M/YYYY h:mm A")}</TableCell>
            <TableCell>{row.isTimeslotArchived ? 'Yes' : 'No'}</TableCell>
            <TableCell>{row.timeSlotStatus}</TableCell>
            <TableCell>{row.isAppointmentArchived ? 'Yes' : 'No'}</TableCell>
            <TableCell>{row.outcome}</TableCell>
            <TableCell>{row.confirmRefunded ? 'Yes' : 'No'}</TableCell>
            <TableCell>{row.purchaseStarted && moment(row.purchaseStarted).format("D/M/YYYY")}</TableCell>
            <TableCell>{row.purchaseCompleted && moment(row.purchaseCompleted).format("D/M/YYYY")}</TableCell>
            <TableCell>{row.responseMessage}</TableCell>
            <TableCell>{row.amount}</TableCell>
            <TableCell>{row.realexTransationId}</TableCell>
            <TableCell>{row.purchaseStatus}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.phoneNumber}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.lockedBy}</TableCell>
            <TableCell>{row.architectsName}</TableCell>
        </TableRow>
    );
}