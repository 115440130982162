import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

interface IProps {
    errorReturn: (error: string) => void;
}

export default function Payment(props: IProps) {
    const { errorReturn } = props;
    const error = 'This is a test error';
    const orderId = 'OrderId';

    const successLink = React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link to={{
            pathname: '/PaymentSuccess',
            state: {
                orderId: orderId
            }
        }}
            ref={ref} {...itemProps} />
    ));

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Button variant="outlined" color="error" onClick={() => { errorReturn(error) }}>Return w/ Fail</Button>
            </Grid>
            <Grid item xs={6}>
                <Button variant="outlined" color="success" component={successLink}>Return w/ Success</Button>
            </Grid>
        </Grid>
    );
}