import * as React from 'react';
import Grid from '@mui/material/Grid';
import SystemAdminsTable from '../Users/System Admins/SystemAdminsTable';
import OrganisationsTable from '../Users/Organisations/OrganisationsTable';
import ClientsTable from '../Users/Clients/ClientsTable';
import CampaignSettings from '../CampaignSettings/CampaignSettings';
import AdminFunctions from './AdminFunctions';

export default function AdminContainer() {
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <CampaignSettings />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <AdminFunctions />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <SystemAdminsTable />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <OrganisationsTable />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ClientsTable />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}