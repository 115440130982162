import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.light,
                backgroundColor: 'transparent'
            },
        },
    }));

export default function SearchButton(props: IconButtonProps) {
    const classes = useStyles();

    return (
        <IconButton {...props} disableRipple disableFocusRipple className={classes.root}><SearchIcon /></IconButton>
    );
}