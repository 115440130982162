import * as React from 'react';
import { AppointmentPaymentViewModel } from '../ViewModels/Payments/PaymentViewModel';
import DropdownViewModel from '../ViewModels/Utilities/DropdownViewModel';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Typography from '@mui/material/Typography';
import { AlertContext } from '../Contexts/AlertContext';
import SquareButton from '../Utilities/SquareButton';
import YellowButton from '../Utilities/YellowButton';
import authService from '../api-authorization/AuthorizeService';
import Alert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Bugsnag from '@bugsnag/js';

interface IProps {
	appointmentId: number;
	next: () => void;
	close: (refresh: boolean) => void;
	donation: number;
	paymentErrorId?: string;
	back: () => void;
}

export default function BillingDetails(props: IProps) {
	const { appointmentId, next, close, donation, paymentErrorId, back } = props;
	const { show } = React.useContext(AlertContext);
	const [loading, setLoading] = React.useState(true);
	const [saving, setSaving] = React.useState(false);
	const [countryCodes, setCountryCodes] = React.useState<DropdownViewModel[]>([]);
	const [info, setInfo] = React.useState<AppointmentPaymentViewModel>(new AppointmentPaymentViewModel(appointmentId, donation));
	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	ValidatorForm.addValidationRule("notZero", (value: number) => {
		if (value > 0) {
			return true;
		} return false;
	});

	ValidatorForm.addValidationRule("phoneNumber", (value) => {
		if (value.match(/(^[^+-])([0-9]{8,11})+/g)) return true;
		return false;
	})

	React.useEffect(() => {
		getCountryCodes();
		getInfo();
	}, []);

	React.useEffect(() => {
		getInfo();
	}, [appointmentId, paymentErrorId]);

	React.useEffect(() => {
		if (countryCodes.length > 0)
			setLoading(false);
	}, [countryCodes]);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = isNaN(event.target.valueAsNumber) ?
			event.target.value :
			event.target.valueAsNumber;

		if (event.target.name === 'postalCode') {
			value = (value as string).toUpperCase();
		}

		setInfo({
			...info,
			[event.target.name]: value
		});
	}

	const getCountryCodes = async () => {
		const token = await authService.getAccessToken();
		setLoading(true);

		fetch(`api/Payments/GetCountryCodes`, {
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		})
			.then(response => response.json())
			.then((data) => {
				setCountryCodes(data);
			})
			.catch((error) => {
				Bugsnag.notify(error);
				setLoading(false);
			});
	}

	const getInfo = async () => {
		const token = await authService.getAccessToken();
		setLoading(true);
		if (appointmentId) {
			fetch(`api/Payments/GetExistingPurchase?appointId=${appointmentId}`, {
				headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
			})
				.then(response => response?.json())
				.then((data) => {
					if (data && data !== true) {
						setInfo(data);
					}
					else if (data && data === true) {
						show('error', "Someone else managed to book this appointment before you");
						cancel();
					}
					else {
						setInfo(new AppointmentPaymentViewModel(appointmentId, donation));
					}
				})
				.catch((error) => {
					Bugsnag.notify(error);
					setLoading(false);
				});
		} else {
			setInfo(new AppointmentPaymentViewModel(appointmentId, donation));
		}
	}

	const save = async () => {
		const token = await authService.getAccessToken();
		setSaving(true);

		const infoCopy = { ...info };
		let postcode = infoCopy.postalCode.replace(/\s/g, "");
		postcode = postcode.substring(0, 3) + " " + postcode.substring(3);
		infoCopy.postalCode = postcode;

		const url = !paymentErrorId ? 'Appointment/AddBillingDetails' : 'Appointment/UpdateBillingDetails';

		fetch(url, {
			method: 'POST',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
			body: JSON.stringify(infoCopy)
		})
			.then(response => response.text())
			.then(data => {
				setSaving(false);

				if (data.length > 0) {
					show('error', data);
				} else {
					setInfo(new AppointmentPaymentViewModel(appointmentId, donation));
					next();
				}
			})
			.catch(error => {
				Bugsnag.notify(error);
				setSaving(false);
			});
	}

	const cancel = async () => {
		const token = await authService.getAccessToken();
		setSaving(true);

		fetch(`Appointment/CancelAddAppointment?appointmentId=${appointmentId}`, {
			method: 'POST',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		})
			.then(response => response.text())
			.then(data => {
				if (data.length > 0) {
					show('error', data);
					setSaving(false);
				} else {
					setSaving(false);
					setInfo(new AppointmentPaymentViewModel(appointmentId, donation));
					close(false);
				}
			})
			.catch(error => {
				Bugsnag.notify(error);
				setSaving(false);
			});
	}

	ValidatorForm.addValidationRule('eircode', (value: string) => {
		if (value.match(/^[a-zA-Z0-9 ]*$/) && value.replace(/\s/g, "").length === 7)
			return true;

		if (value.match(/^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/))
			return true;

		return false;
	});

	return (
		<ValidatorForm onSubmit={save}>
			<Grid container spacing={1} justifyContent="center">
				{!smallScreen &&
					<Grid item xs={12}>
						<Typography variant="h5">Billing Details</Typography>
					</Grid>
				}
				{info.error.length > 0 &&
					<Grid item xs={12}>
						<Alert severity="error">{info.error}</Alert>
					</Grid>
				}
				<Grid item xs={12}>
					<TextValidator
						fullWidth
						size="small"
						margin="dense"
						variant="outlined"
						label="Street Address 1*"
						name="streetAddress1"
						value={info.streetAddress1}
						onChange={onChange}
						validators={['required']}
						errorMessages={['This field is required']}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextValidator
						fullWidth
						size="small"
						margin="dense"
						variant="outlined"
						label="Street Address 2"
						name="streetAddress2"
						value={info.streetAddress2}
						onChange={onChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextValidator
						fullWidth
						size="small"
						margin="dense"
						variant="outlined"
						label="Street Address 3"
						name="streetAddress3"
						value={info.streetAddress3}
						onChange={onChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextValidator
						fullWidth
						size="small"
						margin="dense"
						variant="outlined"
						label="Town*"
						name="city"
						value={info.city}
						onChange={onChange}
						validators={['required']}
						errorMessages={['This field is required']}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextValidator
						fullWidth
						size="small"
						margin="dense"
						variant="outlined"
						label="County*"
						name="province"
						value={info.province}
						onChange={onChange}
						validators={['required']}
						errorMessages={['This field is required']}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextValidator
						fullWidth
						size="small"
						margin="dense"
						variant="outlined"
						label="Eircode*"
						name="postalCode"
						value={info.postalCode}
						onChange={onChange}
						validators={['required', 'eircode']}
						errorMessages={['This field is required', 'Invalid eircode']}
						helperText="Please enter the Eircode in XXX XXXX format (with a space)."
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<SelectValidator
						select
						size="small"
						margin="dense"
						name='countryCode'
						fullWidth
						variant="outlined"
						value={info.countryCode !== '' ? info.countryCode : ''}
						onChange={onChange}
						InputProps={{
							disabled: loading,
							startAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress size={20} /> : null}
								</React.Fragment>
							)
						}}
						validators={['required']}
						errorMessages={['This field is required']}
					>
						{countryCodes && countryCodes.map(item =>
							<MenuItem key={item.key} value={item.key}>{item.value + " (+" + item.key + ")"}</MenuItem>
						)}
					</SelectValidator>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextValidator
						fullWidth
						size="small"
						margin="dense"
						variant="outlined"
						label="Contact Number*"
						name="phoneNumber"
						value={info.phoneNumber}
						onChange={onChange}
						validators={['required', 'phoneNumber']}
						errorMessages={['This field is required', 'Invalid phone number']}
					/>
					<FormHelperText>Do not enter any extra characters, leading zeros, spaces, and country codes into your phone number.  For example, "+ 353 (0)1 676 1703" should be entered as "16761703".</FormHelperText>
					<FormHelperText>Must match the phone number on the account for the credit card that is being used.</FormHelperText>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={4}>
							<SquareButton fullWidth onClick={cancel} variant="outlined" color="error" disabled={saving}>Cancel</SquareButton>
						</Grid>
						<Grid item xs={12} md={4}>
							<SquareButton fullWidth onClick={back} variant="outlined" color="error" disabled={saving}>Back</SquareButton>
						</Grid>
						<Grid item xs={12} md={4}>
							<YellowButton fullWidth type="submit" variant="contained" color="primary" disabled={saving}>Next</YellowButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ValidatorForm>
	);
}