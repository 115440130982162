import * as React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import 'moment-timezone';

export default class TimeSlotValidator extends ValidatorComponent {

    renderValidatorComponent() {
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            onChange,
            ...rest
        } = this.props;
        const { isValid } = this.state;

        moment.tz.setDefault('UTC');

        return (
            <div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        {...rest}
                        renderInput={(params) => <TextField fullWidth {...params} helperText={(!isValid && this.getErrorMessage()) || helperText}/>}
                        shouldDisableTime={() => !isValid || error}
                        onChange={onChange}
                        mask="__:__"
                    />
                </LocalizationProvider>
            </div>
        );
    }
}