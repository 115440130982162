import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import { SmallTableCell } from '../Utilities/ResponsiveTable';
import YellowButton from '../Utilities/YellowButton';
import RefundsListViewModel from '../ViewModels/Refunds/RefundsListViewModel';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    row: RefundsListViewModel;
    refunds: boolean;
    saving: boolean;
    refund: (id: number, base: number, donation: number) => void;
    retain: (id: number) => void;
}

export default function RefundsTableRow(props: IProps) {
    const { row, refunds, saving, refund, retain } = props;

    const clickRefund = () => {
        refund(row.id, row.baseAmount, row.donationAmount);
    }

    const clickRetain = () => {
        retain(row.id);
    }

    return (
        <TableRow>
            <SmallTableCell>{row.firstName}</SmallTableCell>
            <SmallTableCell>{row.lastName}</SmallTableCell>
            <SmallTableCell>{row.slotDate && new Date(row.slotDate).toLocaleDateString('en-Gb')}</SmallTableCell>
            <SmallTableCell>{row.orderId}</SmallTableCell>
            <SmallTableCell>{row.cancellationDate && new Date(row.cancellationDate).toLocaleDateString('en-Gb')}</SmallTableCell>
            {row.outcome === 1 &&
                <SmallTableCell>Client Cancel</SmallTableCell>
            }
            {row.outcome === 2 &&
                <SmallTableCell>Architect Cancel</SmallTableCell>
            }
            <SmallTableCell align="right">€{row.baseAmount}</SmallTableCell>
            <SmallTableCell align="right">€{row.donationAmount}</SmallTableCell>
            {!refunds && <SmallTableCell align="right">€{row.refundAmount}</SmallTableCell>}
            <SmallTableCell align="right">{row.baseAmount === 0 ? <DoneIcon color="success" /> : <CloseIcon color="error" /> }</SmallTableCell>
            <SmallTableCell align="right">
                <YellowButton disabled={saving} onClick={refunds ? clickRefund : clickRetain}>{refunds ? "Mark as Refunded" : "Mark as Pending"}</YellowButton>
            </SmallTableCell>
        </TableRow>
    );
}