import * as React from 'react';
import ReportAppointmentListViewModel from '../ViewModels/Reports/ReportAppointmentListViewModel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import SquareButton from '../Utilities/SquareButton';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

interface IProps {
    row: ReportAppointmentListViewModel;
    open: boolean;
    onClose: () => void;
}

export default function AppointmentRowDialog(props: IProps) {
    const { row, open, onClose } = props;

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={onClose}
            PaperProps={{ square: true }}
        >
            <DialogTitle>View Appointment Details</DialogTitle>
            <DialogContent sx={{ paddingTop: 0 }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="body2">No details can be edited from this dialog. Please go to the admin dashboard and manage the clients booking to get to their dashboard.</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Appointment Details</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink>Architect Name</InputLabel>
                                <TextField
                                    id="architect"
                                    name="architect"
                                    disabled
                                    value={row.architect}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink>Practice Name</InputLabel>
                                <TextField
                                    id="organisation"
                                    name="organisation"
                                    disabled
                                    value={row.organisation}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink>Member ID</InputLabel>
                                <TextField
                                    id="memberId"
                                    name="memberId"
                                    disabled
                                    value={row.memberId}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink>Architect Email</InputLabel>
                                <TextField
                                    id="architectEmail"
                                    name="architectEmail"
                                    disabled
                                    value={row.architectEmail}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink>Architect Phone Number</InputLabel>
                                <TextField
                                    id="architectPhone"
                                    name="architectPhone"
                                    disabled
                                    value={row.architectPhone}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel shrink>Date</InputLabel>
                                        <TextField
                                            id="date"
                                            name="date"
                                            disabled
                                            value={row.date && new Date(row.date).toLocaleDateString()}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel shrink>Time</InputLabel>
                                        <TextField
                                            id="time"
                                            name="time"
                                            disabled
                                            value={row.time}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink>Meeting Preference</InputLabel>
                                <TextField
                                    id="isOnlineMeeting"
                                    name="isOnlineMeeting"
                                    disabled
                                    value={row.isOnlineMeeting ? "Online" : "In-Person"}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Client Details</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel shrink>Client Forename</InputLabel>
                                        <TextField
                                            id="firstName"
                                            name="firstName"
                                            disabled
                                            value={row.firstName}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel shrink>Client Surname</InputLabel>
                                        <TextField
                                            id="lastName"
                                            name="lastName"
                                            disabled
                                            value={row.lastName}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink>Phone Number</InputLabel>
                                <TextField
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    disabled
                                    value={row.phoneNumber}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink>Email</InputLabel>
                                <TextField
                                    id="email"
                                    name="email"
                                    disabled
                                    value={row.email}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            {row.isOnlineMeeting &&
                                <Grid item xs={12}>
                                    <InputLabel shrink>Meeting Link</InputLabel>
                                    <TextField
                                        id="meetingLink"
                                        name="meetingLink"
                                        disabled
                                        value={row.meetingLink}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            }
                            {!row.isOnlineMeeting &&
                                <Grid item xs={12}>
                                    <InputLabel shrink>Address</InputLabel>
                                    <TextField
                                        id="address"
                                        name="address"
                                        disabled
                                        value={row.address}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            }
                            {!row.isOnlineMeeting &&
                                <Grid item xs={12}>
                                    <InputLabel shrink>Eircode</InputLabel>
                                    <TextField
                                        id="eirCode"
                                        name="eirCode"
                                        disabled
                                        value={row.eirCode}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <InputLabel shrink>Outcome</InputLabel>
                                <TextField
                                    id="outcome"
                                    name="outcome"
                                    disabled
                                    value={row.outcome === 0 ? "Booked" : row.outcome === 1 ? "Client Refund" : row.outcome === 2 ? "Full Refund" : row.outcome === 3 ? "Rescheduled" : ""}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink>Project Description</InputLabel>
                                <TextField
                                    id="projectDescription"
                                    name="projectDescription"
                                    disabled
                                    value={row.projectDescription}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SquareButton onClick={onClose} variant="outlined" color="error">Close</SquareButton>
            </DialogActions>
        </Dialog>
    );
}