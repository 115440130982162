import * as React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enLocale from 'date-fns/locale/en-GB';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/en-gb';

export default class DateValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            ...rest
        } = this.props;
        const { isValid } = this.state;

        moment.tz.setDefault('UTC');
        moment.locale('en-gb');
        moment.defaultFormat = "DD-MM-YYYY HH:mm";

        return (
            <div>
                <LocalizationProvider dateAdapter={AdapterMoment} locale={'en-gb'}>
                    <DatePicker
                        {...rest}
                        renderInput={(params) => <TextField {...params} fullWidth
                            error={!isValid || error}
                            helperText={(!isValid && this.getErrorMessage()) || helperText} />
                        }
                        shouldDisableDate={() => !isValid || error}
                    />
                </LocalizationProvider>
            </div>
        );
    }
} 