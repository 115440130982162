import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ReportAppointmentListViewModel from '../ViewModels/Reports/ReportAppointmentListViewModel';
import YellowButton from '../Utilities/YellowButton';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';

interface IProps {
    row: ReportAppointmentListViewModel;
    onSelect: (id: number) => void;
}

export default function ReportAppointmentTableRow(props: IProps) {
    const { row, onSelect } = props;

    const select = () => {
        onSelect(row.id);
    }

    return (
        <TableRow key={row.id}>
            <TableCell>{row.architect}</TableCell>
            <TableCell>{row.memberId}</TableCell>
            <TableCell>{row.date && moment(row.date).format("D/M/YYYY")}</TableCell>
            <TableCell>{row.date && moment(row.date).format("h:mm A")}</TableCell>
            <TableCell>{row.firstName}</TableCell>
            <TableCell>{row.lastName}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell align="right">
                <YellowButton aria-label="view" color="primary" variant="contained" size="small" onClick={select}>
                    <SearchIcon />
                </YellowButton>
            </TableCell>
        </TableRow>
    );
}