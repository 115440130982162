import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import authService from './api-authorization/AuthorizeService';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            paddingLeft: 0,
            paddingRight: 0
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        logo: {
            marginRight: 'auto',
            height: 'inherit'
        },
        center: {
            marginRight: 'auto',
            marginLeft: 'auto'
        },
        account: {
            marginLeft: 'auto'
        },
        bar: {
            height: 64
        },
        profileButton: {
            textTransform: 'none'
        },
        profileIcon: {
            color: theme.palette.primary.main,
            marginLeft: theme.spacing(1)
        },
        barButton: {
            transition: 'all .3s ease',
            color: theme.palette.primary.main,
            marginRight: theme.spacing(2),
            borderRadius: 0,
            borderBottom: `2px solid transparent`,
            '&:hover, &:focus, &$focusVisible': {
                color: theme.palette.error.main,
                borderBottom: `2px solid ${theme.palette.error.main}`,
                backgroundColor: "inherit",
            }
        },
        activeBarButton: {
            color: theme.palette.error.main,
            borderBottom: `2px solid ${theme.palette.error.main}`,
            marginRight: theme.spacing(2),
            borderRadius: 0,
        },
        focusVisible: {
        },
        formControl: {

            margin: 'auto',
            minWidth: 120,
        },
        select: {
            borderRadius: 0,
            margin: 8
        },
        selectBox: {

            paddingLeft: 14,
            paddingTop: 14,
            paddingRight: 35,
            paddingBottom: 14,

        },
        activeLink: {
            color: theme.palette.error.main,
            borderBottom: `2px solid ${theme.palette.error.main}`,
        },
        popper: {
            top: '13px !important',
            zIndex: 1300
        },
        profilePopper: {
            zIndex: 1300,
            backgroundColor: '#f5f5f5',
            [theme.breakpoints.down('md')]: {
                top: '10px !important'
            }
        },
        menu: {
            backgroundColor: '#f5f5f5',
            borderRadius: 0
        },
        menuItem: {
            backgroundColor: '#f5f5f5',
            color: theme.palette.primary.main,
            '&:focus, &:hover': {
                backgroundColor: '#f5f5f5',
                color: theme.palette.error.main,
            },
        },
        hideWhenSmall: {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        simonLogo: {
            padding: '8px 0'
        }
    }),
);

export default function NavMenu() {
    const classes = useStyles();
    const theme = useTheme();
    const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(profileAnchorEl);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuAnchorEl);
    const [authenticated, setAuthenticated] = React.useState(false);
    const [userName, setUserName] = React.useState('');
    const [role, setRole] = React.useState('');
    const [hasAppointment, setHasAppointment] = React.useState(false);

    React.useEffect(() => {
        updateAuth();
    }, []);

    React.useEffect(() => {
        if (authenticated) getUsername();
    }, [authenticated])

    React.useEffect(() => {
        if (role && role === "Client" && authenticated) getHasAppointment();
    }, [role])

    const getHasAppointment = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        if (user) {
            fetch(`Appointment/CheckClientHasAppointment?clientId=${user.sub}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then((response) => response.json())
                .then((data) => {
                if(data){
                    setHasAppointment(data);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
            });
        }
    }

    const getUsername = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        if (user) {
            fetch(`User/GetUsername?id=${user.sub}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then((response) => response.text())
                .then((data) => {
                    if (data) {
                        setUserName(data);
                    }
            }).catch((error) => {
                Bugsnag.notify(error);
            });
        }
    }

    const updateAuth = async () => {
        const isAuth = await authService.isAuthenticated();
        setAuthenticated(isAuth);

        const user = await authService.getUser();
        if (user && user !== null) {
            setRole(user ? user.role : '');
        }
    }

    const profileLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Profile" ref={ref} {...itemProps} />
    ));

    const reportLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Reports" ref={ref} {...itemProps} />
    ));

    const dashboardLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/" ref={ref} {...itemProps} />
    ));

    const logoutLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={{
            pathname: `${ApplicationPaths.LogOut}`,
            state: { local: true }
        }} ref={ref} {...itemProps} />
    ));

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setProfileAnchorEl(event.currentTarget);
    }

    const handleProfileMenuClose = () => {
        setProfileAnchorEl(null);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const bigScreen = useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        if (bigScreen) {
            setMenuAnchorEl(null);
        }
    }, [bigScreen]);

    return (
        <div className={classes.root}>
            <AppBar position="fixed" color="default" className={classes.appBar}>
                <Toolbar disableGutters className={classes.bar}>
                    <div className={classes.logo}>
                        <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                            <Grid item>
                                <Link to="/"><img src="logo.png" alt="RIAI" height="64" /></Link>
                            </Grid>
                            <Grid item>
                                <Link to="/"><img src="simonLogo.svg" alt="Simon Open Door" height="64" className={classes.simonLogo} /></Link>
                            </Grid>
                        </Grid>
                    </div>

                    {bigScreen &&
                        <div className={classes.center}>
                            <Grid container spacing={4}>
                                <Grid item>
                                    
                                </Grid>
                            </Grid>
                        </div>
                    }
                    
                    <div className={classes.account}>
                        <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                            {bigScreen &&
                                <Grid item>
                                    <Button disabled={!authenticated} color="primary" size="large" aria-label="open account menu" className={classes.profileButton} endIcon={<AccountCircleIcon className={classes.profileIcon} />} onClick={handleProfileMenuOpen}>
                                        <Stack alignContent="flex-end">
                                            <Typography className={classes.hideWhenSmall}>{userName}</Typography>
                                            <Typography className={classes.hideWhenSmall} variant="caption" align="right">{role === "Organisation" ? "Practice" : role}</Typography>
                                        </Stack>
                                    </Button>
                                </Grid>
                            }
                            {!bigScreen &&
                                <Grid item>
                                    <IconButton color="primary" aria-label="open menu" onClick={handleMenuOpen}>
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </Toolbar>
            </AppBar>

            <Popper
                anchorEl={profileAnchorEl}
                id='menu'
                open={open}
                disablePortal
                role={undefined}
                placement="bottom-end"
                className={classes.profilePopper}
            >
                {({ TransitionProps }) => (
                    <Grow
                        timeout={350}
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'right top'
                        }}
                    >
                        <ClickAwayListener onClickAway={handleProfileMenuClose}>
                            <MenuList>
                                <MenuItem className={classes.menuItem} onClick={handleProfileMenuClose} component={profileLink}>Profile</MenuItem>
                                <MenuItem className={classes.menuItem} onClick={handleProfileMenuClose} component={dashboardLink}>{(role && role === "Client") ? hasAppointment ? "My Appointment" : "Find an Architect" : "Dashboard"}</MenuItem>
                                {role && (role === "SystemAdministrator" || role === "Admin" || role === "Organisation") && <MenuItem onClick={handleProfileMenuClose} component={reportLink} className={classes.menuItem}>{role === "Organisation" ? "Your Schedule" : "Reports"}</MenuItem>}
                                {authenticated && <MenuItem className={classes.menuItem} onClick={handleProfileMenuClose} component={logoutLink}>Logout</MenuItem>}
                            </MenuList>
                        </ClickAwayListener>
                    </Grow>
                )}
            </Popper>

            {!bigScreen &&
                <Popper
                    anchorEl={menuAnchorEl}
                    id='menu'
                    open={isMenuOpen}
                    disablePortal
                    role={undefined}
                    placement="bottom-end"
                    className={classes.profilePopper}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            timeout={350}
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'right top'
                            }}
                        >
                            <ClickAwayListener onClickAway={handleMenuClose}>
                                <MenuList>
                                    <MenuItem onClick={handleMenuClose} component={profileLink} className={classes.menuItem}>Profile</MenuItem>
                                    <MenuItem onClick={handleMenuClose} component={dashboardLink} className={classes.menuItem}>{(role && role === "Client") ? hasAppointment ? "My Appointment" : "Find an Architect" : "Dashboard"}</MenuItem>
                                    {role && (role === "SystemAdministrator" || role === "Admin" || role === "Organisation") && <MenuItem onClick={handleMenuClose} component={reportLink} className={classes.menuItem}>Reports</MenuItem>}
                                    {authenticated && <MenuItem className={classes.menuItem} component={logoutLink}>Logout</MenuItem>}
                                </MenuList>
                            </ClickAwayListener>
                        </Grow>
                    )}
                </Popper>
            }
        </div>
    );
}
