import * as React from 'react';
import authService from './api-authorization/AuthorizeService';
import Grid from '@mui/material/Grid';
import ArchitectsContainer from './Architects/ArchitectsContainer';
import { useLocation } from 'react-router-dom';
import AdminContainer from './Admin/AdminContainer';
import ClientDashboard from './Client/ClientDashboard';
import Bugsnag from '@bugsnag/js';

interface IProps {
    id: string | undefined;
}

export default function Home() {
    const location = useLocation<IProps>();
    const { id } = location.state || {
        id: undefined
    };
    const [role, setRole] = React.useState('');
    const [storedId, setStoredId] = React.useState<string | undefined>(id !== undefined ? id : undefined);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (id === undefined) {
            getUserDetails();
        }
        else {
            getUserRole();
        }
    }, []);

    React.useEffect(() => {
        if (role !== '' && storedId !== undefined) setLoading(false);
    }, [role, storedId])

    const getUserDetails = async () => {
        const user = await authService.getUser();
        if (user) {
            setRole(user.role);
            setStoredId(user.sub);
        }
    }

    const getUserRole = async () => {
        const token = await authService.getAccessToken();

        fetch(`User/GetUserType?id=${id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.text())
            .then(data => {
                setRole(data);
            })
            .catch(error => {
                Bugsnag.notify(error);
            });
    }

    return (!loading &&
        <React.Fragment>
            {role === 'Client' &&
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <ClientDashboard />
                    </Grid>
                </Grid>
            }
            {role === 'Organisation' &&
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <ArchitectsContainer orgId={storedId} />
                    </Grid>
                </Grid>
            }
            {role === 'SystemAdministrator' &&
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <AdminContainer />
                    </Grid>
                </Grid>
            }
            {role === 'Admin' &&
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <AdminContainer />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}
