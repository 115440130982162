import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import NextIcon from '@mui/icons-material/NavigateNext';
import BackIcon from '@mui/icons-material/NavigateBefore';
import { ToolbarProps, View } from "react-big-calendar";
import Typography from "@mui/material/Typography";
import YellowButton from "./YellowButton";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from "react";
import Alert from "@mui/material/Alert";

interface IProps extends ToolbarProps {
    addButtonClick: () => void;
    disabled: boolean;
    name: string;
    canCreate: boolean;
}

export default function CalendarToolbar(props: IProps) {
    const { onNavigate, label, view, views, onView, addButtonClick, disabled, name, canCreate } = props;

    const onViewChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
        onView(value);
    }

    return (
        <Stack spacing={1}>
            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">{name}</Typography>
                <ToggleButtonGroup
                    aria-label="calendar view buttons"
                    exclusive
                    value={view}
                    onChange={onViewChange}
                    size="small"
                >
                    {(views as View[]).map(item =>
                        <ToggleButton
                            key={item}
                            value={item}
                            onClick={() => onView(item)}
                            sx={{ borderRadius: 0, textTransform: 'capitalize' }}
                        >{item}</ToggleButton>
                    )}
                </ToggleButtonGroup>
            </Stack>
            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                <Stack spacing={1} direction="row">
                    <IconButton aria-label="previous month" onClick={() => onNavigate("PREV")}>
                        <BackIcon />
                    </IconButton>
                    <IconButton aria-label="next month" onClick={() => onNavigate("NEXT")}>
                        <NextIcon />
                    </IconButton>
                    <Typography variant="h6">{label}</Typography>
                </Stack>
                {canCreate ?
                    <YellowButton variant="contained" size="small" onClick={addButtonClick} disabled={disabled}>Add Appointment</YellowButton>
                    :
                    <Alert severity="info" sx={{backgroundColor: "transparent"}}>Appointment creation is disabled</Alert>
                }
            </Stack>
        </Stack>
    );
}