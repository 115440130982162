import * as React from 'react';
import authService from '../api-authorization/AuthorizeService';
import PaymentRequestDataViewModel from '../ViewModels/Payments/PaymentRequestDataViewModel';
import { AppointmentPaymentViewModel } from '../ViewModels/Payments/PaymentViewModel';
import { AlertContext } from '../Contexts/AlertContext';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import YellowButton from '../Utilities/YellowButton';
import Bugsnag from '@bugsnag/js'

const height = 600;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'relative',
            height: height + 4 + 5 + theme.spacing(2),
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
        loading: {
            position: 'absolute',
            top: 'calc(50% - 10px)',
            left: 'calc(50% - 10px)',
            zIndex: 0
        },
    }));

interface IProps {
    appointmentId: number;
    close: (refresh: boolean) => void;
    back: () => void;
}

export default function PaymentDetails(props: IProps) {
    const { appointmentId, back } = props;
    const { show } = React.useContext(AlertContext);
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [order, setOrder] = React.useState<AppointmentPaymentViewModel>(new AppointmentPaymentViewModel(appointmentId, 0));
    const [paymentRequestData, setPaymentRequestData] = React.useState<PaymentRequestDataViewModel>(new PaymentRequestDataViewModel);
    const [loadButtonClicked, setButtonClicked] = React.useState(false);

    const iframeRef = React.createRef<HTMLIFrameElement>();

    React.useEffect(() => {
        if (iframeRef.current) {
            var iframe: any = document.getElementById('realexIframe');

            // For testing payment locally
            //if (paymentRequestData.MERCHANT_ID === "dev861691010943345097" && iframe.src.includes("Dummy")) {
            //    loadIFrame(paymentRequestData);
            //    setLoading(false);
            //}

            if (paymentRequestData.MERCHANT_ID === "riai" && iframe.src.includes("Dummy")) {
                loadIFrame(paymentRequestData);
                setLoading(false);
            }
        }
    }, [iframeRef, paymentRequestData]);

    React.useEffect(() => {
        getPurchase();
    }, []);

    React.useEffect(() => {
        if (order !== null) {
            if (order.streetAddress1 !== null && order.streetAddress1.length > 0 && order.phoneNumber !== null && order.phoneNumber.length > 0) {
                realexHpp();
            }
        }
    }, [order]);

    const getPurchase = async () => {
        const token = await authService.getAccessToken();

        fetch(`Appointment/GetPurchase?appointmentId=${appointmentId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setOrder(data);
            })
            .catch(error => {
                Bugsnag.notify(error);
            });
    }

    const realexHpp = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        const data = JSON.stringify({
            purchaseId: appointmentId,
            billingAddress:
            {
                streetAddress1: order.streetAddress1,
                streetAddress2: order.streetAddress2,
                streetAddress3: order.streetAddress3,
                city: order.city,
                province: order.province,
                postalCode: order.postalCode,
            },
            countryCode: order.countryCode,
            phoneNumber: order.phoneNumber,
            donationAmount: order.donationAmount ?? 0,
            baseAmount: order.baseAmount,
            email: order.email
        });

        fetch("api/Payments/RequestPayment",
            {
                method: "POST",
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                body: data
            }
        )
            .then(response => response.json())
            .then((data) => {
                setPaymentRequestData(data);
                setSaving(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const loadIFrame = (data: any) => {
        if (document.readyState === "complete") {
            //For testing on local
            //window.RealexHpp.setHppUrl("https://pay.sandbox.realexpayments.com/pay");
            window.RealexHpp.setHppUrl("https://pay.realexpayments.com/pay");
            window.RealexHpp.embedded.init("payButton", "realexIframe", window.location.origin + "/api/Payments/PaymentResponse", data);

            if (buttonRef && buttonRef.current && !loadButtonClicked) {
                buttonRef.current.click();
                setButtonClicked(true);
            }
        }
    }

    //const cancel = async () => {
    //    const token = await authService.getAccessToken();
    //    setSaving(true);

    //    fetch(`Appointment/CancelAddAppointment?appointmentId=${appointmentId}`, {
    //        method: 'POST',
    //        headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
    //    })
    //        .then(response => response.text())
    //        .then(data => {
    //            if (data.length > 0) {
    //                show('error', data);
    //                setSaving(false);
    //            } else {
    //                setSaving(false);
    //                close(false);
    //            }
    //        })
    //        .catch(error => {
    //            setSaving(false);
    //        });

    //    fetch(`api/Payments/ArchiveAppointmentPurchase?purchaseId=${order.appointmentPurchaseId}`, {
    //        method: 'POST',
    //        headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
    //    })
    //        .then(response => response.text())
    //        .then(data => {
    //            if (data.length > 0) {
    //                show('error', data);
    //            } else {
    //                back();
    //            }
    //        })
    //        .catch(error => {
    //        });
    //}

    const goBack = async () => {
        const token = await authService.getAccessToken();

        fetch(`api/Payments/ArchiveAppointmentPurchase?purchaseId=${order.appointmentPurchaseId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    show('error', data);
                } else {
                    back();
                }
            })
            .catch(error => {
                Bugsnag.notify(error);
            });
    }

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
            {loading && <Grid item xs={12}><LinearProgress color="primary" /></Grid>}
            <Collapse in={!loading} sx={{ width: '100%' }}>
                <Grid item xs={12} sx={{ position: 'relative', minHeight: '500px' }}>
                    <Button id="payButton" hidden ref={buttonRef} />
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                    </div>
                    <iframe ref={iframeRef} id="realexIframe" height={height + 4} width={'100%'} style={(loading || saving) ? { opacity: 0, border: 'none', position: 'relative', zIndex: 100 } : { opacity: 1, border: 'none', position: 'relative', zIndex: 100 }} src="iframeDummyLoader.html" />
                </Grid>
            </Collapse>
            <Grid item xs={12} md={6}>
                <YellowButton onClick={goBack} variant="contained" fullWidth disabled={saving || loading}>Back</YellowButton>
            </Grid>
        </Grid>
    );
}