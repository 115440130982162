import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PaymentViewModel, { AppointmentPaymentViewModel } from '../ViewModels/Payments/PaymentViewModel';
import DropdownViewModel from '../ViewModels/Utilities/DropdownViewModel';
import Grid from '@mui/material/Grid';
import ColourPaper from '../Utilities/ColourPaper';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Payment from './Payment';
import InputAdornment from '@mui/material/InputAdornment';
import Bugsnag from '@bugsnag/js';

interface IProps {
    appointmentId?: number;
}

interface IRouteParams {
    purchaseRecordId?: string;
}

export default function PaymentInformation() {
    const history = useHistory();
    const { purchaseRecordId } = useParams<IRouteParams>();
    const locationProps = useLocation<IProps>();
    const { appointmentId } = locationProps.state || {
        appointmentId: undefined
    };
    const [loading, setLoading] = React.useState(true);
    const [countryCodes, setCountryCodes] = React.useState<DropdownViewModel[]>([]);
    const [info, setInfo] = React.useState<PaymentViewModel | AppointmentPaymentViewModel>(appointmentId !== undefined ? new AppointmentPaymentViewModel(appointmentId, 0.5) : new PaymentViewModel(0.50));
    const [showPaymentScreen, setShowPaymentScreen] = React.useState(false);
    const [cancellationFee, setCancellationFee] = React.useState(0);

    ValidatorForm.addValidationRule("notZero", (value: number) => {
        if (value > 0) {
            return true;
        } return false;
    });

    ValidatorForm.addValidationRule("phoneNumber", (value) => {
        if (value.match(/(^[^+-])([0-9]{8,11})+/g)) return true;
        return false;
    });

    React.useEffect(() => {
        getCountryCodes();
        if (appointmentId !== undefined) getBaseAmount();
    }, []);

    React.useEffect(() => {
        if (countryCodes.length > 0) setLoading(false);
    }, [countryCodes]);

    React.useEffect(() => {
        if (purchaseRecordId !== undefined) getPaymentInformation();
    }, [purchaseRecordId])

    const getPaymentInformation = async () => {
        fetch(`api/Payments/GetGuestPurchase?id=${purchaseRecordId}`, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }).then(response => response.json()).then((data) => {
            console.log(data);
            setInfo(data);
        }).catch((error) => {
            Bugsnag.notify(error);
        });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = isNaN(event.target.valueAsNumber) ?
            event.target.name === 'mainUser' ?
                event.target.checked :
                event.target.value :
            event.target.valueAsNumber;

        if (event.target.name === 'postalCode') {
            value = (value as string).toUpperCase();
        }

        setInfo({
            ...info,
            [event.target.name]: value
        });
    }

    const getBaseAmount = async () => {
        let data = 0;
        setInfo({
            ...info,
            baseAmount: data
        });
    }

    const getCountryCodes = async () => {
        fetch(`api/Payments/GetCountryCodes`, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }).then(response => response.json()).then((data) => {
            setCountryCodes(data);
        }).catch((error) => {
            Bugsnag.notify(error);
        });

        fetch(`api/Campaign/GetCancellationFee`, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }).then(response => response.json()).then((data) => {
            setCancellationFee(data);
        }).catch((error) => {
            Bugsnag.notify(error);
        });
    }

    const changeToPaymentScreen = () => {
        const infoCopy = { ...info };
        let postcode = infoCopy.postalCode.replace(/\s/g, "");
        postcode = postcode.substring(0, 3) + " " + postcode.substring(3);
        infoCopy.postalCode = postcode;

        fetch(`api/Payments/AddPurchase`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(infoCopy)
        })
            .then(response => response.json())
            .then(data => {
                if (data !== 0) {
                    history.push({
                        pathname: "/GuestPayment",
                        state: { orderId: data }
                    });
                } else {
                    // exception occurred
                }
            })
            .catch(error => {
                Bugsnag.notify(error);
            });
    }

    ValidatorForm.addValidationRule('eircode', (value: string) => {
        if (value.replace(/\s/g, "").length !== 7) {
            return false;
        }

        if (!value.match(/^[a-zA-Z0-9 ]*$/))
            return false;

        return true;
    });

    const bannerURL = window.location.origin + '/payment_banner.png';

    return (
        <React.Fragment>
            {!loading ?
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6}>
                        <img src={bannerURL} style={{width: "100%"}} />
                    </Grid>
                    {!showPaymentScreen ?
                        <Grid item xs={12} md={8}>
                            <ColourPaper>
                                <ValidatorForm onSubmit={changeToPaymentScreen}>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{appointmentId !== undefined ? "Appointment Purchase" : "Voluntary Donation"}</Typography>
                                        </Grid>
                                        {info.error !== '' && info.error !== null && info.error !== undefined &&
                                            <Grid item xs={12}>
                                                <Alert severity='error'>{info.error}</Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="Name*"
                                                name="name"
                                                value={info.name}
                                                onChange={onChange}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="Email*"
                                                name="email"
                                                value={info.email}
                                                onChange={onChange}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="Street Address 1*"
                                                name="streetAddress1"
                                                value={info.streetAddress1}
                                                onChange={onChange}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="Street Address 2"
                                                name="streetAddress2"
                                                value={info.streetAddress2}
                                                onChange={onChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="Street Address 3"
                                                name="streetAddress3"
                                                value={info.streetAddress3}
                                                onChange={onChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="Town*"
                                                name="city"
                                                value={info.city}
                                                onChange={onChange}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="County*"
                                                name="province"
                                                value={info.province}
                                                onChange={onChange}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="Eircode*"
                                                name="postalCode"
                                                value={info.postalCode}
                                                onChange={onChange}
                                                validators={['required', 'eircode']}
                                                errorMessages={['This field is required', 'Invalid eircode']}
                                                helperText="Please enter the Eircode in XXX XXXX format (with a space)."
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <SelectValidator
                                                select
                                                size="small"
                                                margin="dense"
                                                name='countryCode'
                                                fullWidth
                                                variant="outlined"
                                                value={info.countryCode !== '' ? info.countryCode : ''}
                                                onChange={onChange}
                                                InputProps={{
                                                    disabled: loading,
                                                    startAdornment: (
                                                        <React.Fragment>
                                                            {loading ? <CircularProgress size={20} /> : null}
                                                        </React.Fragment>
                                                    )
                                                }}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            >
                                                {countryCodes && countryCodes.map(item =>
                                                    <MenuItem key={item.key} value={item.key}>{item.value + " (+" + item.key + ")"}</MenuItem>
                                                )}
                                            </SelectValidator>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="Contact Number*"
                                                name="phoneNumber"
                                                value={info.phoneNumber}
                                                maxlength="8"
                                                onChange={onChange}
                                                validators={['required', 'phoneNumber']}
                                                errorMessages={['This field is required', 'Invalid phone number']}
                                            />
                                            <FormHelperText>Do not enter any extra characters, leading zeros, spaces, and country codes into your phone number.  For example, "+ 353 (0)1 676 1703" should be entered as "16761703".</FormHelperText>
                                            <FormHelperText>Must match the phone number on the account for the credit card that is being used.</FormHelperText>
                                        </Grid>
                                        {appointmentId !== undefined && info instanceof AppointmentPaymentViewModel &&
                                            <Grid item xs={12}>
                                                <TextValidator
                                                    fullWidth
                                                    margin="dense"
                                                    size="small"
                                                    variant="outlined"
                                                    label="Base Amount"
                                                    name="Base Amount"
                                                    type="number"
                                                    value={info.baseAmount}
                                                    onChange={onChange}
                                                    validators={['required']}
                                                    errorMessages={['This field is required']}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                                                    }}
                                                    helperText={"In the event of cancellation, you will receive back: €" + (info.baseAmount - cancellationFee)}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="Donation Amount*"
                                                name="donationAmount"
                                                type="number"
                                                value={info.donationAmount}
                                                onChange={onChange}
                                                validators={['required', 'notZero']}
                                                errorMessages={['This field is required', 'This value cannot be zero']}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">€</InputAdornment>
                                                }}
                                                inputProps={{
                                                    min: 0.50,
                                                    step: ".01"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                size="small"
                                                margin="dense"
                                                variant="outlined"
                                                label="Note (leave an optional goodwill note)"
                                                name="note"
                                                value={info.note}
                                                onChange={onChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                                                <Grid item>
                                                    <Button type="submit" variant="outlined" color="primary">Submit</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </ColourPaper>
                        </Grid>
                        :
                        <Grid item xs={8}>
                            <Payment errorReturn={(error: string) => { setShowPaymentScreen(false); }} />
                        </Grid>
                    }
                </Grid>
                :
                <Grid item xs={12}>
                    <LinearProgress color="primary" />
                </Grid>
            }
        </React.Fragment>
    );
}