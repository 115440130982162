import * as React from 'react';
import ReportDonationListViewModel from "../ViewModels/Reports/ReportDonationListViewModel";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import ColourPaper from '../Utilities/ColourPaper';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import ReportDonationTableRow from './ReportDonationTableRow';
import YellowButton from '../Utilities/YellowButton';
import TablePagination from '@mui/material/TablePagination';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Bugsnag from '@bugsnag/js';
import DatePicker from '../Utilities/DatePicker';

const headCells: HeadCell<ReportDonationListViewModel>[] = [
    { id: 'name', property: 'Name', disablePadding: false, label: 'Name' },
    { id: 'orderId', property: 'OrderId', disablePadding: false, label: 'Order ID' },
    { id: 'transactionId', property: 'TransactionId', disablePadding: false, label: 'Transaction ID' },
    { id: 'date', property: 'Date', disablePadding: false, label: "Transaction Date" },
    { id: 'refunded', property: 'Refunded', disablePadding: false, label: 'Refunded' },
    { id: 'amount', property: 'Amount', disablePadding: false, label: 'Amount' }
];

export default function ReportDonationTable() {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof ReportDonationListViewModel>('name');
    const [search, setSearch] = React.useState('');
    const [results, setResults] = React.useState<ReportDonationListViewModel[]>([]);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [downloading, setDownloading] = React.useState(false);

    const today = new Date();

    const [filterStartDate, setFilterStartDate] = React.useState<Date | null>(new Date(today.getFullYear(), 0, 1));
    const [filterEndDate, setFilterEndDate] = React.useState<Date | null>(new Date(today.getFullYear(), 11, 31));

    React.useEffect(() => {
        getData();
    }, [order, orderBy, page, rowsPerPage]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ReportDonationListViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getData = async () => {
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        fetch(`Reports/GetReportDonationTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}${filterStartDate !== null ? `&filterStartDate=${filterStartDate.toISOString()}` : ""}${filterEndDate !== null ? `&filterEndDate=${filterEndDate.toISOString()}` : ""}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setResults(data.rows);
                    setCount(data.count);
                }
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const downloadCSV = async () => {
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        setDownloading(true);

        let url = `Reports/DownloadDonationTableCSV?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}${filterStartDate !== null ? `&filterStartDate=${filterStartDate.toISOString()}` : ""}${filterEndDate !== null ? `&filterEndDate=${filterEndDate.toISOString()}` : ""}`;
        fetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.blob())
            .then(blob => {
                let dateTime = new Date();
                const formattedTime = dateTime.toLocaleDateString() + "_" + dateTime.toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit'
                });

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "PaymentsReport_CSV_Export_" + formattedTime + ".csv";
                document.body.appendChild(a);
                a.click();
                a.remove();
                setDownloading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setDownloading(false);
            });
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDateChange = (newValue: Date | null, name: string) => {
        switch (name) {
            case "filterStartDate":
                setFilterStartDate(newValue);
                break;
            case "filterEndDate":
                setFilterEndDate(newValue);
                break;
        }
    }

    return (
        <ColourPaper>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                        <Grid item xs={12} sm={6} md={2}>
                            <DatePicker
                                date={filterStartDate}
                                name="filterStartDate"
                                disablePast={false}
                                disableFuture={false}
                                onChange={onDateChange}
                                disabled={false}
                                size="small"
                                clearable={true}
                                label="Start Date"

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DatePicker
                                date={filterEndDate}
                                name="filterEndDate"
                                disablePast={false}
                                disableFuture={false}
                                onChange={onDateChange}
                                disabled={false}
                                size="small"
                                clearable={true}
                                label="End Date"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                color="secondary"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleSearch}
                                value={search}
                                placeholder="Search..."
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                            <YellowButton fullWidth variant="contained" disabled={loading} onClick={getData}>Search</YellowButton>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                            <YellowButton fullWidth variant="contained" disabled={loading} onClick={handleClick} endIcon={downloading ? <CircularProgress color="secondary" size={20} /> : <KeyboardArrowDownIcon />}>Download</YellowButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'menuButton',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={downloadCSV}>Download Detailed Report (CSV)</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <ResponsiveTable
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="architect table"
                            sx={{ width: 'calc(100% - 1px)' }}
                        >
                            <SortableTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={headCells}
                            />
                            <TableBody>
                                {loading &&
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="secondary" /><Typography>Pulling latest data</Typography></TableCell>
                                    </TableRow>
                                }
                                {!loading && results && results.map((row: ReportDonationListViewModel) => {
                                    return (<ReportDonationTableRow key={row.id} row={row} />);
                                })}
                            </TableBody>
                        </ResponsiveTable>
                        {count <= 0 && !loading &&
                            <Grid container justifyContent="center" alignItems="flex-end">
                                <Grid item>
                                    <Typography variant="h5">No payments found</Typography>
                                </Grid>
                            </Grid>
                        }
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ColourPaper>
    );
}