import * as React from 'react';
import RefundsTable from "./RefundsTable";
import Stack from '@mui/material/Stack';

export default function RefundsContainer() {
    return (
        <Stack direction="column" justifyContent="center">
            <RefundsTable />
        </Stack>
    );
}