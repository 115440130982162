import * as React from 'react';
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import ArchitectViewModel from '../ViewModels/Architects/ArchitectViewModel';
import authService from '../api-authorization/AuthorizeService';
import WarningDialog from '../Utilities/WarningDialog';
import { AlertContext } from '../Contexts/AlertContext';
import EditArchitectDialog from './EditArchitectDialog';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Bugsnag from '@bugsnag/js';

interface IProps extends TableRowProps {
    row: ArchitectViewModel;
    refresh: () => void;
    selectRow: (row: ArchitectViewModel) => void;
}

export default function ArchitectsTableRow(props: IProps) {
    const { row, refresh, selectRow, ...rest } = props;
    const { show } = React.useContext(AlertContext);
    const [saving, setSaving] = React.useState(false);
    const [openWarning, setWarningOpen] = React.useState(false);
    const [openEdit, setEditOpen] = React.useState(false);

    const remove = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`Architect/Archive?id=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length <= 0) {
                    setWarningOpen(false);
                    show('success', 'Architect and appointments successfully removed.')
                    refresh();
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const closeEdit = (refreshList: boolean) => {
        if (refreshList) {
            refresh();
        }

        setEditOpen(false);
    }

    return (
        <TableRow {...rest} sx={{
            '& td': { padding: '8px' },
            '&.Mui-selected': {
                bgcolor: 'rgba(30, 25, 106, 0.1)',
                border: 2,
                borderColor: 'primary.main'
            }
        }}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.memberId}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell align="center">{row.conservation ? <DoneIcon color="success" /> : <CloseIcon />}</TableCell>
            <TableCell align="right">
                <Grid container spacing={1} style={{ flexWrap: 'nowrap' }}>
                    <Grid item>
                        <IconButton color="error" size="small" disabled={saving} onClick={() => setWarningOpen(true)}><DeleteIcon /></IconButton>
                        <WarningDialog open={openWarning} close={() => setWarningOpen(false)} okClick={remove} text={`This will remove ${row.name} from this practice and cancel their appointments.`} buttonText="Remove" />
                    </Grid>
                    <Grid item>
                        <IconButton color="primary" size="small" disabled={saving} onClick={() => setEditOpen(true)}><EditIcon /></IconButton>
                        <EditArchitectDialog architect={row} open={openEdit} close={closeEdit} />
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
}