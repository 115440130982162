import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import DropdownViewModel from '../ViewModels/Utilities/DropdownViewModel';
import Bugsnag from '@bugsnag/js';

interface IProps {
    architect: string | number | null;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    editable: boolean;
    alignRight?: boolean;
    orgId?: string;
}

export default function ArchitectDropdown(props: IProps) {
    const { architect, onChange, editable, alignRight, orgId } = props;
    const [items, setItems] = React.useState<DropdownViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        getItems();
    }, []);

    React.useEffect(() => {
        getItems();
    }, [orgId]);

    const getItems = async () => {
        const token = await authService.getAccessToken();
        setLoaded(false);

        fetch(`Architect/GetArchitectsDropdown?orgId=${orgId ? orgId : ""}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setItems(data);
                setLoaded(true);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoaded(true);
            });
    }

    return (
        <TextField
            disabled={!editable || !loaded}
            select
            name='architect'
            label='Architect'
            size="small"
            fullWidth
            variant="outlined"
            value={architect}
            onChange={onChange}
            InputProps={{
                style: {
                    textAlign: alignRight ? 'right' : 'start'
                },
                startAdornment: (
                    <React.Fragment>
                        {loaded ? null : <CircularProgress size={20} />}
                    </React.Fragment>
                ),
            }}
        >
            <MenuItem key={0} value={0}><i>All Architects</i></MenuItem>
            {items.map(item => {
                return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>;
            })}
        </TextField>
    );
}